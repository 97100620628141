import React from 'react'
import {
    Switch,
    useRouteMatch
} from "react-router-dom";

import ClientList from './ClientList';
import ClientCreate from './ClientCreate';
import ClientEdit from './ClientEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ClientRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Client">
                <ClientCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:clientId`} action="read" subject="Client">
                <ClientEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="Client">
                <ClientList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ClientRoute
