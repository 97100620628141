import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ClientCaseForm from './ClientCaseForm';
import { CaseSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientCaseEdit = ({ clientId }: Props) => {
    const { caseId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const [loading, setLoading] = useState<boolean>(false);

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "Case")

    const loadCase = useCallback(() => {
        setLoading(true);
        loadAction(
            `/Case/${caseId}`,
            'case',
            (data) => {
                const formData = dataToForm(data, CaseSchema);
                reset(formData);
                setLoading(false);
            }
        );
    }, [loadAction, caseId, reset]);

    const saveCase = (formData: any) => {
        if (!allowEdit) return;

        formData.clientId = clientId;
        const data = formToData(formData, CaseSchema);

        updateAction(
            `/Case/${caseId}`, data,
            `case '${data.id}'`,
            () => {
                history.push(`/clients/${clientId}/cases`);
            }
        );
    }

    useEffect(() => {
        loadCase();
    }, [loadCase]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Case Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={loading}>
                    <form onSubmit={handleSubmit(saveCase)} noValidate autoComplete="off">
                        <ClientCaseForm disabled={!allowEdit} />
                        <FormButtons
                            disabled={!allowEdit}
                            dirty={formState.isDirty}
                            cancelLink={`/clients/${clientId}/cases`}
                            submitLabel="Save Case"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientCaseEdit
