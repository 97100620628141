import { faScroll } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography,
    Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';

const TemplateList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const loadTemplates = useCallback(() => {
        loadAction(
            '/NoteTemplate',
            'Note Templates',
            setData
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/NoteTemplate/${data.id}`,
            `Note Template '${data.id}'`,
            loadTemplates
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/administration/templates/${data.id}`)
    };

    useEffect(() => {
        loadTemplates();
    }, [loadTemplates]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <Typography color="textPrimary">Templates</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Note Templates">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/administration/templates/create')}
                    >
                        Add Template
                    </Button>
                </SectionHeader>
                <SectionTitle
                    title="Note Template List"
                    icon={faScroll}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile='Note Templates'
                    columns={[
                        { field: 'id', title: 'Id', hidden: true, export: true, type: 'numeric' },
                        
                        { field: 'name', title: 'Name', width: 200, defaultSort: 'asc' },
                        { field: 'body', title: 'Body' },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },

                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default TemplateList
