import { Grid, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import TimeEntryRow from './TimeEntryRow';
import TimeEntryRowHeader from './TimeEntryRowHeader';
import TimeEntryRowFooter from './TimeEntryRowFooter';
import { DateTime } from 'luxon';
import { formatDate } from 'utils/dateUtils';
import { TimeEntryType, TimeSheetType } from 'models/TimeSheetModel';
import { useFormActions } from 'components/Form/FormActions';
import { useHistory } from 'react-router-dom';
import { useConfirmation } from 'components/Confirmation/ConfirmationProvider';

interface Props {
    startDate: Date;
    timeEntries?: TimeEntryType[];
    disabled?: boolean;
}

type Row = {
    date: string,
    index: number,
    day?: string,
    timeEntry?: TimeEntryType,
    removable?: boolean
}

export type RowData = {
    weekOneRows: Row[],
    weekTwoRows: Row[]
}

const TimeEntryGrid = (props: Props) => {
    const { startDate, timeEntries, disabled = false } = props
    const [state, setState] = useState<RowData>()
    const { deleteAction } = useFormActions();
    const history = useHistory();
    const confirmation = useConfirmation();

    const addDaysToDate = (addDays: number) => {
        return DateTime.fromISO(startDate.toISOString()).plus({ days: addDays }).toISODate()
    }

    const handleAdd = (index: number, date: string) => {
        if (state) {
            // Get highest current index and increase by one to avoid duplicates when adding/removing rows
            const weekOneIndexMax = Math.max(...state.weekOneRows.map(i => i.index), 0)
            const weekTwoIndexMax = Math.max(...state.weekTwoRows.map(i => i.index), 0)
            const nextIndex = Math.max(weekOneIndexMax, weekTwoIndexMax) + 1

            const weekOneAdd = state.weekOneRows.find(x => x.index === index)
            const weekTwoAdd = state.weekTwoRows.find(x => x.index === index)
            if (weekOneAdd) {
                const weekOneList = state.weekOneRows
                weekOneList.splice(weekOneList.indexOf(weekOneAdd) + 1, 0, { date: date, index: nextIndex, removable: true })
                setState({ ...state, weekOneRows: weekOneList })
            } else if (weekTwoAdd) {
                const weekTwoList = state.weekTwoRows
                weekTwoList.splice(weekTwoList.indexOf(weekTwoAdd) + 1, 0, { date: date, index: nextIndex, removable: true })
                setState({ ...state, weekTwoRows: weekTwoList })
            }
        }
    }

    const handleRemove = async (index: number, date: string) => {
        const weekOneRemoval = state?.weekOneRows.find(x => x.index === index)
        const weekTwoRemoval = state?.weekTwoRows.find(x => x.index === index)
        if (weekOneRemoval && state) {
            const weekOneList = state.weekOneRows
            if(!weekOneRemoval.timeEntry)
                weekOneList.splice(weekOneList.indexOf(weekOneRemoval), 1)
            
            for(let key in timeEntries)
            {
                if(timeEntries[Number(key)].id === weekOneRemoval.timeEntry?.id){
                    const confirmed = await confirmation({
                        title: "Confirm Delete",
                        description: `Are you sure you want to this time Entry?\nThis action is Irreversible do you want to continue?`,
                        variant: "danger"
                    });
                    if (confirmed){
                        deleteEntry(timeEntries[Number(key)].id, timeEntries[Number(key)].timeSheetId)
                        weekOneList.splice(weekOneList.indexOf(weekOneRemoval), 1)
                    }
                }
            }

            setState({ ...state, weekOneRows: weekOneList })
            
        } else if (weekTwoRemoval && state) {
            const weekTwoList = state.weekTwoRows
            if(!weekTwoRemoval.timeEntry)
                weekTwoList.splice(weekTwoList.indexOf(weekTwoRemoval), 1)

            for(let key in timeEntries)
            {
                if(timeEntries[Number(key)].id === weekTwoRemoval.timeEntry?.id){
                    const confirmed = await confirmation({
                        title: "Confirm Delete",
                        description: `Are you sure you want to this time Entry?\nThis action is Irreversible do you want to continue?`,
                        variant: "danger"
                    });
                    if (confirmed){
                        deleteEntry(timeEntries[Number(key)].id, timeEntries[Number(key)].timeSheetId)
                        weekTwoList.splice(weekTwoList.indexOf(weekTwoRemoval), 1)
                    }
                }
            }
            
            setState({ ...state, weekTwoRows: weekTwoList });
        }
    }

    const deleteEntry = (entryId: number, timeSheetId: number) => {
        deleteAction(
            `/TimeEntry/${entryId}`,
            `TimeEntry '${entryId}'`,
            () => {
                history.replace(history.location);
            },
            true
        );        
    }
    useEffect(() => {
        if (timeEntries) {
            const entryValueArray = Object.values(timeEntries)
            let isRemovable = false;
            const checkForNexted: TimeEntryType[] = [];
            const entries = entryValueArray.map((entry, i) => {
                var exists = checkForNexted.find(val => val.entryDate === entry.entryDate)
                if(!exists){
                    checkForNexted.push(entry);
                    isRemovable = false;
                }
                else
                    isRemovable = true;
                
                return {
                    date: entry.entryDate,
                    index: i,
                    timeEntry: entry,
                    removable: isRemovable
                }
            })

            // split entries array into week 1 and 2
            // get end of week one by the start date
            const endWeekOne = entries.filter(e => {
                if (e.date === addDaysToDate(6))
                    return e
            })

            const weekOneMaxIndex = Math.max(...endWeekOne.map(i => i.index)) + 1
            const weekOne = entries.slice(0, weekOneMaxIndex)
            const weekTwo = entries.slice(weekOneMaxIndex, entries.length)

            setState({ weekOneRows: weekOne, weekTwoRows: weekTwo })

        } else {
            // New time sheet, create the empty rows
            let weekOne = []
            for (let i = 0; i < 7; i++) {
                weekOne.push({
                    date: i === 0 ? formatDate(startDate.toString()) : addDaysToDate(i),
                    index: i
                })
            }
            let weekTwo = []
            for (let i = 7; i < 14; i++) {
                weekTwo.push({ date: addDaysToDate(i), index: i })
            }

            setState({ weekOneRows: weekOne, weekTwoRows: weekTwo })
        }
    }, [timeEntries, startDate])

    const timeEntryRow = (row: Row) => {
        return (
            <TimeEntryRow
                disabled={disabled}
                index={row.index}
                date={row.date}
                timeEntry={row?.timeEntry}
                key={row.index}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                removeRow={row.removable}
            />
        )
    }

    return (
        <Grid
            container
            direction="row"
        >
            <TimeEntryRowHeader />

            {/* Week 1 */}
            {state?.weekOneRows.map((row) => (
                timeEntryRow(row)
            ))}

            <TimeEntryRowFooter disabled={disabled} rowData={state} />

            {/* Week 2 */}
            {state?.weekTwoRows.map((row) => (
                timeEntryRow(row)
            ))}

            <TimeEntryRowFooter GridEnd={true} disabled={disabled} rowData={state} />
        </Grid>
    )
}

export default TimeEntryGrid