import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ManagerForm from './ManagerForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { CaseManagerSchema } from 'models/Schemas';
import { dataToForm, formToData } from 'services/DataTransform';
import { AbilityContext } from 'components/AuthorizationProvider';

const ManagerEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { managerId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "CaseManager")

    const loadManager = useCallback(() => {
        loadAction(
            `/CaseManager/${managerId}`,
            'case manager',
            data => {
                const formData = dataToForm(data, CaseManagerSchema);
                reset(formData);
            }
        );
    }, [loadAction, managerId, reset]);

    const saveManager = (formData: any) => {
        if (!allowEdit) return;

        const data = formToData(formData, CaseManagerSchema);
        updateAction(
            `/CaseManager/${managerId}`, data,
            `case manager '${data.userName}'`,
            () => {
                history.push('/administration/managers');
            }
        );
    }

    useEffect(() => {
        loadManager();
    }, [loadManager]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/managers">Managers</LinkRouter>
                <Typography color="textPrimary">Edit Manager</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update Case Manager">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Case Manager Information"
                    icon={faUserFriends}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveManager)} noValidate>
                            <ManagerForm disabled={!allowEdit} />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/managers"
                                submitLabel="Save Manager"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ManagerEdit
