import { DateTime } from 'luxon';

const getNextCycleDate = (year: number, month: number, day: number, current: DateTime) => {

    const interval = 14;
    const epoch = DateTime.local(year, month, day);

    const difference = current.diff(epoch, 'days')
    const offset = difference.as('days');

    const days = (interval - (offset % interval))
    const nextDate = current.plus({ days });

    return nextDate;
}

export const nextBillingDate = (date: Date | DateTime = DateTime.local()): DateTime => {

    const current = (date instanceof Date) ? DateTime.fromJSDate(date) : date;

    return getNextCycleDate(2014, 1, 3, current);
}

export const nextBillingLockDate = (date: Date | DateTime = DateTime.local()): DateTime => {

    const current = (date instanceof Date) ? DateTime.fromJSDate(date) : date;

    return getNextCycleDate(2014, 1, 4, current);
}