import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import AgreementNoteForm from './AgreementNoteForm';
import { NoteSchema } from 'models/Schemas';
import { useAuthorization } from 'components/AuthorizationProvider';
import { nextBillingLockDate } from 'services/BillingCycle';

interface Props {
    agreementId: string
}

const AgreementNoteCreate = ({ agreementId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const userInfo = useAuthorization();
    const form = useForm({
        defaultValues: {
            caseManagerId: userInfo.caseManagerId,
            billingLockDate: nextBillingLockDate().toISODate()
        }
    });
    const { createAction } = useFormActions();

    const saveServiceNote = (formData: any) => {
        formData.caseManagerId = userInfo.caseManagerId;
        formData.serviceAgreementId = agreementId;
        const data = formToData(formData, NoteSchema);

        createAction(
            '/ServiceNote', data,
            `service note '${data.id}'`,
            () => history.push(`/agreements/${agreementId}/notes`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Note">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveServiceNote)} noValidate autoComplete="off">
                    <AgreementNoteForm action="create" />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/agreements/${agreementId}/notes`}
                        submitLabel="Save Note"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default AgreementNoteCreate
