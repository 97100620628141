import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Breadcrumbs, Container, Paper, Typography, TextField, Grid, Box } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import ManagerPicker from 'components/Pickers/ManagerPicker';

const ClientNotes = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [caseManagers, setCaseManagers] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());
    const [minimumUnits, setMinimumUnits] = useState<string>('');
    const [maximumUnits, setMaximumUnits] = useState<string>('');

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    const firstLoad = useRef(true);

    // load data
    const loadData = useCallback((caseManagerIds: number[], startDate: string, endDate: string, minimumUnits: number | null, maximumUnits: number | null) => {
        const url = URI("/Report/ClientNotes")
            .setSearch({
                caseManagerIds,
                startDate,
                endDate,
                minimumUnits,
                maximumUnits
            });

        loadAction(
            url.toString(),
            'Client Notes',
            setData
        );
    }, [loadAction])

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${data.ClientId}/notes/${data.ServiceNoteId}`)
    };

    useEffect(() => {
        // prevent double loading of list
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        if (!caseManagers
            || caseManagers.length === 0
            || !DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid
        ) {
            setTimeout(() => setData([]), 0);
            return;
        }

        const ids = caseManagers.map(m => m.id);
        const min = minimumUnits ? parseFloat(minimumUnits) : null;
        const max = maximumUnits ? parseFloat(maximumUnits) : null;
        loadData(ids, delayedStartDate, delayedEndDate, min, max);
    }, [loadData, caseManagers, delayedStartDate, delayedEndDate, minimumUnits, maximumUnits]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Client Notes</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Client Notes">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Client Notes Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={2}
                    >
                        <Grid item xs={4}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="minimumUnits"
                                label="Minimum Units"
                                type='number'
                                fullWidth
                                value={minimumUnits}
                                onChange={event => setMinimumUnits(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="maximumUnits"
                                label="Maximum Units"
                                type='number'
                                fullWidth
                                value={maximumUnits}
                                onChange={event => setMaximumUnits(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    options={{
                        exportFileName: `Client Notes ${DateTime.local().toISODate()}`,
                    }}
                    columns={[
                        { field: 'CaseManager', title: 'Case Manager' },
                        { field: 'ClientName', title: 'Client Name' },
                        { field: 'DateOfService', title: 'Date of Service', width: 190, type: 'date', defaultSort: 'asc' },
                        { field: 'CaseManagerActivity', title: 'Activity', width: 200 },
                        { field: 'DateOfEntry', title: 'Date of Entry', width: 190, type: 'date' },
                        { field: 'UnitsUsed', title: 'Units Used', width: 150, type: 'numeric' }
                    ]}
                    data={data}
                    detailPanel={(rowData: any) => (
                        <Box
                            padding={1.5}
                            style={{
                                border: '1px solid #e6e6e6',
                                borderRadius: 4,
                                overflow: 'auto',
                                maxHeight: '400px'
                            }}
                        >
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                {rowData.Note}
                            </Typography>
                        </Box>
                    )}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ClientNotes
