import React from 'react'
import { FormControl, InputLabel } from '@material-ui/core';
import ReactSelect from 'react-select';
import useFetch, { CachePolicies } from 'use-http';
import { selectStyles } from 'components/Styles';

interface Props {
    label?: string;
    multiselect?: boolean;
    selected: any;
    setSelected: (value: any) => void;
}

const ClientPicker = ({ 
    label = "Client",
    multiselect = true,
    selected = null,
    setSelected 
}: Props) => {
    
    // load data
    const { data: clients } = useFetch('/Client?sort=familyName', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    return (
        <FormControl fullWidth>
            <InputLabel shrink>
                {label}
            </InputLabel>

            <ReactSelect
                styles={selectStyles}
                isClearable={true}
                options={clients}
                isMulti={multiselect}
                getOptionLabel={(option: any) => option.fullName}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={value => {
                    setSelected(value);
                }}
            />
        </FormControl>
    )
}

export default ClientPicker
