import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import { selectStyles } from 'components/Styles';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    supervisor?: boolean;
    action?: string;
    subject?: string;
    field?: string;

}

const ManagerSelect = ({
    label = "Case Manager",
    name = "caseManagerId",
    required = true,
    disabled = false,
    readOnly = false,
    supervisor = false,
    action,
    subject,
    field
}: Props) => {

    const form = useFormContext();
    const { errors, register, setValue } = form;
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>();
    const [filterOptions, setFilterOptions] = useState<any[]>([]);
    const ability = useContext(AbilityContext);

    const formValue = useWatch<any>({ name: name });

    const denied = disabled || Boolean(action && subject && ability.cannot(action, subject, field ?? name));

    // load data
    const url = supervisor ? '/CaseManager?sort=familyName&q=IsSupervisor+eq+true' : '/CaseManager?sort=familyName'
    const { data: options } = useFetch(url, {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);

        // set form value
        setValue(name, selectedOption?.id, {
            shouldValidate: true,
            shouldDirty: true
        });

    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options) {
            return;
        }

        // find the selected options
        const current = options.find((f: any) => f.id === Number(formValue));
        setSelected(current);

        const filtered = options.filter((f: any) => f.isActive === true || f.id === Number(formValue))
        setFilterOptions(filtered);

    }, [formValue, options])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={denied}
                styles={selectStyles}
                isClearable={!readOnly}
                isSearchable={!readOnly}
                openMenuOnClick={!readOnly}
                menuIsOpen={readOnly ? false : undefined}
                options={filterOptions}
                getOptionLabel={(option: any) => option.fullName}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}                
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default ManagerSelect
