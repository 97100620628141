import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect, { FormatOptionLabelMeta } from 'react-select';
import { useFetch } from 'use-http';
import { DateTime } from 'luxon';

interface Props {
    label?: string;
    name?: string;
    instance?: string;
    required?: boolean;
    clientId?: string | null;
    disabled?: boolean
}

const formatOptionLabel = (data: any, option: FormatOptionLabelMeta<any, false>) => {
    if (option.context === 'value') {
        return (
            <div>{data.serviceAgreementNumber} - {data.procedureCode} {data.serviceName}</div>
        )
    }
    else if (option.context === 'menu') {
        return (
            <div>
                <div>{data.serviceAgreementNumber} - {data.procedureCode} {data.serviceName}</div>
                <small>
                    {data.startDate && <span>{DateTime.fromISO(data.startDate).toLocaleString()}</span>}
                    {data.startDate && data.endDate && <span> - </span>}
                    {data.endDate && <span>{DateTime.fromISO(data.endDate).toLocaleString()}</span>}
                </small>
            </div>
        )
    }
};

const ServiceAgreementSelect = ({
    label = "Service Agreement",
    name = "serviceAgreementId",
    instance = "serviceAgreement",
    required = true,
    clientId = null,
    disabled = false
}: Props) => {

    const form = useFormContext();
    const { errors, register, setValue } = form;
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()
    const [filterOptions, setFilterOptions] = useState<any[]>([]);

    const formValue = useWatch({ name: name });

    const url = clientId ? `/Client/${clientId}/Agreement?sort=startDate` : '/ServiceAgreement?sort=startDate';
    // load data
    const { data: options } = useFetch(url, [url]);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);

        // set form value
        setValue(name, selectedOption?.id, {
            shouldValidate: true,
            shouldDirty: true
        });
    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options) {
            return;
        }

        // find the selected options
        const current = options.find((f: any) => f.id === Number(formValue));
        setSelected(current);

        const filtered = options.filter((f: any) => f.isArchived === false || f.id === Number(formValue))
        setFilterOptions(filtered);

    }, [formValue, options, selected])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
        register({ name: instance });
    }, [register, name, required, instance]);

    useEffect(() => {
        setTimeout(() => {
            // set form instance
            setValue(instance, selected, {
                shouldValidate: false,
                shouldDirty: false
            });
        });
    }, [instance, selected, setValue]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}
                styles={selectStyles}
                isClearable={true}
                options={filterOptions}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default ServiceAgreementSelect
