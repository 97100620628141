import { faMedkit } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ActivityForm from './ActivityForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import { ActivityTypeSchema } from 'models/Schemas';

const ActivityCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveActivity = (formData: any) => {
        const data = formToData(formData, ActivityTypeSchema);

        actions.createAction(
            '/ActivityType', data,
            `activity '${data.code}'`,
            () => history.push('/administration/activities')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/activities">Activities</LinkRouter>
                <Typography color="textPrimary">Add Activity</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add Activity Type">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Activity Type Information"
                    icon={faMedkit}
                    color={theme.palette.text.primary}
                />
                <FormProvider  {...form}>
                    <form onSubmit={form.handleSubmit(saveActivity)} noValidate>
                        <ActivityForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/administration/activities"
                            submitLabel="Save Activity"
                        />
                    </form>
                </FormProvider >
            </Paper>
        </Container>
    )
}

export default ActivityCreate
