import React from 'react'
import { FallbackProps } from 'react-error-boundary';
import { Container, Breadcrumbs, Button } from '@material-ui/core';
import LinkRouter from './Navigation/LinkRouter';
import { Alert, AlertTitle } from '@material-ui/lab';



const ErrorFallback = ({ error }: FallbackProps) => {
    
    console.log(error);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
            </Breadcrumbs>

            <Alert
                severity="error"
                action={
                    <Button color="inherit" size="small" onClick={e => window.location.reload()} >
                        Reload
                    </Button>
                } 
            >
                <AlertTitle>Application Error</AlertTitle>
                <pre>{error?.message}</pre>
            </Alert>
        </Container>
    )
}

export default ErrorFallback
