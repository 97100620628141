import { AuthenticationContext, AdalConfig } from 'react-adal'

// msal Configurations
const authenticationConfiguration = {
    tenant: process.env.REACT_APP_TENANT_ID,
    clientId: process.env.REACT_APP_CLIENT_ID,
    endpoints: {
        api: process.env.REACT_APP_CLIENT_ID
    },
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.location.origin,
    cacheLocation: 'localStorage'
} as AdalConfig;

export const authenticationContext = new AuthenticationContext(authenticationConfiguration);

