import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import ManagerRoute from './Managers/ManagerRoute';
import CodeRoute from './Codes/CodeRoute';
import ModifierRoute from './Modifiers/ModifierRoute';
import ActivityRoute from './Activities/ActivityRoute';
import LanguageRoute from './Languages/LanguageRoute';
import ContactTypeRoute from './ContactTypes/ContactTypeRoute';
import CompanyRoute from './Companies/CompanyRoute';
import TemplateRoute from './Templates/TemplateRoute';

import AdministrationDashboard from './AdministrationDashboard';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';



const AdministrationRoute = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/codes`} action="read" subject="MedicalCode">
                <CodeRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/companies`} action="read" subject="Company">
                <CompanyRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/managers`} action="read" subject="CaseManager">
                <ManagerRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/activities`} action="read" subject="ActivityType">
                <ActivityRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/modifiers`} action="read" subject="ServiceModifier">
                <ModifierRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/languages`} action="read" subject="Language">
                <LanguageRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/templates`} action="read" subject="NoteTemplate">
                <TemplateRoute />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/contactTypes`} action="read" subject="ContactType">
                <ContactTypeRoute />
            </ProtectedRoute>
            <Route path={`${match.path}`} exact>
                <AdministrationDashboard />
            </Route>
        </Switch>
    )
}

export default AdministrationRoute
