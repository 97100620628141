import React from 'react'

interface Props {
    children: React.ReactNode;
}

const TimelineBody = (props: Props) => {
    return (
        <div className="timeline-item-body">
            {props.children}
        </div>
    )
}

export default TimelineBody
