import React from 'react'
import { DateTime } from 'luxon';

interface Props {
    value: Date | string
}

const TimelineDate = ({ value }: Props) => {
    const format = (value: Date | string) => {
        if (value instanceof Date) {
            return DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)
        }

        return DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED)
    }

    return (
        <span className="timeline-date">
            {value && format(value)}
        </span>
    )
}

export default TimelineDate
