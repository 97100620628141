import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ManagerForm from './ManagerForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { CaseManagerSchema } from 'models/Schemas';
import { formToData } from 'services/DataTransform';

const ManagerCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveManager = (formData: any) => {
        const data = formToData(formData, CaseManagerSchema);
        actions.createAction(
            '/CaseManager', data,
            `case manager '${data.userName}'`,
            () => history.push('/administration/managers')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/managers">Managers</LinkRouter>
                <Typography color="textPrimary">Add Manager</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add New Case Manager">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Case Manager Information"
                    icon={faUserFriends}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(saveManager)} noValidate>
                        <ManagerForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/administration/managers"
                            submitLabel="Save Manager"
                        />
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ManagerCreate
