import React from 'react'
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    to: string;
    replace?: boolean;
    children: React.ReactNode;
}

const LinkRouter = (props: Props) => {
    return (
        <Link {...props} component={RouterLink as any} />
    )
}

export default LinkRouter
