import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { useAuthorization } from "components/AuthorizationProvider";
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

interface Props {

}

const AnnualAssessment = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const userInfo = useAuthorization();
    const history = useHistory();

    const loadData = useCallback(() => {
        if (!userInfo || !userInfo.caseManagerId) return;

        loadAction(
            `/Notification/AnnualAssessment?caseManagerId=${userInfo.caseManagerId}`,
            'Annual Assessment',
            setData
        );
    }, [loadAction, userInfo])

    const handleEdit = (event: any, data: any) => {
        if (!data.ClientId) return;

        history.push(`/clients/${data.ClientId}/notes`)
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Annual Assessment</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Annual Assessment">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Annual Assessment Notification"
                    icon={faBell}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile={`Annual Assessment ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name', defaultSort: 'asc' },
                        { field: 'ServiceAgreementNumber', title: 'Service Agreement Number', width: 250 },
                        { field: 'ServiceName', title: 'Service', width: 250 },
                        { field: 'StartDate', title: 'Agreement Start', width: 180, type: 'date' },
                        { field: 'EndDate', title: 'Agreement End', width: 180, type: 'date' },
                        { field: 'TypeWaiver', title: 'Type Waiver' }
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default AnnualAssessment
