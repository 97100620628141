import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

export const useNotification = () => {
    const { enqueueSnackbar } = useSnackbar();

    const show = useCallback((type: VariantType, message: string) => {
        return enqueueSnackbar(message, {
            variant: type
        });
    }, [enqueueSnackbar]);

    const showError = useCallback((message: string, error?: any) => {
        let m = message;

        if (error && error.message) {
            m = `${m}: ${error.message}`;
        }

        console.error(m);
        if (error) {
            console.log(error);
        }

        return enqueueSnackbar(m, {
            autoHideDuration: 5000 * 2,
            variant: 'error'
        });
    }, [enqueueSnackbar]);

    const showSuccess = useCallback((message: string) => {
        return show('success', message);
    }, [show]);

    const showWarning = useCallback((message: string) => {
        return show('warning', message);
    }, [show]);

    const showInformation = useCallback((message: string) => {
        return show('info', message);
    }, [show]);

    return {
        showError,
        showSuccess,
        showWarning,
        showInformation,
        show
    }
}

export default useNotification
