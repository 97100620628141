import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

import ReportDashboard from './ReportDashboard';
import EligibilityExport from './EligibilityExport';
import EligibilityImport from './EligibilityImport';
import UnitSummary from './UnitSummary';
import ClientList from './ClientList';
import ClientCount from './ClientCount';
import ClientNotes from './ClientNotes';
import ClientNotesPrint from './ClientNotesPrint';
import ClientFacility from './ClientFacility';
import BatchBillingRange from './BatchBillingRange';
import BatchBillingUnbilled from './BatchBillingUnbilled';
import OutputBillableUnits from './OutputBillableUnits';
import OutputBillableUnitsLegacy from './OutputBillableUnitsLegacy';
import OutputNewClients from './OutputNewClients';
import UnitAggregate from './UnitAggregate';
import UnitsDelayedBilling from './UnitsDelayedBilling';
import AuditHistory from './AuditHistory'
import TimeSheetRange from './TimeSheetRange'
import TimeSheetReport from './TimeSheetReport';

const ReportRoute = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/eligibility/export`} action="read" subject="Reports" field="eligibility">
                <EligibilityExport />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/eligibility/import`} action="read" subject="Reports" field="eligibility">
                <EligibilityImport />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/client-list`} action="read" subject="Reports" field="client">
                <ClientList />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/client-count`} action="read" subject="Reports" field="client">
                <ClientCount />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/client-notes`} action="read" subject="Reports" field="client">
                <ClientNotes />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/client-notes-print`} action="read" subject="Reports" field="client">
                <ClientNotesPrint />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/client-facility`} action="read" subject="Reports" field="client">
                <ClientFacility />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/unit-summary`} action="read" subject="Reports" field="unit">
                <UnitSummary />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/units-delayed-billing`} action="read" subject="Reports" field="billing">
                <UnitsDelayedBilling />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/batch-billing-range`} action="read" subject="Reports" field="billing">
                <BatchBillingRange />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/batch-billing-unbilled`} action="read" subject="Reports" field="billing">
                <BatchBillingUnbilled />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/output-billable-units`} action="read" subject="Reports" field="accounting">
                <OutputBillableUnits />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/output-billable-units-legacy`} action="read" subject="Reports" field="accounting">
                <OutputBillableUnitsLegacy />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/output-new-clients`} action="read" subject="Reports" field="accounting">
                <OutputNewClients />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/unit-aggregate`} action="read" subject="Reports" field="unit">
                <UnitAggregate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/audit-history`} action="read" subject="Reports" field="administrator">
                <AuditHistory />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/time-sheet-range`} action="read" subject="Reports" field="timeSheet">
                <TimeSheetRange />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/time-sheet-report`} action="read" subject="Reports" field="timeSheet">
                <TimeSheetReport />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}`} exact action="read" subject="Reports">
                <ReportDashboard />
            </ProtectedRoute>
        </Switch>
    )
}

export default ReportRoute
