import { faProcedures } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import CodeForm from './CodeForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { dataToForm, formToData } from 'services/DataTransform';
import { MedicalCodeSchema } from 'models/Schemas';

const CodeEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { codeId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadCode = useCallback(() => {
        loadAction(
            `/MedicalCode/${codeId}`,
            'medical code',
            data => {
                const formData = dataToForm(data, MedicalCodeSchema);
                reset(formData);
            }
        );
    }, [loadAction, codeId, reset]);

    const saveCode = (formData: any) => {
        const data = formToData(formData, MedicalCodeSchema);
        updateAction(
            `/MedicalCode/${codeId}`, data,
            `medical code '${data.code}'`,
            () => {
                history.push('/administration/codes');
            }
        );
    }

    useEffect(() => {
        loadCode();
    }, [loadCode]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/codes">Codes</LinkRouter>
                <Typography color="textPrimary">Edit Code</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update ICD-10 Medical Code">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Medical Code Information"
                    icon={faProcedures}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveCode)} noValidate>
                            <CodeForm />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/codes"
                                submitLabel="Save Code"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default CodeEdit
