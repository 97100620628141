import React, { useState, useCallback } from 'react'
import { Breadcrumbs, Container, Paper, Typography, TextField, Grid, Box, FormControl, InputLabel, Select, Button, Input, Checkbox, ListItemText, MenuItem } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import ManagerPicker from 'components/Pickers/ManagerPicker';
import TimelineItem from 'components/Timeline/TimelineItem';

const AuditHistory = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const operations = [
        'Create',
        'Update',
        'Delete',
        'Login'
    ]

    const entities = [
        "Authorization",
        "Case",
        "CaseDiagnosis",
        "Client",
        "ClientEligibility",
        "Contact",
        "FacilityStay",
        "Payer",
        "Program",
        "Service",
        "ServiceAgreement",
        "ServiceModifier",
        "ServiceNote",
        "ServiceNoteActivity",
        "ServiceRate"
    ];

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [caseManagers, setCaseManagers] = useState<any[]>([]);
    const [operation, setOperation] = useState<string[]>(operations);
    const [entity, setEntity] = useState<string[]>(entities);
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ days: 7 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());


    // load data
    const loadData = useCallback((query: any) => {
        const url = URI('/AuditHistory/Report')
            .setSearch(query);

        loadAction(
            url.toString(),
            'Audit History',
            data => {
                var mapped = data.map((item: any) => {
                    const changes = item.changes ? JSON.parse(item.changes) : null;
                    return { ...item, changes };
                });
                setData(mapped);
            }
        );
    }, [loadAction])

    const handleEdit = (event: any, data: any) => {
        if (!data) return;

        switch (data.entity) {
            case 'Case':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/cases/${data.key}`)

                break;
            case 'CaseDiagnosis':
                if (data.clientId)
                    history.push(`/clients/${data.clientId}`)

                break;
            case 'Client':
                if (data.key)
                    history.push(`/clients/${data.key}`)

                break;
            case 'ClientEligibility':
                if (data.key)
                    history.push(`/clients/${data.key}/eligibility`)

                break;
            case 'Contact':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/contacts/${data.key}`)

                break;
            case 'FacilityStay':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/facility/${data.key}`)

                break;
            case 'Payer':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/payers/${data.key}`)

                break;
            case 'Program':
                if (data.key)
                    history.push(`/programs/${data.key}`)

                break;
            case 'Service':
                if (data.key)
                    history.push(`/services/${data.key}`)

                break;
            case 'ServiceAgreement':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/agreements/${data.key}`)

                break;
            case 'ServiceModifier':
                if (data.serviceId)
                    history.push(`/services/${data.serviceId}`)

                break;
            case 'ServiceNote':
                if (data.clientId && data.key)
                    history.push(`/clients/${data.clientId}/notes/${data.key}`)

                break;
            case 'ServiceRate':
                if (data.serviceId && data.key)
                    history.push(`/services/${data.serviceId}/rates/${data.key}`)

                break;
        }
    };

    const handleApply = (event: any) => {
        if (!DateTime.fromISO(startDate).isValid || !DateTime.fromISO(endDate).isValid) {
            return;
        }
        const caseManagerIds = caseManagers ? caseManagers.map(m => m.id) : [];

        const query = {
            caseManagerIds,
            startDate,
            endDate,
            entity,
            operation
        };

        loadData(query);
    };


    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Audit History</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Audit History">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Audit History Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={2}
                    >
                        <Grid item xs={3}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Change
                                </InputLabel>
                                <Select multiple
                                    input={<Input />}
                                    renderValue={(selected: any) => selected.join(', ')}
                                    value={operation}
                                    onChange={event => setOperation(event.target.value as string[])}
                                >
                                    {operations.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={operation.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Entity
                                </InputLabel>
                                <Select multiple
                                    input={<Input />}
                                    renderValue={(selected: any) => selected.join(', ')}
                                    value={entity}
                                    onChange={event => setEntity(event.target.value as string[])}
                                >
                                    {entities.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox checked={entity.indexOf(name) > -1} />
                                            <ListItemText primary={name.split(/(?=[A-Z])/).join(' ')} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            <Button variant="contained"
                                color="primary"
                                onClick={handleApply} >Apply</Button>
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    options={{
                        exportFileName: `Audit History ${DateTime.local().toISODate()}`,
                        pageSize: 20
                    }}
                    columns={[
                        { field: 'entity', title: 'Entity', hidden: true, export: true },
                        { field: 'key', title: 'Key', hidden: true, export: true },
                        { field: 'activityBy', title: 'User Name', width: 200 },
                        { field: 'caseManagerName', title: 'Case Manager', width: 200 },
                        { field: 'activityDate', title: 'Date', width: 190, type: 'datetime', defaultSort: 'desc' },
                        { field: 'operation', title: 'Operation', width: 200 },
                        { field: 'displayName', title: 'Entity', width: 200 },
                        { field: 'description', title: 'Description', cellStyle: { minWidth: 200 } },
                        { field: 'clientName', title: 'Client Name', cellStyle: { minWidth: 200 } },
                        { field: 'clientId', title: 'Client Id', type: 'numeric', hidden: true, export: true },
                        { field: 'serviceAgreementId', title: 'Agreement Id', type: 'numeric', hidden: true, export: true },
                        { field: 'serviceId', title: 'Service Id', type: 'numeric', hidden: true, export: true },
                    ]}
                    data={data}
                    detailPanel={(rowData: any) => (
                        <Box
                            padding={1.5}
                            style={{
                                border: '1px solid #e6e6e6',
                                borderRadius: 4,
                                overflow: 'auto',
                                maxHeight: '400px'
                            }}
                        >
                            <TimelineItem item={rowData} showIcon={false} />
                        </Box>
                    )}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default AuditHistory
