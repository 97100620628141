import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import DashboardSection from '../Dashboard/DashboardSection';
import { Can } from 'components/AuthorizationProvider';


interface Props {

}

const ReportDashboard = (props: Props) => {
    const theme = useTheme();

    const eligibilityLinks = [
        { location: "/reports/eligibility/export", label: "Export Client Eligibility" },
        { location: "/reports/eligibility/import", label: "Import Client Eligibility" },
    ];

    const clientLinks = [
        { location: "/reports/client-list", label: "Client List" },
        { location: "/reports/client-count", label: "Client Count" },
        { location: "/reports/client-notes", label: "Client Notes" },
        { location: "/reports/client-notes-print", label: "Client Notes Printable" },
        { location: "/reports/client-facility", label: "Clients in a Facility" },
    ];

    const unitLinks = [
        { location: "/reports/unit-summary", label: "Unit Summary" },
        { location: "/reports/unit-aggregate", label: "Unit Aggregate" },
    ];

    const billingLinks = [
        { location: "/reports/batch-billing-range", label: "Batch Billing by Date Output" },
        { location: "/reports/batch-billing-unbilled", label: "Batch Billing by Unbilled Output" },
        { location: "/reports/units-delayed-billing", label: "Units Delayed For Billing" },
    ];

    const accountingLinks = [
        { location: "/reports/output-new-clients", label: "Output New Clients to Accounting" },
        { location: "/reports/output-billable-units", label: "Output Billable Units to Accounting" },
        { location: "/reports/output-billable-units-legacy", label: "Output Billable Units to Accounting (Legacy)" },
    ];

    const administratorLinks = [
        { location: "/reports/audit-history", label: "Audit History" },
    ];

    const notificationLinks = [
        { location: "/notifications/annual-assessment", label: "Annual Assessment Reminder" },
        { location: "/notifications/bpc-assessment-paperwork", label: "BPC Assessment Paperwork" },
        { location: "/notifications/impending-discharge", label: "Impending Discharge Notification" },
        { location: "/notifications/new-clients", label: "New Client Notification" },
        { location: "/notifications/quality-visit", label: "Quality Visit Reminder" },
        { location: "/notifications/quarterly-address", label: "Quarterly Address Reminder" },
    ];

    const timeSheetLinks = [
        { location: "/reports/time-sheet-range", label: "Time Sheets by Date Output" },
        { location: "/reports/time-sheet-report", label: "Time Sheets Report" }
    ];

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <Typography color="textPrimary">Report</Typography>
            </Breadcrumbs>
            <Grid container spacing={3}>
                <Can do="read" on="Reports" field="eligibility">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Client Eligibility"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={eligibilityLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports" field="unit">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Unit Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={unitLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports" field="billing">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Billing Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={billingLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports" field="accounting">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Accounting Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={accountingLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports" field="client">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Client Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={clientLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports" field="notification">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Notification Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={notificationLinks}
                        />
                    </Grid>
                </Can>

                <Can do="read" on="Reports" field="administrator">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Administrator Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={administratorLinks}
                        />
                    </Grid>
                </Can>

                <Can do="read" on="Reports" field="timeSheet">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Time Sheet Reports"
                            icon={faChartBar}
                            color={theme.palette.primary.main}
                            links={timeSheetLinks}
                        />
                    </Grid>
                </Can>
            </Grid>
        </Container>)
}

export default ReportDashboard
