import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
} from '@material-ui/core';
import { AbilityContext, Can } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const EligibilityList = ({ clientId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "Case")

    const loadCases = useCallback(() => {
        loadAction(
            `/client/${clientId}/eligibility`,
            'client eligibility',
            setData
        );
    }, [loadAction, clientId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/clientEligibility/${data.id}`,
            `eligibility '${data.id}'`,
            loadCases
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${clientId}/eligibility/${data.id}`)
    };

    useEffect(() => {
        loadCases();
    }, [loadCases]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Client Eligibility">
                <Can do="create" on="Contact">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/clients/${clientId}/eligibility/create`)}
                    >
                        Add Eligibility
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Eligibility"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="Eligibility"
                columns={[
                    { field: 'clientId', title: 'Client Id', hidden: true, export: true },
                    { field: 'clientFamilyName', title: 'Client Last', hidden: true, export: true },
                    { field: 'clientGivenName', title: 'Client First', hidden: true, export: true },

                    { field: 'eligibilityYear', title: 'Year', width: 100, type: 'numeric' },
                    { field: 'eligibilityMonth', title: 'Month', width: 100, type: 'numeric' },

                    { field: 'planDate', title: 'Plan Date', width: 200, type: 'date', defaultSort: 'desc' },
                    { field: 'coverageStatus', title: 'Coverage Status' },
                    { field: 'typeWaiver', title: 'Waiver Type' },

                    { field: 'medicalSpenddown', title: 'Spenddown' },
                    { field: 'spenddownAmount', title: 'Spenddown Amount', type: 'numeric' },

                    { field: 'isBillingDelayed', title: 'Billing Delayed', width: 140, type: 'boolean' },
                    { field: 'billingDelayedReason', title: 'Delayed Reason' },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                ]}
                data={data}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default EligibilityList
