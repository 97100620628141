import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ServiceForm from './ServiceForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import { ServiceSchema } from 'models/Schemas';

const ServiceCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveService = (formData: any) => {
        const data = formToData(formData, ServiceSchema);

        actions.createAction(
            '/service', data,
            `service '${data.procedureCode}'`,
            () => history.push('/services')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/services">Services</LinkRouter>
                <Typography color="textPrimary">Add Service</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <FormHeader title="Add New Service">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Service Information"
                    icon={faFileMedicalAlt}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(saveService)} noValidate>
                        <ServiceForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/services"
                            submitLabel="Save Service"
                        />
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ServiceCreate
