export function getPcClientId(pcVal: string, value: string) {
    const programCodeMap = {
        CADI: '00',
        BI: '01',
        DD: '02',
        PP: '03',
        IF: '04',
        CTPD: '05',
        VADD: '06'
    }
    const pc = programCodeMap[pcVal as keyof typeof programCodeMap];
    const newId = pc + value.slice(2);
    
    // If new id fails, send back original value
    if (newId.includes('undefined')) return value
    return newId;
}