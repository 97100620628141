import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography, TextField, Grid } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import ManagerPicker from 'components/Pickers/ManagerPicker';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';


const groupBy = <K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
    const map = new Map();

    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);

        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });

    return map;
}

const UnitSummary = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [caseManagers, setCaseManagers] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    const groupData = useCallback((data: any[]) => {
        let result = [];
        let index = 1;
        let totalUnits = 0;
        let totalBillable = 0;

        const sorted = data.sort((a: any, b: any) => {
            var nameA = a.CaseManager.toUpperCase();
            var nameB = b.CaseManager.toUpperCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        })

        const grouped = groupBy(sorted, item => item.CaseManager);

        grouped.forEach((value: any[], key: any) => {
            // create group parent row
            const indexItem = {
                _index: index++,
                _type: 'GroupTotal',

                CaseManager: key,
                UnitsUsed: 0,
                BillableUnits: 0
            };

            const sortedValues = value.sort((a: any, b: any) => {
                var dateA = DateTime.fromISO(a.DateOfService);
                var dateB = DateTime.fromISO(b.DateOfService);

                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
                return 0;
            })

            // link group to parent and total units
            sortedValues.forEach((item: any) => {
                item._index = index++;
                item._parent = indexItem._index;

                indexItem.UnitsUsed += item.UnitsUsed;
                indexItem.BillableUnits += item.BillableUnits;

                result.push(item);
            })

            // push total row
            result.push(indexItem);

            // track overall total
            totalUnits += indexItem.UnitsUsed;
            totalBillable += indexItem.BillableUnits;
        });

        // create grand total row
        const totalItem = {
            _index: index++,
            _type: 'GrandTotal',

            CaseManager: 'Total',
            UnitsUsed: totalUnits,
            BillableUnits: totalBillable
        };
        result.push(totalItem);

        setData(result);
    }, []);

    // load data
    const loadData = useCallback((caseManagerIds: number[], startDate: string, endDate: string) => {
        const url = URI("/Report/UnitSummary")
            .setSearch({
                caseManagerIds,
                startDate,
                endDate
            });

        loadAction(
            url.toString(),
            'Unit Summary',
            (data: any) => {
                groupData(data);
            }
        );
    }, [loadAction, groupData])


    const handleEdit = (event: any, data: any) => {
        if (!data || !data.ClientId || !data.ServiceNoteId) return;

        history.push(`/clients/${data.ClientId}/notes/${data.ServiceNoteId}`)
    };

    const totalStyle = (data: any[], rowData: any): React.CSSProperties => {
        const properties: React.CSSProperties = {
            fontWeight: rowData._type === 'GroupTotal' || rowData._type === 'GrandTotal' ? 'bold' : 'inherit',
            fontSize: rowData._type === 'GrandTotal' ? '1.3em' : 'inherit',
        };

        return properties
    };

    useEffect(() => {
        if (!DateTime.fromISO(delayedStartDate).isValid || !DateTime.fromISO(delayedEndDate).isValid) {
            return;
        }

        const ids = caseManagers ? caseManagers.map(m => m.id) : [];

        loadData(ids, delayedStartDate, delayedEndDate);
    }, [loadData, caseManagers, delayedStartDate, delayedEndDate]);


    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Unit Summary</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Unit Summary">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Unit Summary Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Unit Summary ${DateTime.local().toISODate()}`}
                    columns={[
                        {
                            field: 'CaseManager',
                            title: 'Case Manager',
                            cellStyle: totalStyle
                        },
                        {
                            field: 'DateOfService',
                            title: 'Date of Service',
                            width: 190,
                            type: 'date',
                            export: false
                        },
                        {
                            field: 'ClientName',
                            title: 'Client Name',
                            export: false
                        },
                        {
                            field: 'CaseManagerActivity',
                            title: 'Activity',
                            export: false
                        },
                        {
                            field: 'UnitsUsed',
                            title: 'Units Used',
                            width: 150,
                            type: 'numeric',
                            cellStyle: totalStyle
                        },
                        {
                            field: 'BillableUnits',
                            title: 'Billable Units',
                            width: 150,
                            type: 'numeric',
                            cellStyle: totalStyle
                        },
                    ]}
                    data={data}
                    parentChildData={(row, rows) => rows.find(a => a._index === row._parent)}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                    options={{
                        paging: false,
                        sorting: false
                    }}
                />
            </Paper>
        </Container>
    )
}

export default UnitSummary
