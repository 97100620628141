import React from 'react'
import PublishIcon from '@material-ui/icons/Publish';
import {
    Button
} from '@material-ui/core';
import Papa from 'papaparse';
import { ImportDefinition } from 'models/ImportDefinition'
import useNotification from '../Notification';

interface Props {
    importDefinition: ImportDefinition;
    fileSelected: (data: any[], fileName?: string) => void;
}

const UploadSelect = (props: Props) => {
    const { showError, showWarning } = useNotification();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files;

        if (!files || files.length === 0) {
            showWarning('No file selected for uploading');
            return;
        }

        // only use first file
        const file = files[0];

        Papa.parse(file, {
            header: false,
            error: (error: Papa.ParseError, file?: File) => {
                showError(`Error Parsing ${file?.name}`, error);
            },
            complete: (results: Papa.ParseResult<any>, file?: File) => {
                props.fileSelected(results?.data, file?.name);
            }
        })
    };

    return (
        <div>
            <input
                style={{ display: 'none' }}
                id="import-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="import-file">
                <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<PublishIcon />}
                >
                    Upload
                </Button>
            </label>
        </div>
    )
}

export default UploadSelect
