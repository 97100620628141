import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ProgramList from './ProgramList';
import ProgramCreate from './ProgramCreate';
import ProgramEdit from './ProgramEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ProgramRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Program">
                <ProgramCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:programId`} action="read" subject="Program">
                <ProgramEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="Program">
                <ProgramList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ProgramRoute
