import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import { Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    serviceId: string;
    disabled?: boolean;
}

const ServiceRateForm = ({ serviceId, disabled = false }: Props) => {
    const { errors, register } = useFormContext();
    const theme = useTheme();

    return (
        <React.Fragment>
            <SectionTitle
                title="Service Rate"
                icon={faFileMedicalAlt}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}
                        name="effectiveStart"
                        label="Effective Start"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.effectiveStart)}
                        helperText={errors.effectiveStart && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                
                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}
                        name="effectiveEnd"
                        label="Effective End"
                        type="date"
                        fullWidth
                        error={Boolean(errors.effectiveEnd)}
                        helperText={errors.effectiveEnd && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}
                        name="unitRate"
                        label="Unit Rate"
                        type="number"
                        required
                        fullWidth
                        error={Boolean(errors.unitRate)}
                        helperText={errors.unitRate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        name="description"
                        label="Description"
                        fullWidth
                        multiline
                        minRows="8"
                        error={Boolean(errors.description)}
                        helperText={errors.description && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ServiceRateForm
