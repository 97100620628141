import { makeStyles, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { RowData } from './TimeEntryGrid';

interface Props {
    GridEnd?: boolean,
    disabled?: boolean,
    rowData: RowData | undefined
}

const useStyles = makeStyles(() => ({
    textField: {
    },
    inputLeft: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        borderColor: "black",
        paddingLeft: "10px",
    },
    inputLeft2: {
        borderBottomStyle: "solid",
        borderColor: "black",
        paddingLeft: "10px",
    },
    inputLabel: {
        borderRightStyle: "solid",
        borderColor: "black",
        borderBottomStyle: "solid",
        paddingLeft: "10px",
        backgroundColor: "lightgray",
    }
}));

const TimeSheetRowFooter = ({ GridEnd = false, disabled = false, rowData }: Props) => {
    const classes = useStyles();
    let otherHourWeekOneRows, otherHourWeekTwoRows, hoursWorkedWeekOneRows, hoursWorkedWeekTwoRows
    if (rowData) {
        const { weekOneRows, weekTwoRows } = rowData
        otherHourWeekOneRows = weekOneRows.map(row => `otherHours-${row.index}`)
        otherHourWeekTwoRows = weekTwoRows.map(row => `otherHours-${row.index}`)
        hoursWorkedWeekOneRows = weekOneRows.map(row => `hoursWorked-${row.index}`)
        hoursWorkedWeekTwoRows = weekTwoRows.map(row => `hoursWorked-${row.index}`)
    }

    const otherHoursFormValuesWeek1 = useWatch({ name: otherHourWeekOneRows ? otherHourWeekOneRows : ['otherHours-0', 'otherHours-1', 'otherHours-2', 'otherHours-3', 'otherHours-4', 'otherHours-5', 'otherHours-6'] });
    const otherHoursFormValuesWeek2 = useWatch({ name: otherHourWeekTwoRows ? otherHourWeekTwoRows : ['otherHours-7', 'otherHours-8', 'otherHours-9', 'otherHours-10', 'otherHours-11', 'otherHours-12', 'otherHours-13'] });

    const hoursWorkedFormValuesWeek1 = useWatch({ name: hoursWorkedWeekOneRows ? hoursWorkedWeekOneRows : ['hoursWorked-0', 'hoursWorked-1', 'hoursWorked-2', 'hoursWorked-3', 'hoursWorked-4', 'hoursWorked-5', 'hoursWorked-6'] });
    const hoursWorkedFormValuesWeek2 = useWatch({ name: hoursWorkedWeekTwoRows ? hoursWorkedWeekTwoRows : ['hoursWorked-7', 'hoursWorked-8', 'hoursWorked-9', 'hoursWorked-10', 'hoursWorked-11', 'hoursWorked-12', 'hoursWorked-13'] });

    const sum = (obj: any) => {
        let sum = 0;
        for (var el in obj) {
            if (obj.hasOwnProperty(el))
                sum += obj[el] !== undefined ? parseFloat(obj[el]) : 0;
        }
        return sum;
    }

    // Get Other Hours totals
    let totalOtherHoursWeek1 = 0
    let totalOtherHoursWeek2 = 0

    if (!GridEnd) {
        totalOtherHoursWeek1 = sum(otherHoursFormValuesWeek1)
    } else {
        totalOtherHoursWeek2 = sum(otherHoursFormValuesWeek2)
    }

    // Get Hours Worked totals
    let totalHoursWorkedWeek1 = 0
    let totalHoursWorkedWeek2 = 0

    if (!GridEnd) {
        totalHoursWorkedWeek1 = sum(hoursWorkedFormValuesWeek1)
    } else {
        totalHoursWorkedWeek2 = sum(hoursWorkedFormValuesWeek2)
    }

    // Get Total Hours
    const totalHoursWeek1 = totalOtherHoursWeek1 + totalHoursWorkedWeek1
    const totalHoursWeek2 = totalOtherHoursWeek2 + totalHoursWorkedWeek2

    return (
        <Grid
            container
            direction="row"
        >
            <Grid item xs={7}>
                {!GridEnd && (
                    <TextField
                        disabled={true}
                        fullWidth
                        InputProps={{
                            className: classes.inputLeft2
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={2}>
                <TextField
                    disabled={true}
                    defaultValue={'Weekly Totals'}
                    fullWidth
                    InputProps={{
                        className: classes.inputLabel
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                        className: classes.inputLeft
                    }}
                    value={!GridEnd ? totalOtherHoursWeek1 : totalOtherHoursWeek2}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                        className: classes.inputLeft
                    }}
                    value={!GridEnd ? totalHoursWorkedWeek1 : totalHoursWorkedWeek2}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    type="number"
                    fullWidth
                    InputProps={{
                        className: classes.inputLeft
                    }}
                    value={!GridEnd ? totalHoursWeek1 : totalHoursWeek2}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    )
}

export default TimeSheetRowFooter
