import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect, { FormatOptionLabelMeta } from 'react-select';
import { useFetch } from 'use-http';
import { DateTime } from 'luxon';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    clientId?: string | null;
    disabled?: boolean
}

const formatOptionLabel = (data: any, option: FormatOptionLabelMeta<any, false>) => {
    if (option.context === 'value') {
        return (
            <div>{data.id} - {data.clientFamilyName}, {data.clientGivenName}</div>
        )
    }
    else if (option.context === 'menu') {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>{data.id} - {data.clientFamilyName}, {data.clientGivenName}</div>
                <small>
                    {data.transferDate && <span>{DateTime.fromISO(data.transferDate).toLocaleString()}</span>}
                </small>
            </div>
        )
    }
};

const CaseSelect = ({
    label = "Case",
    name = "caseId",
    required = true,
    clientId = null,
    disabled = false
}: Props) => {

    const form = useFormContext();
    const { errors, register, setValue } = form;
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()

    const formValue = useWatch<any>({name: name});

    const url = clientId ? `/Client/${clientId}/Case` : '/Case';
    // load data
    const { data: options } = useFetch(url, [url]);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);

        // set form value
        setValue(name, selectedOption?.id, {
            shouldValidate: true,
            shouldDirty: true
        });
    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options || !formValue) {
            return;
        }

        // find the selected options
        const current = options.find((f: any) => f.id === Number(formValue));
        setSelected(current);

    }, [formValue, options])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}                        
                styles={selectStyles}
                isClearable={true}
                options={options}
                formatOptionLabel={formatOptionLabel}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default CaseSelect
