
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Popover, Typography, Avatar, Divider, Button, IconButton, Chip } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { authenticationContext } from 'services/Authentication';
import { useAuthorization } from "components/AuthorizationProvider";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    popover: {
        width: theme.spacing(40),
        borderRadius: theme.shape.borderRadius
    },
    container: {
        display: "flex",
        padding: theme.spacing(2),
        flexDirection: "column",
        alignItems: "center"
    },
    avatar: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        margin: theme.spacing(1),
        color: theme.palette.text.primary,
        background: theme.palette.background.default
    },
    userInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: theme.spacing(1)
    },
    userName: {
        fontSize: "1rem",
        fontWeight: 500
    },
    userEmail: {
        fontSize: "0.9rem",
    },
    bar: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    buttonSignout: {
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5, 2),
        fontSize: "0.8rem",
        fontWeight: 500,
        textTransform: "none"
    },
    roles: {
        marginTop: theme.spacing(0.5),
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

const ProfilePopover = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const userInfo = useAuthorization();
    const history = useHistory();

    const isOpen = Boolean(anchorEl);
    const id = isOpen ? "profile-popover" : undefined;

    const handleLogOut = () => {
        authenticationContext.logOut();
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const permissionClick = () => {
        history.push('/permissions');
        setAnchorEl(null);
    }

    return (
        <React.Fragment>
            <IconButton
                aria-label="account of current user"
                aria-describedby={id}
                color="inherit"
                onClick={handleClick}
            >
                <AccountCircle />
                {userInfo &&
                    <Typography
                        className={classes.userName}
                        style={{ marginLeft: 5 }}
                    >
                        {userInfo.displayName}
                    </Typography>
                }
            </IconButton>
            <Popover
                id={id}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                classes={{
                    paper: classes.popover
                }}
            >
                {userInfo &&
                    <div className={classes.container}>
                        <Avatar
                            alt={userInfo.displayName}
                            className={classes.avatar}
                        >
                            <AccountCircle style={{ fontSize: 55 }} />
                        </Avatar>
                        <div className={classes.userInfo}>
                            <Typography className={classes.userName} variant="h6" component="span" color="textPrimary">
                                {userInfo.displayName}
                            </Typography>
                            <Typography className={classes.userEmail} variant="body1" component="span" color="textSecondary">
                                {userInfo.emailAddress}
                            </Typography>
                            <div className={classes.roles}>
                                {userInfo.allowAdministrator &&
                                    <Chip size="small" variant="outlined" color="primary" label="Administrator" onClick={permissionClick} />
                                }
                                {userInfo.allowBilling &&
                                    <Chip size="small" variant="outlined" color="primary" label="Billing" onClick={permissionClick} />
                                }
                                {userInfo.allowPayroll &&
                                    <Chip size="small" variant="outlined" color="primary" label="Payroll" onClick={permissionClick} />
                                }
                                {userInfo.allowParaprofessional &&
                                    <Chip size="small" variant="outlined" color="primary" label="Paraprofessional" onClick={permissionClick} />
                                }
                                {userInfo.allowCaseManager &&
                                    <Chip size="small" variant="outlined" color="primary" label="Case Manager" onClick={permissionClick} />
                                }
                                {userInfo.allowSupervisor &&
                                    <Chip size="small" variant="outlined" color="primary" label="Supervisor" onClick={permissionClick} />
                                }
                            </div>
                        </div>
                    </div>
                }
                <Divider />
                <div className={classes.bar}>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={handleLogOut}
                        classes={{ root: classes.buttonSignout }}
                    >
                        Sign out
                    </Button>
                </div>
            </Popover>
        </React.Fragment>
    )
}

export default ProfilePopover
