import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ManagerSelect from 'components/Form/ManagerSelect';
import Dropdown from 'components/Form/Dropdown';

interface Props {
    disabled?: boolean
}

const ManagerForm = ({
    disabled = false
}: Props) => {
    const { errors, register } = useFormContext();

    return (
        <Grid
            style={{
                marginTop: "5px",
                marginBottom: "5px"
            }}
            container
            direction="row"
            spacing={3}
        >

            <Grid item xs={2}>
                <TextField
                    disabled={disabled}
                    name="employeeNumber"
                    label="Employee Number"
                    fullWidth
                    error={Boolean(errors.employeeNumber)}
                    helperText={errors.employeeNumber && 'This field is required'}
                    inputRef={register}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    disabled={disabled}
                    name="givenName"
                    label="First Name"
                    required
                    fullWidth
                    error={Boolean(errors.givenName)}
                    helperText={errors.givenName && 'This field is required'}
                    inputRef={register({ required: true })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={2}>
                <TextField
                    disabled={disabled}
                    name="middleName"
                    label="Middle Name"
                    fullWidth
                    error={Boolean(errors.middleName)}
                    helperText={errors.middleName && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    disabled={disabled}
                    name="familyName"
                    label="Last Name"
                    required
                    fullWidth
                    error={Boolean(errors.familyName)}
                    helperText={errors.familyName && 'This field is required'}
                    inputRef={register({ required: true })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    disabled={disabled}
                    name="emailAddress"
                    label="Email Address"
                    type="email"
                    fullWidth
                    error={Boolean(errors.emailAddress)}
                    helperText={errors.emailAddress && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    disabled={disabled}
                    name="businessPhone"
                    label="Business Phone"
                    fullWidth
                    error={Boolean(errors.businessPhone)}
                    helperText={errors.businessPhone && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    disabled={disabled}
                    name="mobilePhone"
                    label="Mobile Phone"
                    type="tel"
                    fullWidth
                    error={Boolean(errors.mobilePhone)}
                    helperText={errors.mobilePhone && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    disabled={disabled}
                    name="faxNumber"
                    label="Fax Number"
                    type="tel"
                    fullWidth
                    error={Boolean(errors.faxNumber)}
                    helperText={errors.faxNumber && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <ManagerSelect
                    disabled={disabled}
                    name="supervisorId"
                    label="Supervisor"
                    required={false}
                    supervisor={true}
                />

            </Grid>


            <Grid item xs={3}>
                <Dropdown
                    disabled={disabled}
                    name="isSupervisor"
                    label="Is Supervisor"
                    required={false}
                    defaultValue="false"
                    items={[
                        { name: 'Yes', value: 'true' },
                        { name: 'No', value: 'false' }
                    ]}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    name="accountingId"
                    label="Accounting Number"
                    fullWidth
                    error={Boolean(errors.accountingId)}
                    helperText={errors.accountingId && errors.accountingId.message}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

            </Grid>
            <Grid item xs={3}>
                <Dropdown
                    disabled={disabled}
                    name="isActive"
                    label="Active"
                    required={false}
                    defaultValue="true"
                    items={[
                        { name: 'Yes', value: 'true' },
                        { name: 'No', value: 'false' }
                    ]}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    name="activeDirectoryId"
                    label="Active Directory Id"
                    fullWidth
                    error={Boolean(errors.activeDirectoryId)}
                    helperText={errors.activeDirectoryId && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                        className: 'Mui-disabled'
                    }}
                    InputProps={{
                        readOnly: true,
                        className: 'Mui-disabled'
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="userPrincipalName"
                    label="User Principal Name"
                    fullWidth
                    error={Boolean(errors.userPrincipalName)}
                    helperText={errors.userPrincipalName && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                        className: 'Mui-disabled'
                    }}
                    InputProps={{
                        readOnly: true,
                        className: 'Mui-disabled'
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="userName"
                    label="Legacy User Name"
                    fullWidth
                    error={Boolean(errors.userName)}
                    helperText={errors.userName && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                        className: 'Mui-disabled'
                    }}
                    InputProps={{
                        readOnly: true,
                        className: 'Mui-disabled'
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default ManagerForm
