import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SectionHeader from "components/Common/SectionHeader";
import SectionTitle from "components/Common/SectionTitle";
import DataGrid from "components/DataGrid/DataGrid";
import { useDebounce } from "components/Debounce";
import { useFormActions } from "components/Form/FormActions";
import LinkRouter from "components/Navigation/LinkRouter";
import ManagerPicker from "components/Pickers/ManagerPicker";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useRef, useState } from "react";
import URI from "urijs";
import {
  Breadcrumbs,
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Theme,
} from "@material-ui/core";
import { previousPeriod } from "services/TimePeriod";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeading: {
      color: theme.palette.text.primary,
      textAlign: "center",
      marginTop: "20px",
    },
    sectionSubHeading: {
      textAlign: "center",
      fontWeight: "bold",
    },
    clientHeader: {
      marginBottom: 10,
    },
    columnLabel: {
      fontWeight: "bold",
      textAlign: "center",
    },
    columnData: {
      textAlign: "center",
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
      maxWidth: "100%",
    },
    unitSubtotal: {
      textAlign: "right",
      fontWeight: "bold",
      margin: "20px",
    },
    unitTotal: {
      textAlign: "right",
      fontWeight: "bold",
      margin: "30px 20px 30px 0px",
      fontSize: "1.2em",
    },
  })
);

const groupBy = <K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K
): Map<K, Array<V>> => {
  const map = new Map();

  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });

  return map;
};

const timePeriod = previousPeriod();

const TimeSheetReport = () => {
  const classes = useStyles();
  const theme = useTheme();

  const { loadAction } = useFormActions();
  const [data, setData] = useState<any[]>([]);

  const [caseManagers, setCaseManagers] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string>(
    timePeriod.startDate.toISODate()
  );
  const [endDate, setEndDate] = useState<string>(
    timePeriod.endDate.toISODate()
  );

  const delayedStartDate = useDebounce(startDate, 500);
  const delayedEndDate = useDebounce(endDate, 500);

  const groupData = useCallback((data: any[]) => {
    let result = [];
    let index = 1;
    let otherHours = 0;
    let workHours = 0;
    let totalHours = 0;

    const sorted = data.sort((a: any, b: any) => {
      var nameA = a.CaseManager.toUpperCase();
      var nameB = b.CaseManager.toUpperCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    const grouped = groupBy(sorted, (item) => item.CaseManager);

    grouped.forEach((value: any[], key: any) => {
      // create group parent row
      const indexItem = {
        _index: index++,
        _type: "GroupTotal",

        CaseManager: key,
        EmployeeNumber: '',
        OtherHours: 0,
        WorkHours: 0,
        TotalHours: 0,
      };

      // link group to parent and total units
      value.forEach((item: any) => {
        item._index = index++;
        item._parent = indexItem._index;

        indexItem.EmployeeNumber = item.EmployeeNumber;
        indexItem.OtherHours += item.OtherHours;
        indexItem.WorkHours += item.WorkHours;
        indexItem.TotalHours += item.TotalHours;

        result.push(item);
      });

      // push total row
      result.push(indexItem);

      // track overall total
      otherHours += indexItem.OtherHours;
      workHours += indexItem.WorkHours;
      totalHours += indexItem.TotalHours;
    });

    // create grand total row
    const totalItem = {
      _index: index++,
      _type: "GrandTotal",

      CaseManager: "Total",
      OtherHours: otherHours,
      WorkHours: workHours,
      TotalHours: totalHours,
    };
    result.push(totalItem);

    setData(result);
  }, []);

  // load data
  const loadData = useCallback(
    (caseManagerIds: number[], startDate: string, endDate: string) => {
      const url = URI("/Report/TimeSheetByCaseManager").setSearch({
        caseManagerIds,
        startDate,
        endDate,
      });

      loadAction(url.toString(), "Time Sheet Output", (data: any) => {
        groupData(data);
      });
    },
    [loadAction, groupData]
  );

  const totalStyle = (data: any[], rowData: any): React.CSSProperties => {
    const properties: React.CSSProperties = {
      fontWeight:
        rowData._type === "GroupTotal" || rowData._type === "GrandTotal"
          ? "bold"
          : "inherit",
      fontSize: rowData._type === "GrandTotal" ? "1.3em" : "inherit",
    };

    return properties;
  };

  useEffect(() => {
    if (
      !DateTime.fromISO(delayedStartDate).isValid ||
      !DateTime.fromISO(delayedEndDate).isValid ||
      !caseManagers
    ) {
      return;
    }

    const ids = caseManagers ? caseManagers.map((m) => m.id) : [];

    loadData(ids, delayedStartDate, delayedEndDate);
  }, [loadData, caseManagers, delayedStartDate, delayedEndDate]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginTop: "-10px", marginBottom: "10px" }}
      >
        <LinkRouter to="/">Home</LinkRouter>
        <LinkRouter to="/reports">Reports</LinkRouter>
        <Typography color="textPrimary">Time Sheet Output</Typography>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <SectionHeader title="Time Sheet Output Report">
          <p></p>
        </SectionHeader>
        <SectionTitle
          title="Time Sheet Output Report"
          icon={faChartBar}
          color={theme.palette.text.primary}
        />
        <fieldset className="standard">
          <legend>Parameters</legend>
          <Grid
            style={{
              marginTop: "5px",
              marginBottom: "5px",
            }}
            container
            direction="row"
            spacing={4}
          >
            <Grid item xs={3}>
              <TextField
                name="startDate"
                label="Start Date"
                type="date"
                fullWidth
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                name="endDate"
                label="End Date"
                type="date"
                fullWidth
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <ManagerPicker
                selected={caseManagers}
                setSelected={setCaseManagers}
                defaultCurrentUser={false}
              />
            </Grid>
          </Grid>
        </fieldset>
        <DataGrid
          exportFile={`Time Sheet ${DateTime.local().toISODate()}`}
          columns={[
            {
              field: "CaseManager",
              title: "Case Manager",
              cellStyle: totalStyle,
            },
            {
              field: "EmployeeNumber",
              title: "Employee Number",
              cellStyle: totalStyle,
            },
            {
              field: "EntryDate",
              title: "Date",
              width: 190,
              type: "date",
            },
            { field: "AmTimeIn", title: "Time In" },
            { field: "AmTimeOut", title: "Time Out" },
            { field: "PmTimeIn", title: "Time In" },
            { field: "PmTimeOut", title: "Time Out" },
            {
              field: "OtherHoursType",
              title: "Other Hours Type",
            },
            {
              field: "OtherHours",
              title: "Other Hours",
              width: 150,
              type: "numeric",
              cellStyle: totalStyle,
            },
            {
              field: "WorkHours",
              title: "Worked Hours",
              width: 150,
              type: "numeric",
              cellStyle: totalStyle,
            },
            {
              field: "TotalHours",
              title: "Total Hours",
              width: 150,
              type: "numeric",
              cellStyle: totalStyle,
            },
          ]}
          data={data}
          parentChildData={(row, rows) =>
            rows.find((a) => a._index === row._parent)
          }
          options={{
            paging: false,
            sorting: false,
          }}
        />
      </Paper>
    </Container>
  );
};

export default TimeSheetReport;
