import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import FacilityStayForm from './FacilityStayForm';
import { FacilityStaySchema } from 'models/Schemas';


interface Props {
    clientId: string
}

const FacilityStayCreate = ({ clientId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();

    const saveFacilityStay = (formData: any) => {
        const data = formToData(formData, FacilityStaySchema);

        createAction(
            '/FacilityStay', data,
            'facility stay',
            () => history.push(`/clients/${clientId}/facility`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Facility Stay">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveFacilityStay)} noValidate autoComplete="off">
                    <FacilityStayForm clientId={clientId} />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/facility`}
                        submitLabel="Save Stay"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default FacilityStayCreate
