import { makeStyles, Grid, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import Dropdown from './TimeEntryDropDown';
import Cell from './TimeEntryCell';
import { useFormContext, useWatch } from 'react-hook-form';
import { TimeEntryType } from 'models/TimeSheetModel';
import { convertTimeStringToMilliseconds, msToTime } from 'utils/dateUtils';
import { AddBoxRounded, IndeterminateCheckBoxRounded } from '@material-ui/icons';

interface Props {
    index: number;
    date: string;
    timeEntry?: TimeEntryType | undefined;
    disabled?: boolean;
    handleAdd: (index: number, date: string) => void;
    handleRemove: (index: number, date: string) => void;
    removeRow?: boolean
}

const useStyles = makeStyles(() => ({
    buttonInput: {
        backgroundColor: "lightgray",
        borderBottomStyle: "solid",
        borderColor: "black"
    },
    textField: {
    },
    inputLeft: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        paddingLeft: "10px",
    },
    inputLabel: {
        borderRightStyle: "solid",
        borderColor: "black",
        //borderBottomStyle: "solid",
        paddingLeft: "10px",
        backgroundColor: "lightgray",
    },
    inputRight: {
        borderBottomStyle: "solid",
        paddingLeft: "10px",
    }
}));

const TimeSheetRow = (props: Props) => {
    const { errors, register, setValue, setError, clearErrors } = useFormContext();
    const classes = useStyles();
    const { index, date, timeEntry, disabled = false, handleAdd, handleRemove, removeRow = false } = props;

    const shortDayName = new Date(`${date} `).toLocaleDateString('en-US', { weekday: 'short' })
    const shortDay = shortDayName.toUpperCase()

    const EntryDateLabel = "entryDate-" + index;
    const AmTimeInLabel = "amTimeIn-" + index;
    const AmTimeOutLabel = "amTimeOut-" + index;
    const PmTimeInLabel = "pmTimeIn-" + index;
    const PmTimeOutLabel = "pmTimeOut-" + index;
    const OtherHoursLabel = "otherHours-" + index;
    const HoursWorkedLabel = "hoursWorked-" + index;
    const DropDownLabel = "otherHoursType-" + index;
    const TotalHoursLabel = "totalHours-" + index;

    const [combinedHours, setCombinedHours] = useState<number>()

    const rowWatchedValues = useWatch({ name: [`amTimeIn-${index}`, `amTimeOut-${index}`, `pmTimeIn-${index}`, `pmTimeOut-${index}`, `otherHours-${index}`] });

    const amTimeOut = rowWatchedValues[`amTimeOut-${index}`]
    const amTimeIn = rowWatchedValues[`amTimeIn-${index}`]
    const amTotalHours = amTimeOut && amTimeIn ? Number(convertTimeStringToMilliseconds(amTimeOut)
        - convertTimeStringToMilliseconds(amTimeIn)) : 0
    const amInvalid = amTotalHours < 0

    const pmTimeOut = rowWatchedValues[`pmTimeOut-${index}`]
    const pmTimeIn = rowWatchedValues[`pmTimeIn-${index}`]
    const pmTotalHours = pmTimeOut && pmTimeIn ? Number(convertTimeStringToMilliseconds(pmTimeOut)
        - convertTimeStringToMilliseconds(pmTimeIn)) : 0
    const pmInvalid = pmTotalHours < 0

    const totalHoursWorkedPerDay = msToTime(amTotalHours + pmTotalHours)
    const otherHoursTotalPerDay = rowWatchedValues[`otherHours-${index}`] ?? 0

    useEffect(() => {
        const hours = Number(otherHoursTotalPerDay) + Number(totalHoursWorkedPerDay)
        setCombinedHours(hours > 0 ? hours : 0)

        // Validate the time entries
        if (amInvalid) {
            setError(`TimeEntryCellError-${index}-amInvalid`, { type: 'custom' })
        } else {
            clearErrors(`TimeEntryCellError-${index}-amInvalid`)
        }
        if (pmInvalid) {
            setError(`TimeEntryCellError-${index}-pmInvalid`, { type: 'custom' })
        } else {
            clearErrors(`TimeEntryCellError-${index}-pmInvalid`)
        }
    }, [otherHoursTotalPerDay, totalHoursWorkedPerDay, amInvalid, pmInvalid])

    return (
        <Grid
            container
            direction="row"
        >
            <Grid item xs={1}>
                <Grid container direction="row" alignItems="center" wrap="nowrap" className={classes.buttonInput}>
                    <Grid item>
                        <AddBoxRounded onClick={() => handleAdd(index, date)} />
                    </Grid>
                    <Grid item>
                        {removeRow && <IndeterminateCheckBoxRounded onClick={() => handleRemove(index, date)} />}
                    </Grid>
                    <Grid item>
                        <TextField
                            disabled={true}
                            InputProps={{
                                className: classes.inputLabel
                            }}
                            value={shortDay}
                        />
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={2}>
                <Cell
                    name={EntryDateLabel}
                    type="date"
                    date={date}
                    disabled={disabled}
                    readOnly
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={AmTimeInLabel}
                    type="time"
                    timeEntryData={timeEntry?.amTimeIn}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={AmTimeOutLabel}
                    type="time"
                    timeEntryData={timeEntry?.amTimeOut}
                    disabled={disabled}
                    amInvalid={amTotalHours < 0}
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={PmTimeInLabel}
                    type="time"
                    timeEntryData={timeEntry?.pmTimeIn}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={PmTimeOutLabel}
                    type="time"
                    timeEntryData={timeEntry?.pmTimeOut}
                    disabled={disabled}
                    pmInvalid={pmTotalHours < 0}
                />
            </Grid>
            <Grid item xs={2}>
                <Dropdown
                    name={DropDownLabel}
                    required={false}
                    items={[
                        { name: 'PTO', value: 'PTO' },
                        { name: 'PT_PTO (For Part-Time Employees Only)', value: 'PT_PTO' },
                        { name: 'Holiday', value: 'Holiday' },
                        { name: 'HolidayFlex', value: 'HolidayFlex' },
                        { name: 'Bereavement', value: 'Bereavement' },
                        { name: 'JuryDuty', value: 'JuryDuty' }
                    ]}
                    timeEntryData={timeEntry?.otherHoursType}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={OtherHoursLabel}
                    type="number"
                    timeEntryData={timeEntry?.otherHours}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={HoursWorkedLabel}
                    type="number"
                    timeEntryData={totalHoursWorkedPerDay > 0 ? totalHoursWorkedPerDay?.toString() : '0'}
                    disabled={disabled}
                    readOnly
                />
            </Grid>
            <Grid item xs={1}>
                <Cell
                    name={TotalHoursLabel}
                    type="number"
                    timeEntryData={combinedHours?.toString()}
                    disabled={disabled}
                    readOnly
                />
            </Grid>
        </Grid>
    )
}

export default TimeSheetRow
