import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';

const ContactTypeForm = () => {
    const form = useFormContext();

    return (
        <Grid
            style={{
                marginTop: "5px",
                marginBottom: "5px"
            }}
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={8}>
                <TextField
                    name="name"
                    label="Name"
                    required
                    fullWidth
                    error={Boolean(form.errors.name)}
                    helperText={form.errors.name && 'This field is required'}
                    inputRef={form.register({ required: true })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="displayOrder"
                    label="Display Order"
                    fullWidth
                    type="number"
                    error={Boolean(form.errors.displayOrder)}
                    helperText={form.errors.displayOrder && 'This field is required'}
                    inputRef={form.register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="description"
                    label="Description"
                    fullWidth                    
                    inputRef={form.register}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default ContactTypeForm
