import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean
}

const ProgramSelect = ({
    label = "Program",
    name = "programId",
    required = true,
    disabled = false
}: Props) => {

    const form = useFormContext();
    const { errors, register, setValue } = form;
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()

    const formValue = useWatch<any>({name: name});

    // load data
    const { data: options } = useFetch('/Program', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);

        // set form value
        setValue(name, selectedOption?.id, {
            shouldValidate: true,
            shouldDirty: true
        });
    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options || !formValue) {
            return;
        }

        // find the selected options
        const current = options.find((f: any) => f.id === Number(formValue));
        setSelected(current);

    }, [formValue, options])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}                        
                styles={selectStyles}
                isClearable={true}
                options={options}
                getOptionLabel={(option: any) => `${option.code} - ${option.name}`}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default ProgramSelect
