import { Breadcrumbs, Container, Tooltip, Typography } from '@material-ui/core';
import { useFormActions } from 'components/Form/FormActions';
import LinkRouter from 'components/Navigation/LinkRouter';
import TabNavigationBar from 'components/Navigation/TabNavigationBar';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import AgreementDetail from './AgreementDetail';
import AgreementHistory from './AgreementHistory';
import { AgreementSchema } from 'models/Schemas';
import { dataToForm } from 'services/DataTransform';
import AgreementNoteCreate from './Notes/AgreementNoteCreate';
import AgreementNoteEdit from './Notes/AgreementNoteEdit';
import AgreementNoteList from './Notes/AgreementNoteList';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';
import { AbilityContext } from 'components/AuthorizationProvider';

const AgreementEdit = () => {
    const { agreementId } = useParams();
    const match = useRouteMatch();
    const [agreement, setAgreement] = useState<any>({});
    const { loadAction } = useFormActions();
    const ability = useContext(AbilityContext);

    const tabs = [
        {
            label: 'Details',
            location: `/agreements/${agreementId}`,
            matchPath: `${match.path}`
        },
        {
            label: 'Notes',
            location: `/agreements/${agreementId}/notes`,
            matchPath: `${match.path}/notes`
        },
        {
            label: 'History',
            location: `/agreements/${agreementId}/history`,
            matchPath: `${match.path}/history`,
            hide: ability.cannot("read", "ServiceAgreementHistory")
        }
    ];

    const loadAgreement = useCallback(() => {
        loadAction(
            `/ServiceAgreement/${agreementId}`,
            'agreement',
            (data) => {
                const formData = dataToForm(data, AgreementSchema);
                setAgreement(formData);

            }
        );
    }, [loadAction, agreementId]);

    useEffect(() => {
        loadAgreement();
    }, [loadAgreement]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/agreements">Agreements</LinkRouter>
                <Typography color="textPrimary">
                    {agreement &&
                        <Tooltip interactive
                            title={
                                <Fragment>
                                    <Typography color="inherit">Service Agreement Number {agreement.serviceAgreementNumber}</Typography>
                                    {agreement.startDate && <Fragment>Start Date: {agreement.startDate}<br /></Fragment>}
                                    {agreement.endDate && <Fragment>End Date: {agreement.endDate}<br /></Fragment>}
                                    {agreement.unitsAllocated && <Fragment>Units Allocated: {agreement.unitsAllocated}<br /></Fragment>}
                                </Fragment>
                            }>
                            <span>Agreement {agreement.serviceAgreementNumber}</span>
                        </Tooltip>
                    }
                </Typography>
            </Breadcrumbs>

            <TabNavigationBar tabs={tabs} />

            {agreementId &&
                <Switch>
                    <ProtectedRoute path={`${match.path}/notes/create`} action="create" subject="ServiceNote">
                        <AgreementNoteCreate agreementId={agreementId} />
                    </ProtectedRoute>
                    <Route path={`${match.path}/notes/:noteId`}>
                        <AgreementNoteEdit agreementId={agreementId} />
                    </Route>
                    <Route path={`${match.path}/notes`}>
                        <AgreementNoteList agreementId={agreementId} />
                    </Route>
                    <Route path={`${match.path}/history`}>
                        <AgreementHistory agreementId={agreementId} />
                    </Route>
                    <Route path={match.path}>
                        <AgreementDetail agreementId={agreementId} />
                    </Route>
                </Switch>
            }
        </Container>
    )
}

export default AgreementEdit
