import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid, TextField } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { useDebounce } from 'components/Debounce';
import { DateTime } from 'luxon';
import URI from 'urijs';

const BatchBillingRange = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ days: 14 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);
    const mode = 0;

    // load data
    const loadData = useCallback((startDate: string, endDate: string) => {
        const url = URI("/Report/BatchBilling")
            .setSearch({
                startDate,
                endDate,
                mode
            });

        loadAction(
            url.toString(),
            'Batch Billing Output',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        if (!DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid
        ) {
            setTimeout(() => setData([]), 0);
            return;
        }

        loadData(delayedStartDate, delayedEndDate);
    }, [loadData, delayedStartDate, delayedEndDate]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Batch Billing Output</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Batch Billing Output - Date Range">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Batch Billing Output Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={4}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Batch Billing Output ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientID', title: 'Client ID', width: 190 },
                        { field: 'ClientLastName', title: 'Client Last', width: 190 },
                        { field: 'ClientFirstName', title: 'Client First', width: 190 },
                        { field: 'DateOfBirth', title: 'Birth Date', width: 190, type: 'date' },
                        { field: 'MA', title: 'MA Number', width: 190 },
                        { field: 'ServiceAgreementNumber', title: 'Service Agreement Number', width: 190 },
                        { field: 'ServiceAgreementStart', title: 'Service Agreement Start', width: 190, type: 'date' },
                        { field: 'ServiceAgreementEnd', title: 'Service Agreement End', width: 190, type: 'date' },
                        { field: 'ServiceProcedureCode', title: 'Procedure Code', width: 190 },
                        { field: 'ProcedureModifier1', title: 'Modifier 1', width: 220 },
                        { field: 'ProcedureModifier2', title: 'Modifier 2', width: 220 },
                        { field: 'ProcedureModifier3', title: 'Modifier 3', width: 220 },
                        { field: 'ProcedureModifier4', title: 'Modifier 4', width: 220 },
                        { field: 'NumberOfUnits', title: 'Units', width: 190, type: 'numeric' },
                        { field: 'Charge', title: 'Charge', width: 190, type: 'numeric' },
                        { field: 'StartDate', title: 'Start Date', width: 190, type: 'date' },
                        { field: 'EndDate', title: 'End Date', width: 190, type: 'date' },
                        { field: 'ICD10', title: 'ICD10', width: 190 },
                        { field: 'ClientAddress', title: 'Address', width: 280 },
                        { field: 'ClientCity', title: 'City', width: 240 },
                        { field: 'ClientState', title: 'State', width: 190 },
                        { field: 'ClientZipCode', title: 'Zip', width: 190 },
                        { field: 'ClientGender', title: 'Gender', width: 190 },
                        { field: 'FacilityTypeCode', title: 'Facility Type', width: 190, type: 'numeric' },
                        { field: 'UnitsIdentifierCode', title: 'Units Identifier Code', width: 190 },
                        { field: 'ServiceFacilityTypeCode', title: 'Service Facility Type Code', width: 190, type: 'numeric' },
                        { field: 'Diagnosis', title: 'Diagnosis', width: 190 },
                    ]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default BatchBillingRange
