import { Button, Paper } from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useProgress } from 'components/Progress/ProgressProvider';
import { useStyles } from 'components/Styles';
import { AgreementSchema } from 'models/Schemas';
import React, { useCallback, useContext, useEffect } from 'react';
import BlockUi from 'react-block-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ClientAgreementForm from './ClientAgreementForm';
import { DateTime } from 'luxon';

interface Props {
    clientId: string
}

const ClientAgreementEdit = ({ clientId }: Props) => {
    const { agreementId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    
    const { reset, handleSubmit, formState, setError, clearErrors } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "ServiceAgreement")

    const loadAgreement = useCallback(() => {
        loadAction(
            `/ServiceAgreement/${agreementId}`,
            'agreement',
            (data) => {
                const formData = dataToForm(data, AgreementSchema);
                reset(formData);
            }
        );
    }, [loadAction, agreementId, reset]);

    const saveAgreement = (formData: any) => {
        if (!allowEdit) return;

        formData.clientId = clientId;
        let fromDate = DateTime.fromISO(formData.startDate);
        let toDate = DateTime.fromISO(formData.endDate);
        if(toDate.diff(fromDate, 'days').days < 0){
            setError("endDate", {
                message: 'End Date Cannot be less than the Start Date',
                shouldFocus: true,
            });
            return;
        }
        else{
            clearErrors("endDate");
        }        
        const data = formToData(formData, AgreementSchema);

        updateAction(
            `/ServiceAgreement/${agreementId}`, data,
            `service agreement '${data.serviceAgreementNumber}'`,
            () => {
                history.push(`/clients/${clientId}/agreements`);
            }
        );
    }

    useEffect(() => {
        loadAgreement();
    }, [loadAgreement]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Service Agreement Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveAgreement)} noValidate autoComplete="off">
                        <ClientAgreementForm 
                            clientId={clientId}
                            disabled={!allowEdit} />
                        <FormButtons
                            dirty={formState.isDirty}
                            disabled={!allowEdit}
                            cancelLink={`/clients/${clientId}/agreements`}
                            submitLabel="Save Agreement"
                        >
                            <Button
                                type="button"
                                variant="contained"
                                disabled={!allowEdit}
                                onClick={() => history.push(`/clients/${clientId}/agreements/${agreementId}/reallocate`)}
                            >
                                Reallocate
                            </Button>
                        </FormButtons>
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientAgreementEdit
