import React, { useEffect } from 'react'
import { FormControl, InputLabel } from '@material-ui/core';
import ReactSelect from 'react-select';
import useFetch, { CachePolicies } from 'use-http';
import { useAuthorization } from 'components/AuthorizationProvider';
import { selectStyles } from 'components/Styles';

interface Props {
    label?: string;
    multiselect?: boolean;
    defaultCurrentUser?: boolean
    selected: any;
    setSelected: (value: any) => void;
}

const ManagerPicker = ({
    label = "Case Manager",
    multiselect = true,
    defaultCurrentUser = true,
    selected,
    setSelected
}: Props) => {

    const userInfo = useAuthorization();

    // load data
    const { data: managers } = useFetch('/CaseManager?sort=familyName', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    useEffect(() => {
        if (!managers || !defaultCurrentUser) return;

        const id = userInfo.caseManagerId;
        const current = managers.find((f: any) => f.id === id);

        setSelected([current]);
    }, [userInfo, managers, setSelected]);

    return (
        <FormControl fullWidth>
            <InputLabel shrink>
                {label}
            </InputLabel>

            <ReactSelect
                styles={selectStyles}
                isClearable={true}
                options={managers}
                isMulti={multiselect}
                getOptionLabel={(option: any) => option.fullName}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={value => {
                    setSelected(value);
                }}
            />
        </FormControl>
    )
}

export default ManagerPicker
