import { DateTime } from "luxon";

const toForm = (type: string, value: any): string => {
    if (typeof value === 'undefined') {
        return '';
    } else if (value === null) {
        return '';
    }

    switch (type) {
        case 'date':
            // keep in utc format
            const date = DateTime.fromISO(value);
            // format for browser input type=date
            return date.toISODate();
        case 'number':
        case 'boolean':
            return String(value);
        default:
            return value;
    }
}

const toData = (type: string, value: any): any => {
    if (value === '') {
        return null
    }

    if (typeof value === type) {
        return value;
    }

    switch (type) {
        case 'number':
            return parseFloat(value)
        case 'boolean':
            if (typeof value === 'string') {
                switch (value?.toLowerCase()?.trim()) {
                    case 'true':
                    case 'yes':
                    case '1':
                        return true;
                    case 'false':
                    case 'no':
                    case '0':
                        return false;
                }
            }

            return Boolean(value);
        default:
            return value;
    }
}


export const dataToForm = (data: any, schema: { [field: string]: string; }): any => {
    const transformed: { [field: string]: string; } = {};

    Object.keys(data).forEach((key: string) => {

        const type = schema[key];
        const value = data[key]

        transformed[key] = toForm(type, value);
    });

    return transformed
}

export const formToData = (form: any, schema: { [field: string]: string; }): any => {
    const transformed: { [field: string]: any; } = {};

    Object.keys(form).forEach((key: string) => {

        const type = schema[key];
        const value = form[key]

        transformed[key] = toData(type, value);
    });

    return transformed
}
