import {
    Input,
    Popper,
    ClickAwayListener,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import React, { useEffect, useState, useCallback } from 'react';
import { useDebounce } from '../Debounce';
import { useFormActions } from 'components/Form/FormActions';
import { Link, useHistory } from 'react-router-dom';
import { alpha } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '265px',
            },
        },
        searchPopper: {
            zIndex: 1300
        },
        searchMenu: {
            border: '1px solid rgb(191, 191, 191)',
            borderRadius: 4,
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 4px 11px',
            width: '320px',
        },
        searchList: {
            maxHeight: 300,
            overflowY: 'auto',
            padding: '4px 0',
            position: 'relative'
        },
        searchResult: {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            display: 'block',
            width: '100%',
            userSelect: 'none',
            '-webkit-tap-highlight-color': 'rgba(0, 0, 0, 0)',
            padding: '8px 12px',
            '&:hover': {
                backgroundColor: 'rgb(222, 235, 255)',
            },
        },
        searchNoResults: {
            padding: '.5rem',
            opacity: '.5'
        },
        searchLinks: {
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: 5
        }
    }),
);

interface Props {
    clientType: boolean
}

const SearchBar = ({ clientType }: Props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [results, setResults] = useState<null | any[]>(null);
    const [searchText, setSearchText] = useState<String>('');
    const delayedSearch = useDebounce(searchText, 500);
    const { loadAction } = useFormActions();
    const history = useHistory();

    const open = Boolean(anchorEl);
    const id = open ? 'search-popper' : undefined;

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    };

    const handleClickAway = (e: React.MouseEvent<any, MouseEvent>) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleClick = (client: any) => {
        history.push(`/clients/${client.id}`);
        setAnchorEl(null);
    };

    const loadSearch = useCallback((query: string, searchType: boolean) => {
        const searchQuery = encodeURI(query);
        loadAction(
            `/Client/Search?q=${searchQuery}`,
            'client search',
            (data) => {
                let finalSearch: any[] = [];
                if(searchType)
                    finalSearch = data.filter((d:any) => !d?.exitDate)
                else{
                    data.forEach((el: any) => {
                        let isAlreadyIn = finalSearch.find((d: any) => d?.id === el?.id);
                        if(!isAlreadyIn )
                            finalSearch.push(el);
                    });
                }
    
                finalSearch.reverse();
                setResults(finalSearch);
            }
        );
    }, [loadAction])

    useEffect(() => {
        if (loadSearch && delayedSearch) {
            loadSearch(delayedSearch, clientType)
        }
        else {
            setResults(null);
        }
    }, [loadSearch, delayedSearch, clientType])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <Input
                    disableUnderline
                    placeholder="Search"
                    type="search"
                    onFocus={handleFocus}
                    onChange={handleChange}
                    value={searchText}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    className={classes.searchPopper}
                    placement="bottom-end">
                    <div className={classes.searchMenu}>
                        <div className={classes.searchList}>
                            {(results == null || results.length === 0) &&
                                <div className={classes.searchNoResults}>
                                    No Results Found
                                </div>
                            }
                            {results && results.map(result => (
                                <div 
                                    className={classes.searchResult} 
                                    onClick={() => handleClick(result)}
                                    key={result.id}>
                                    <h4>
                                        {result.familyName}, {result.middleName}{result.middleName ? '.' : ''} {result.givenName}
                                    </h4>
                                    <small>
                                        {result.addressLine1}, {result.city}, {result.stateProvince} {result.postalCode}
                                    </small>
                                    <small className={classes.searchLinks}>
                                        <Link 
                                            to={`/clients/${result.id}`} 
                                            onClick={handleClickAway}
                                            title="View Client Details">
                                            Details
                                        </Link>
                                        <Link 
                                            to={`/clients/${result.id}/contacts`} 
                                            onClick={handleClickAway}
                                            title="View Client Contacts">
                                            Contacts
                                        </Link>
                                        <Link 
                                            to={`/clients/${result.id}/agreements`} 
                                            onClick={handleClickAway} 
                                            title="View Client Service Agreements">
                                            Agreements
                                        </Link>
                                        <Link 
                                            to={`/clients/${result.id}/notes`} 
                                            onClick={handleClickAway} 
                                            title="View Client Service Notes">
                                            Notes
                                        </Link>
                                    </small>
                                </div>
                            ))}

                        </div>
                    </div>
                </Popper>
            </div>
        </ClickAwayListener >
    )
}

export default SearchBar
