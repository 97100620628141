import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
} from '@material-ui/core';
import { Can, AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientCaseList = ({ clientId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "Case")

    const loadCases = useCallback(() => {
        loadAction(
            `/client/${clientId}/case`,
            'client cases',
            setData
        );
    }, [loadAction, clientId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/case/${data.id}`,
            `case '${data.id}'`,
            loadCases
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${clientId}/cases/${data.id}`)
    };

    useEffect(() => {
        loadCases();
    }, [loadCases]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Client Cases">
                <Can do="create" on="Case">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/clients/${clientId}/cases/create`)}
                    >
                        Add Case
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Cases"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="Cases"
                columns={[
                    { field: 'id', title: 'Case', width: 100, type: 'numeric' },

                    { field: 'clientId', title: 'Client Id', hidden: true, export: true },
                    { field: 'clientFamilyName', title: 'Client Last', hidden: true, export: true },
                    { field: 'clientGivenName', title: 'Client First', hidden: true, export: true },
                    { field: 'clientEmailAddress', title: 'Client Email', hidden: true, export: true },

                    { field: 'transferDate', title: 'Transfer Date', width: 200, type: 'date', defaultSort: 'desc' },
                    { field: 'waiverSpanStartDate', title: 'Waiver Start', width: 200, type: 'date' },
                    { field: 'waiverSpanEndDate', title: 'Waiver End', width: 200, type: 'date' },
                    { field: 'exitDate', title: 'Exit Date', width: 200, type: 'date' },

                    { field: 'caseManagerId', title: 'Case Manager Id', hidden: true, export: true },
                    { field: 'caseManagerName', title: 'Case Manager' },
                    { field: 'caseManagerEmailAddress', title: 'Case Manager Email', hidden: true, export: true },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                ]}
                data={data}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default ClientCaseList
