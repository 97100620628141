import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Breadcrumbs,
  Button,
  Container,
  Paper,
  Typography,
  Select,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import SectionHeader from "components/Common/SectionHeader";
import SectionTitle from "components/Common/SectionTitle";
import DataGrid from "components/DataGrid/DataGrid";
import LinkRouter from "components/Navigation/LinkRouter";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useStyles } from "components/Styles";
import { useFormActions } from "components/Form/FormActions";
import { useFetch } from "use-http";
import {
  AbilityContext,
  Can,
  useAuthorization,
} from "components/AuthorizationProvider";
import { MTableToolbar } from "material-table";

const ClientList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const userInfo = useAuthorization();

  const { loadAction, deleteAction } = useFormActions();
  const [data, setData] = useState<any[]>([]);

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [newfilteredData, setNewFilteredData] = useState<any[]>([]);

  const { data: managers } = useFetch(
    "/CaseManager?sort=FamilyName&q=IsActive%3Dtrue",
    []
  );
  const [filter, setFilter] = useState<string>(String(userInfo.caseManagerId));

  const ability = useContext(AbilityContext);
  const allowDelete = ability.can("delete", "Client");

  const loadClients = useCallback(() => {
    loadAction("/client", "clients", setData);
  }, [loadAction]);

  const handleDelete = (event: any, data: any) => {
    if (!data) {
      return;
    }

    deleteAction(
      `/client/${data.id}`,
      `client '${data.familyName}, ${data.givenName}'`,
      loadClients
    );
  };

  const handleEdit = (event: any, data: any) => {
    history.push(`/clients/${data.id}`);
  };

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setFilter(selectedValue);
  };

  const managerRender = (rowData: any) => {
    if (!rowData || !rowData.caseManagers) {
      return "";
    }

    const dataSet = new Set<string>(
      rowData.caseManagers.map((m: any) => m.fullName)
    );
    return Array.from(dataSet).join("; ");
  };

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  useEffect(() => {
    const updatedFilteredData = data
      ?.map((row: any) => {
        const addressParts = row.fullAddress.split(", ");
        const addressLine2 = addressParts.slice(1).join(", ");

        return { ...row, addressLine2 };
      })
      .filter((row: any) => {
        if (!filter || filter === "" || !row.caseManagers) {
          return true;
        }

        return row.caseManagers.some(
          (manager: any) => manager.id === Number(filter)
        );
      });

    setNewFilteredData(updatedFilteredData);
  }, [data, filter]);

  useEffect(() => {
    const filtered = data?.filter((row: any) => {
      if (!filter || filter === "" || !row.caseManagers) {
        return true;
      }

      return row.caseManagers.some(
        (manager: any) => manager.id === Number(filter)
      );
    });

    setFilteredData(filtered);
  }, [data, filter]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginTop: "-10px", marginBottom: "10px" }}
      >
        <LinkRouter to="/">Home</LinkRouter>
        <Typography color="textPrimary">Clients</Typography>
      </Breadcrumbs>

      <Paper className={classes.paper}>
        <SectionHeader title="Clients">
          <Can do="create" on="Client">
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={() => history.push("/clients/create")}
            >
              Add Client
            </Button>
          </Can>
        </SectionHeader>
        <SectionTitle
          title="Client List"
          icon={faUser}
          color={theme.palette.text.primary}
        />
        <DataGrid
          exportFile="Clients"
          columns={[
            { field: "id", title: "Id", hidden: true, export: true },

            { field: "fullName", title: "Name", defaultSort: "asc" },

            { field: "familyName", title: "Last", hidden: true, export: true },
            { field: "givenName", title: "First", hidden: true, export: true },
            {
              field: "middleName",
              title: "Middle",
              hidden: true,
              export: true,
            },

            { field: "fullAddress", title: "Address" },

            {
              field: "addressLine1",
              title: "Address Line 1",
              hidden: true,
              export: true,
            },
            {
              field: "addressLine2",
              title: "Address Line 2",
              hidden: true,
              export: true,
            },
            { field: "city", title: "City", hidden: true, export: true },
            {
              field: "stateProvince",
              title: "State",
              hidden: true,
              export: true,
            },
            { field: "postalCode", title: "Zip", hidden: true, export: true },

            {
              field: "emailAddress",
              title: "Email",
              hidden: true,
              export: true,
            },
            { field: "businessPhone", title: "Business Phone", width: 170 },
            { field: "homePhone", title: "Home Phone", width: 170 },
            { field: "mobilePhone", title: "Mobile Phone", width: 170 },
            {
              field: "faxNumber",
              title: "Fax Number",
              hidden: true,
              export: true,
            },

            {
              field: "caseManager",
              title: "Case Manager",
              render: managerRender,
            },

            {
              field: "created",
              title: "Created",
              hidden: true,
              export: true,
              type: "date",
            },
            {
              field: "createdBy",
              title: "Created By",
              hidden: true,
              export: true,
            },
            {
              field: "updated",
              title: "Updated",
              hidden: true,
              export: true,
              type: "date",
            },
            {
              field: "updatedBy",
              title: "Updated By",
              hidden: true,
              export: true,
            },
          ]}
          data={filteredData}
          components={{
            Toolbar: (props) => (
              <div className={classes.toolbarWrapper}>
                <Select
                  native
                  className={classes.toolbarSelector}
                  value={filter}
                  onChange={handleFilterChange}
                >
                  <option aria-label="None" value="">
                    - All Case Managers -
                  </option>
                  {managers &&
                    managers.map((item: any) => (
                      <option key={item.id} value={item.id}>
                        {item.fullName}
                      </option>
                    ))}
                </Select>
                <MTableToolbar
                  classes={{
                    spacer: classes.clearSpacer,
                  }}
                  {...props}
                />
              </div>
            ),
          }}
          actions={[
            {
              icon: "create",
              tooltip: "Edit Item",
              onClick: handleEdit,
            },
            {
              icon: "delete",
              tooltip: "Delete Item",
              onClick: handleDelete,
              hidden: !allowDelete,
            },
          ]}
        />
      </Paper>
    </Container>
  );
};

export default ClientList;
