import {
    faBook,
    faChartBar,
    faFileMedicalAlt,
    faNotesMedical,
    faUser,
    faUserCog
} from '@fortawesome/free-solid-svg-icons';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import DashboardSection from './DashboardSection';
import { AbilityContext, Can } from 'components/AuthorizationProvider';

interface Props {

}
const Dashboard = (props: Props) => {
    const theme = useTheme();
    const ability = useContext(AbilityContext);

    return (
        <Container maxWidth={false}>
            <Grid container spacing={3}>
                <Can do="read" on="Client">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Clients"
                            icon={faUser}
                            color={theme.palette.primary.main}
                            links={[
                                { location: "/clients", label: "View/Edit Clients" },
                                { location: "/clients/create", label: "Add New Client", hide: ability.cannot("create", "Client") },
                            ]}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="ServiceAgreement">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Service Agreements"
                            icon={faNotesMedical}
                            color={theme.palette.primary.main}
                            links={[
                                { location: "/agreements", label: "View/Edit Service Agreements" },
                                { location: "/agreements/create", label: "Add New Service Agreement", hide: ability.cannot("create", "ServiceAgreement") },
                            ]}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Service">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Services"
                            icon={faFileMedicalAlt}
                            color={theme.palette.info.main}
                            links={[
                                { location: "/services", label: "View/Edit Services" },
                                { location: "/services/create", label: "Add New Service", hide: ability.cannot("create", "Service") },
                            ]}
                        />

                    </Grid>
                </Can>
                <Can do="read" on="Program">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Programs"
                            icon={faBook}
                            color={theme.palette.info.main}
                            links={[
                                { location: "/programs", label: "View/Edit Programs" },
                                { location: "/programs/create", label: "Add New Program", hide: ability.cannot("create", "Program") },
                            ]}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Reports">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Reports"
                            icon={faChartBar}
                            color={theme.palette.error.main}
                            links={[
                                { location: "/reports", label: "View Reports" },
                            ]}
                        />

                    </Grid>
                </Can>
                <Can do="read" on="TimeSheet">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="TimeSheets"
                            icon={faChartBar}
                            color={theme.palette.error.main}
                            links={[
                                { location: "/TimeSheet", label: "View/Edit TimeSheets" },
                                { location: "/TimeSheet/create", label: "Add New TimeSheet", hide: ability.cannot("create", "TimeSheet") },
                            ]}
                        />

                    </Grid>
                </Can>
                <Can do="read" on="Administration">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Administration"
                            icon={faUserCog}
                            color={theme.palette.error.main}
                            links={[
                                { location: "/administration", label: "View Administration" },
                            ]}
                        />
                    </Grid>
                </Can>
            </Grid>
        </Container>
    )
}

export default Dashboard
