import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import CaseSelect from 'components/Form/CaseSelect';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    disabled?: boolean;
    clientId: string;
}

const FacilityStayForm = ({ clientId, disabled = false }: Props) => {
    const { errors, register } = useFormContext();
    const theme = useTheme();

    return (
        <React.Fragment>
            <SectionTitle
                title="Facility Stay"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <CaseSelect
                        disabled={disabled}                        
                        name="caseId"
                        label="Case"
                        required={true}
                        clientId={clientId}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}                        
                        name="admitted"
                        label="Admitted"
                        required
                        fullWidth
                        type="date"
                        error={Boolean(errors.admitted)}
                        helperText={errors.admitted && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}                        
                        name="discharged"
                        label="Discharged"
                        fullWidth
                        type="date"
                        error={Boolean(errors.discharged)}
                        helperText={errors.discharged && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}                        
                        name="description"
                        label="Description"
                        fullWidth
                        error={Boolean(errors.description)}
                        helperText={errors.description && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default FacilityStayForm
