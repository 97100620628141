import {
    FormControl,
    FormHelperText,
    InputLabel,
    makeStyles,
    styled,
    TextField,
    Tooltip,
    TooltipProps
} from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    name: string;
    disabled?: boolean;
    action?: string;
    subject?: string;
    field?: string;
    type?: string;
    propClass?: { name: string, value: string | number | boolean }[];
    date?: string;
    timeEntryData?: string
    readOnly?: boolean
    amInvalid?: boolean
    pmInvalid?: boolean
}

const useStyles = makeStyles(() => ({
    textField: {
    },
    input: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        paddingLeft: "10px",
    },
    ErrorHelper: {
        marginTop: "0px"
    }
}));

const Cell = (props: Props) => {
    const {
        name,
        disabled = false,
        action,
        subject,
        field,
        type,
        propClass,
        date,
        timeEntryData,
        readOnly = false,
        amInvalid = false,
        pmInvalid = false } = props;

    const timeEntry = timeEntryData && timeEntryData.length > 6 ? new Date(timeEntryData).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }).replace(' AM', '').replace(' PM', '') // strip out 'AM' and 'PM' to update time input value
        : timeEntryData;

    const { errors, register, setValue } = useFormContext();
    const timeError = amInvalid || pmInvalid
    const hasError = Boolean(errors[name] || timeError);
    const [selected, setSelected] = useState<any>();
    const ability = useContext(AbilityContext);
    const classes = useStyles();
    const formValue = useWatch<any>({ name: name });

    const denied = disabled || Boolean(action && subject && ability.cannot(action, subject, field ?? name));

    let inputProps: any = {
        className: classes.input,
        readOnly: readOnly
    }
    if (type === 'time') {
        inputProps = { ...inputProps, inputProps: { step: 900 } }
    } else if (type === 'number') {
        inputProps = { ...inputProps, inputProps: { min: 0, max: 24 } }
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let selectedValue = event.target.value as string;
        const selectedValueFloat = parseFloat(selectedValue)
        // Allow user to empty the other hours field. Replaces empty string with 0
        if (type === 'number' && selectedValue === '') selectedValue = '0'

        let isValid = true
        if (type === 'number' &&
            selectedValue !== '0' &&
            (selectedValueFloat > 24 ||
                selectedValueFloat < 0)) {
            isValid = false
        }

        if (isValid) {
            setSelected(selectedValue);

            setValue(name, selectedValue, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    };

    useEffect(() => {
        if (formValue !== undefined) {
            setSelected(formValue);
        }
    }, [formValue])

    useEffect(() => {
        register({ name: name });
        if (type === "date" && date) setValue(name, date)
        if (timeEntry) setValue(name, timeEntry)
    }, [register, name, date, timeEntry]);

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        ['& .MuiTooltip-tooltip']: {
            backgroundColor: 'white',
            color: 'red',
            fontSize: theme.typography.pxToRem(16),
            border: '1px solid red',
        },
        ['& .MuiTooltip-arrow']: {
            color: 'red'
        },
        ['& .MuiTooltip-tooltipPlacementBottom']: {
            margin: '8px 0'
        }
    }));

    const renderTextField = () => {
        return <TextField
            name={name}
            id={name}
            type={type}
            fullWidth
            onChange={handleChange}
            InputProps={inputProps}
            // TODO: clean this up. Too many ternary statements is hard to read and confusing
            // Selected takes first priority since the user is 'selecting' something
            // If type is date then fill in the rest of the time sheet for the 2 week period from the pay period from field
            // Time entries will come from the db from previous selected
            value={(selected ? selected : type === "date" && date ? date : timeEntry ? timeEntry : selected) || ''}
            disabled={disabled}
            error={timeError} />;
    }

    return (
        <FormControl
            fullWidth
            disabled={denied}
            error={hasError}
        >
            {type !== 'number' ?
                <HtmlTooltip title={timeError && "Out time must be after In time."} open={timeError} arrow>
                    {renderTextField()}
                </HtmlTooltip> : renderTextField()}
        </FormControl>
    )
}

export default Cell
