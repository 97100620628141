import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    agreementId: number
}

const ClientAgreementReallocateForm = (props: Props) => {
    const { errors, register } = useFormContext();
    const theme = useTheme();
    const [createFacilityStay, setCreateFacilityStay] = useState<boolean>(false);

    return (
        <React.Fragment>
            <SectionTitle
                title="Reallocation Information"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <TextField
                        name="serviceAgreementId"
                        label="Service Agreement"
                        fullWidth
                        disabled
                        error={Boolean(errors.serviceAgreementId)}
                        helperText={errors.serviceAgreementId && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="originalEndDate"
                        label="Original Agreement End Date"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.originalEndDate)}
                        helperText={errors.originalEndDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="originalUnitsAllocated"
                        label="Original Agreement Units Allocated"
                        type="number"
                        required
                        fullWidth
                        error={Boolean(errors.originalUnitsAllocated)}
                        helperText={errors.originalUnitsAllocated && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Switch
                                name="checkedB"
                                color="primary"
                                checked={createFacilityStay}
                                onChange={(event) => setCreateFacilityStay(event.target.checked)}
                            />
                        }
                        label="Create Facility Stay"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="facilityStayAdmittedDate"
                        label="Facility Stay Admitted"
                        type="date"
                        disabled={!createFacilityStay}
                        required
                        fullWidth
                        error={Boolean(errors.facilityStayAdmittedDate)}
                        helperText={errors.facilityStayAdmittedDate && 'This field is required'}
                        inputRef={register({ required: createFacilityStay })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="facilityStayDischargedDate"
                        label="Facility Stay Discharged"
                        type="date"
                        disabled={!createFacilityStay}
                        required
                        fullWidth
                        error={Boolean(errors.facilityStayDischargedDate)}
                        helperText={errors.facilityStayDischargedDate && 'This field is required'}
                        inputRef={register({ required: createFacilityStay })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        name="newStartDate"
                        label="New Agreement Start Date"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.newStartDate)}
                        helperText={errors.newStartDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="newEndDate"
                        label="New Agreement End Date"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.newEndDate)}
                        helperText={errors.newEndDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="newUnitsAllocated"
                        label="New Agreement Units Allocated"
                        type="number"
                        required
                        fullWidth
                        error={Boolean(errors.newUnitsAllocated)}
                        helperText={errors.newUnitsAllocated && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientAgreementReallocateForm
