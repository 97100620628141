import React from 'react'

interface Props {
    visible: boolean;
    animationDuration: number;
    children: React.ReactNode;
}

const Container = ({ children, visible, animationDuration }: Props) => {
    return (
        <div
            style={{
                opacity: visible ? 1 : 0,
                pointerEvents: 'none',
                transition: `opacity ${animationDuration}ms linear`,
            }}
        >
            {children}
        </div>
    )
}

export default Container
