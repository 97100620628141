import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import React from 'react';

export interface ConfirmationOptions {
    variant: "danger" | "info";
    title: string;
    description: string;
}

interface Props extends ConfirmationOptions {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export const ConfirmationDialog = (props: Props) => {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.variant === "danger" && (
                    <>
                        <Button color="primary" onClick={props.onClose} autoFocus>
                            Cancel
                        </Button>
                        <Button color="primary" onClick={props.onSubmit}>
                            Yes
                        </Button>
                    </>
                )}
                {props.variant === "info" && (
                    <Button color="primary" onClick={props.onSubmit}>
                        OK
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}