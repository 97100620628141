import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import LanguageForm from './LanguageForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { dataToForm, formToData } from 'services/DataTransform';
import { LanguageSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';

const LanguageEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { languageId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadLanguage = useCallback(() => {
        loadAction(
            `/Language/${languageId}`,
            'language',
            data => {
                const formData = dataToForm(data, LanguageSchema);
                reset(formData);
            }
        );
    }, [loadAction, languageId, reset]);

    const saveLanguage = (formData: any) => {
        const data = formToData(formData, LanguageSchema);

        updateAction(
            `/Language/${languageId}`, data,
            `language '${data.code}'`,
            () => {
                history.push('/administration/languages');
            }
        );
    }

    useEffect(() => {
        loadLanguage();
    }, [loadLanguage]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/languages">Languages</LinkRouter>
                <Typography color="textPrimary">Edit Language</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update Language">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Language Information"
                    icon={faGlobe}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveLanguage)} noValidate>
                            <LanguageForm />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/languages"
                                submitLabel="Save Language"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default LanguageEdit
