import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import AgreementList from './AgreementList';
import AgreementCreate from './AgreementCreate';
import AgreementEdit from './AgreementEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const AgreementRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="ServiceAgreement">
                <AgreementCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:agreementId`} action="read" subject="ServiceAgreement">
                <AgreementEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="ServiceAgreement">
                <AgreementList />
            </ProtectedRoute>
        </Switch>
    )
}

export default AgreementRoute
