import React, { createContext, useContext, useState, useEffect } from 'react'
import { authenticationContext } from 'services/Authentication';
import { ConfigurationContext } from './Configuration';
import { adalGetToken } from 'react-adal';
import { Ability, createAliasResolver } from '@casl/ability';
import { createContextualCan } from '@casl/react';

export interface AuthorizationData {
    displayName: string;
    emailAddress: string;
    userName: string;
    caseManagerId: number;
    employeeNumber: string;

    allowAdministrator: boolean;
    allowSupervisor: boolean;
    allowBilling: boolean;
    allowPayroll: boolean;
    allowParaprofessional: boolean;
    allowCaseManager: boolean;

    rules: AuthorizationRule[];
}

export interface AuthorizationRule {
    action: string;
    subject: string;
}

const defaultContext = {
    displayName: 'Anonymous',
    emailAddress: '',
    userName: 'Anonymous',
    caseManagerId: 0,
    employeeNumber: '',

    allowAdministrator: false,
    allowSupervisor: false,
    allowBilling: false,
    allowPayroll: false,
    allowParaprofessional: false,
    allowCaseManager: false,
    rules: []
};

const resolveAction = createAliasResolver({
    modify: ['read', 'create', 'update'],
    view: ['read'],
    edit: ['update']
});

export const ability = new Ability([], { resolveAction });

export const AbilityContext = createContext<Ability>(ability);
export const AuthorizationContext = createContext<AuthorizationData>(defaultContext);

export const useAuthorization = () => useContext(AuthorizationContext);
export const Can = createContextualCan(AbilityContext.Consumer);

interface Props {
    children: React.ReactNode
}

export const AuthorizationProvider = ({ children }: Props) => {
    const [authorization, setAuthorization] = useState<AuthorizationData>(defaultContext);

    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const configuration = useContext(ConfigurationContext);

    useEffect(() => {
        const fetchData = async () => {

            setError(null);
            setBusy(true);

            try {
                const url = `${configuration.serviceUrl}/authorization`;
                const token = await adalGetToken(authenticationContext, configuration.clientId as string);

                const result = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`
                    }
                });

                if (!result.ok) {
                    console.error('Error loading authorization', result);
                    return;
                }

                const data: AuthorizationData = await result.json();

                setAuthorization(original => {
                    return { ...original, ...data };
                });
            } catch (error) {
                console.error('Error loading authorization', error);
                setError(error);
            } finally {
                setBusy(false);
                setLoaded(true);
            }
        };

        fetchData();
    }, [configuration]);

    useEffect(() => {
        if (authorization && authorization.rules) {
            ability.update(authorization.rules);
        }
    }, [authorization])

    return (
        <AuthorizationContext.Provider value={authorization}>
            <AbilityContext.Provider value={ability}>
                {error && <div>Something went wrong ...</div>}
                {busy || !loaded ? <div className="App-loading"><h3>Loading ...</h3></div> : children}
            </AbilityContext.Provider>
        </AuthorizationContext.Provider>
    )
}

export default AuthorizationProvider
