import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle'; import Dropdown from 'components/Form/Dropdown';
import RemoteSelect from 'components/Form/RemoteSelect';
import React from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    disabled?: boolean;
}

const EligibilityForm = ({ disabled = false }: Props) => {
    const { errors, register } = useFormContext();
    const theme = useTheme();
    const subject = "Eligibility";
    const action = 'update';

    return (
        <React.Fragment>
            <input
                type="hidden"
                name="correlationId"
                ref={register}
            />
            <input
                type="hidden"
                name="billingDelayedReasonId"
                ref={register}
            />

            <SectionTitle
                title="Eligibility"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >

                <Grid item xs={2}>
                    <Dropdown
                        disabled={disabled}
                        name="isBillingDelayed"
                        label="Billing Delayed"
                        required={false}
                        defaultValue="false"
                        items={[
                            { name: 'Yes', value: 'true' },
                            { name: 'No', value: 'false' }
                        ]}
                    />
                </Grid>

                <Grid item xs={4}>
                    <RemoteSelect
                            action={action}
                            subject={subject}
                            name="billingDelayedReasonId"
                            label="Billing Delayed Reason"
                            required={false}
                            url="/BillingDelayedReason"
                            optionRender={(item: any) =>
                                <option key={item.id} value={item.id}>{item.description}</option>
                            }
                        />                    
                    {/* <TextField
                        name="billingDelayedReason"
                        label="Billing Delayed Reason"
                        fullWidth
                        error={Boolean(errors.billingDelayedReason)}
                        helperText={errors.billingDelayedReason && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                            className: 'Mui-disabled'
                        }}
                        InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled'
                        }}
                    /> */}
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="eligibilityYear"
                        label="Eligibility Year"
                        type="number"
                        fullWidth
                        error={Boolean(errors.eligibilityYear)}
                        helperText={errors.eligibilityYear && errors.eligibilityYear.message}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="eligibilityMonth"
                        label="Eligibility Month"
                        type="number"
                        fullWidth
                        error={Boolean(errors.eligibilityMonth)}
                        helperText={errors.eligibilityMonth && errors.eligibilityMonth.message}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="typeWaiver"
                        label="Waiver Type"
                        fullWidth
                        error={Boolean(errors.typeWaiver)}
                        helperText={errors.typeWaiver && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="coverageStatus"
                        label="Coverage Status"
                        fullWidth
                        error={Boolean(errors.coverageStatus)}
                        helperText={errors.coverageStatus && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>


                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="eligibilityType"
                        label="Eligibility Type"
                        fullWidth
                        error={Boolean(errors.eligibilityType)}
                        helperText={errors.eligibilityType && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>


                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="eligibilityDate"
                        label="Eligibility Date"
                        fullWidth
                        type="date"
                        error={Boolean(errors.eligibilityDate)}
                        helperText={errors.eligibilityDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <TextField
                        disabled={disabled}
                        name="planDate"
                        label="Plan Date"
                        fullWidth
                        type="date"
                        error={Boolean(errors.planDate)}
                        helperText={errors.planDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="planBeginDate"
                        label="Plan Begin Date"
                        fullWidth
                        type="date"
                        error={Boolean(errors.planBeginDate)}
                        helperText={errors.planBeginDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="planEndDate"
                        label="Plan End Date"
                        fullWidth
                        type="date"
                        error={Boolean(errors.planEndDate)}
                        helperText={errors.planEndDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={disabled}
                        name="medicalSpenddown"
                        label="Medical Spenddown"
                        fullWidth
                        error={Boolean(errors.medicalSpenddown)}
                        helperText={errors.medicalSpenddown && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={disabled}
                        name="spenddownAmount"
                        label="Spenddown Amount"
                        type="number"
                        fullWidth
                        error={Boolean(errors.spenddownAmount)}
                        helperText={errors.spenddownAmount && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}
                        name="mco"
                        label="MCO"
                        fullWidth
                        error={Boolean(errors.mco)}
                        helperText={errors.mco && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        disabled={disabled}
                        name="mcoMiscInfo"
                        label="MCO Misc Info"
                        fullWidth
                        error={Boolean(errors.mCOMiscInfo)}
                        helperText={errors.mCOMiscInfo && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <fieldset className="standard">
                        <legend>Medicare Part A</legend>
                        <Grid
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px"
                            }}
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartAStatus"
                                    label="Part A Status"
                                    fullWidth
                                    error={Boolean(errors.medicarePartAStatus)}
                                    helperText={errors.medicarePartAStatus && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartAEff"
                                    label="Part A Eff"
                                    fullWidth
                                    error={Boolean(errors.medicarePartAEff)}
                                    helperText={errors.medicarePartAEff && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartATerm"
                                    label="Part A Term"
                                    fullWidth
                                    error={Boolean(errors.medicarePartATerm)}
                                    helperText={errors.medicarePartATerm && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>

                </Grid>

                <Grid item xs={12}>
                    <fieldset className="standard">
                        <legend>Medicare Part B</legend>
                        <Grid
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px"
                            }}
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartBStatus"
                                    label="Part B Status"
                                    fullWidth
                                    error={Boolean(errors.medicarePartBStatus)}
                                    helperText={errors.medicarePartBStatus && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartBEff"
                                    label="Part B Eff"
                                    fullWidth
                                    error={Boolean(errors.medicarePartBEff)}
                                    helperText={errors.medicarePartBEff && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disabled}
                                    name="medicarePartBTerm"
                                    label="Part B Term"
                                    fullWidth
                                    error={Boolean(errors.medicarePartBTerm)}
                                    helperText={errors.medicarePartBTerm && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>


                <Grid item xs={12}>
                    <fieldset className="standard">
                        <legend>Other Additional Coverage 1</legend>
                        <Grid
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px"
                            }}
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage1"
                                    label="Coverage 1"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage1)}
                                    helperText={errors.otherAdditionalCoverage1 && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage1EffectiveDate"
                                    label="Effective Date"
                                    type="date"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage1EffectiveDate)}
                                    helperText={errors.otherAdditionalCoverage1EffectiveDate && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage1EndDate"
                                    label="End Date"
                                    type="date"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage1EndDate)}
                                    helperText={errors.otherAdditionalCoverage1EndDate && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage1PolicyNumber"
                                    label="Policy Number"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage1PolicyNumber)}
                                    helperText={errors.otherAdditionalCoverage1PolicyNumber && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage1GroupNumber"
                                    label="Group Number"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage1GroupNumber)}
                                    helperText={errors.otherAdditionalCoverage1GroupNumber && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>

                </Grid>

                <Grid item xs={12}>
                    <fieldset className="standard">
                        <legend>Other Additional Coverage 2</legend>

                        <Grid
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px"
                            }}
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage2"
                                    label="Coverage 2"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage2)}
                                    helperText={errors.otherAdditionalCoverage2 && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage2EffectiveDate"
                                    label="Effective Date"
                                    type="date"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage2EffectiveDate)}
                                    helperText={errors.otherAdditionalCoverage2EffectiveDate && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage2EndDate"
                                    label="End Date"
                                    type="date"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage2EndDate)}
                                    helperText={errors.otherAdditionalCoverage2EndDate && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage2PolicyNumber"
                                    label="Policy Number"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage2PolicyNumber)}
                                    helperText={errors.otherAdditionalCoverage2PolicyNumber && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={disabled}
                                    name="otherAdditionalCoverage2GroupNumber"
                                    label="Group Number"
                                    fullWidth
                                    error={Boolean(errors.otherAdditionalCoverage2GroupNumber)}
                                    helperText={errors.otherAdditionalCoverage2GroupNumber && 'This field is required'}
                                    inputRef={register()}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default EligibilityForm
