import React, { useContext } from 'react';
import { adalGetToken } from 'react-adal';
import {
    CachePolicies,
    IncomingOptions,
    Provider,
    Res
} from 'use-http';
import { authenticationContext } from '../services/Authentication';
import { ConfigurationContext } from './Configuration'
import { useProgress } from './Progress/ProgressProvider'

interface Props {
    children: React.ReactNode;
}

export const validateResponse = (data: any, response: Res<any>) => {
    if (response.ok) {
        return data
    }

    if (data && data.title) {
        console.log(data);
        throw new Error(data.title);
    }

    const status = `${response.status ?? ''} ${response.statusText ?? ''}`;
    const message = `Unrecognized response from server ${status}`;

    throw new Error(message);
}

export const GatewayProvider = (props: Props) => {
    const configuration = useContext(ConfigurationContext);
    const progress = useProgress();

    const options = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        interceptors: {
            request: async ({ options, url, path, route }) => {
                progress.start();
                const token = await adalGetToken(authenticationContext, configuration.clientId as string);

                options.headers = {
                    ...options.headers,
                    Authorization: `Bearer ${token}`
                }
                return options
            },
            response: async ({ response }) => {
                progress.complete();
                return response;
            }
        },
        cachePolicy: CachePolicies.NO_CACHE,
        onError: (error: any) => {
            progress.complete();
        },
        onAbort: () => {
            progress.complete();
        }
    } as IncomingOptions

    return (
        <Provider
            url={configuration.serviceUrl}
            options={options}
        >
            {props.children}
        </Provider>
    )
}

export default GatewayProvider
