import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useState } from 'react';
import { useStyles } from 'components/Styles';
import FormButtons from 'components/Form/FormButtons';
import { useForm, FormProvider } from 'react-hook-form';
import UploadSelect from 'components/Form/UploadSelect';
import UploadMapping, { matchFields } from 'components/Form/UploadMapping';
import { FieldMap } from 'models/ImportDefinition'
import { useFormActions } from 'components/Form/FormActions';
import useFetch, { CachePolicies } from 'use-http';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DateTime } from 'luxon';

const EligibilityImport = () => {
    const classes = useStyles();
    const theme = useTheme();
    const form = useForm();

    const [fileFields, setFileFields] = useState<FieldMap[] | null>(null);
    const [fileData, setFileData] = useState<any[] | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const [result, setResult] = useState<any | null>(null);

    const [eligibilityYear, setEligibilityYear] = useState<number>(DateTime.local().year);
    const [eligibilityMonth, setEligibilityMonth] = useState<number>(DateTime.local().month);

    const { reset, handleSubmit } = form;
    const { createAction } = useFormActions();

    const { data: eligibilityImport } = useFetch('/ClientEligibility/import/definition', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    const fileSelected = (data: any[], fileName?: string) => {
        setResult(null);
        setFileName(fileName ?? '');

        if (!data || data.length === 0) return;

        setFileData(data);

        const headers: FieldMap[] = data[0]
            .map((name: string, index: number) => { return { name, index } });

        setFileFields(headers);

        const formData = matchFields(eligibilityImport, headers);

        reset(formData);
    }

    const processImport = (mappedFields: any) => {
        const mapping: FieldMap[] = [];

        Object.keys(mappedFields).forEach((key) => {
            mapping.push({
                name: key,
                index: parseFloat(mappedFields[key])
            });
        });

        const importData = {
            hasHeader: true,
            fileName: fileName,
            data: fileData,
            mappings: mapping
        };

        const url = `/ClientEligibility/import?eligibilityYear=${eligibilityYear}&eligibilityMonth=${eligibilityMonth}`;
        
        createAction(
            url, importData,
            'Client Eligibility',
            (data: any) => {
                setFileName('');
                setFileData(null);
                setFileFields(null);

                setResult(data);
            }
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Import Client Eligibility</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                {result &&
                    <Alert
                        severity={result.errors && result.errors.length > 0 ? 'warning' : 'success'}
                        style={{ marginBottom: '1em' }}>
                        <AlertTitle>
                            {result.errors && result.errors.length > 0 ? 'Warning' : 'Success'}
                        </AlertTitle>
                        <h4 style={{ margin: '1em 0' }}>
                            {result.message}
                        </h4>
                        <ul>
                            {result.errors && result.errors.map((error: string) => (
                                <li>{error}</li>
                            ))}
                        </ul>
                    </Alert>
                }

                <FormHeader
                    title="Import Client Eligibility"
                    middle={<Typography style={{ fontWeight: 600 }} color="textPrimary">{fileName}</Typography>}
                >
                    <UploadSelect
                        importDefinition={eligibilityImport}
                        fileSelected={fileSelected}
                    />
                </FormHeader>

                <SectionTitle
                    title="Map Data Fields"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />
                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={4}>
                            <TextField
                                name="eligibilityYear"
                                label="Eligibility Year"
                                type='number'
                                fullWidth
                                value={eligibilityYear}
                                onChange={(event: any) => setEligibilityYear(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Eligibility Month
                                </InputLabel>

                                <Select native
                                    value={eligibilityMonth}
                                    onChange={(event: any) => setEligibilityMonth(event.target.value)}
                                >
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April </option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </fieldset>

                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(processImport)} noValidate>
                        <UploadMapping
                            importDefinition={eligibilityImport}
                            fileFields={fileFields}
                        />
                        <FormButtons
                            dirty={Boolean(fileData && fileFields)}
                            cancelLink="/reports"
                            submitLabel="Import"
                        />
                    </form>
                </FormProvider>

            </Paper >
        </Container >
    )
}

export default EligibilityImport
