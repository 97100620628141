import React, { useContext } from 'react';
import { AbilityContext } from 'components/AuthorizationProvider';
import { Route, RouteProps } from 'react-router-dom'
import Unauthorized from 'components/Unauthorized';

interface Props extends RouteProps {
    action: string;
    subject: string;
    field?: string;
}

const ProtectedRoute = ({
    action,
    subject,
    field,
    ...rest
}: Props): JSX.Element => {
    const ability = useContext(AbilityContext);
    const allowed = ability.can(action, subject, field)
    return (
        allowed
            ? <Route {...rest} />
            : <Unauthorized />
    )
}

export default ProtectedRoute

