import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, FormControl, Grid, InputLabel, Paper, Select, TextField, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import URI from 'urijs';

interface Props {

}

const EligibilityExport = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [onlyDelayed, setOnlyDelayed] = useState<boolean>(false);
    const [eligibilityYear, setEligibilityYear] = useState<number>(DateTime.local().year);
    const [eligibilityMonth, setEligibilityMonth] = useState<number>(DateTime.local().month);

    const loadData = useCallback((onlyDelayed: boolean, eligibilityYear: number, eligibilityMonth: number) => {
        const url = URI("/Report/EligibilityExport")
            .setSearch({
                onlyDelayed,
                eligibilityYear,
                eligibilityMonth
            });

        loadAction(
            url.toString(),
            'Eligibility Export',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        loadData(onlyDelayed, eligibilityYear, eligibilityMonth);
    }, [loadData, onlyDelayed, eligibilityYear, eligibilityMonth]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Export Client Eligibility</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Export Client Eligibility">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Client Eligibility"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={2}
                    >
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Show Only Delayed Clients
                                </InputLabel>

                                <Select native
                                    value={onlyDelayed}
                                    onChange={(event: any) => setOnlyDelayed(event.target.value)}
                                >
                                    <option value="false">false</option>
                                    <option value="true">true</option>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                name="eligibilityYear"
                                label="Eligibility Year"
                                type='number'
                                fullWidth
                                value={eligibilityYear}
                                onChange={(event: any) => setEligibilityYear(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Eligibility Month
                                </InputLabel>

                                <Select native
                                    value={eligibilityMonth}
                                    onChange={(event: any) => setEligibilityMonth(event.target.value)}
                                >
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April </option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </fieldset>


                <DataGrid
                    exportFile={`Client Eligibility ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'InquiringProviderName', title: 'Inquiring Provider Name' },
                        { field: 'PayerName', title: 'Payer Name' },
                        { field: 'SubscriberId', title: 'Subscriber ID', width: 150 },
                        { field: 'PatientFirstName', title: 'Client First Name' },
                        { field: 'PatientLastName', title: 'Client Last Name', defaultSort: 'asc' },
                        { field: 'PatientDateOfBirth', title: 'Date of Birth', width: 150, type: 'date' },
                    ]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default EligibilityExport
