import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
} from '@material-ui/core';
import { AbilityContext, Can } from 'components/AuthorizationProvider';

interface Props {
    serviceId: string
}

const ServiceRateList = ({ serviceId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "ServiceRate")

    const loadRates = useCallback(() => {
        loadAction(
            `/Service/${serviceId}/Rate`,
            'Service Rates',
            setData
        );
    }, [loadAction, serviceId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/ServiceRate/${data.id}`,
            'Service Rate',
            loadRates
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/services/${serviceId}/rates/${data.id}`)
    };

    useEffect(() => {
        loadRates();
    }, [loadRates]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Service Rates">
                <Can do="create" on="ServiceRate">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/services/${serviceId}/rates/create`)}
                    >
                        Add Rate
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Service Rates"
                icon={faFileMedicalAlt}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="ServiceRates"
                columns={[
                    { field: 'id', title: 'Id', hidden: true, export: true },

                    { field: 'serviceId', title: 'Service Id', hidden: true, export: true },
                    { field: 'procedureCode', title: 'Procedure', width: 250 },
                    { field: 'serviceName', title: 'Service Name' },

                    { field: 'effectiveStart', title: 'Start', width: 180, type: 'date', defaultSort: 'desc' },
                    { field: 'effectiveEnd', title: 'End', width: 180, type: 'date' },

                    { field: 'unitRate', title: 'Rate', width: 140, type: 'currency' },

                    { field: 'description', title: 'Description', hidden: true, export: true },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true }
                ]}
                data={data}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default ServiceRateList
