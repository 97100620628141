import React from 'react'
import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader'
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';

import {
    faHome,
    faUser,
    faFileMedicalAlt,
    faFileMedical,
    faNotesMedical,
    faBook,
    faChartBar,
    faUserFriends,
    faUserCog,
    faProcedures,
    faMedkit
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ListItemLink from './ListItemLink'
import { Can } from 'components/AuthorizationProvider';

interface Props {
    isDrawerOpen: boolean
    handleDrawerClose: ((event: any) => void) | undefined;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
}));

const SideDrawer = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer
            variant="persistent"
            anchor="left"
            classes={{
                paper: classes.drawerPaper,
            }}
            open={props.isDrawerOpen}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={props.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<FontAwesomeIcon icon={faHome} size="lg" />}
                />
                <Can do="read" on="Client">
                    <ListItemLink
                        to="/clients"
                        primary="Clients"
                        color={theme.palette.primary.main}
                        icon={<FontAwesomeIcon icon={faUser} size="lg" />}
                    />
                </Can>
                <Can do="read" on="ServiceAgreement">
                    <ListItemLink
                        to="/agreements"
                        primary="Service Agreements"
                        color={theme.palette.primary.main}
                        icon={<FontAwesomeIcon icon={faNotesMedical} size="lg" />}
                    />
                </Can>
                <Can do="read" on="Service">
                    <ListItemLink
                        to="/services"
                        primary="Services"
                        color={theme.palette.info.main}
                        icon={<FontAwesomeIcon icon={faFileMedicalAlt} size="lg" />}
                    />
                </Can>
                <Can do="read" on="Program">
                    <ListItemLink
                        to="/Programs"
                        primary="Programs"
                        color={theme.palette.info.main}
                        icon={<FontAwesomeIcon icon={faBook} size="lg" />}
                    />
                </Can>
            </List>
            <Divider />
            <List>
                <ListSubheader>Administration</ListSubheader>
                <ListItemLink
                    to="/administration"
                    primary="View Administration"
                    color={theme.palette.error.main}
                    icon={<FontAwesomeIcon icon={faUserCog} size="lg" />}
                />
                <Can do="read" on="CaseManager">
                    <ListItemLink
                        to="/administration/managers"
                        primary="Case Managers"
                        color={theme.palette.error.main}
                        icon={<FontAwesomeIcon icon={faUserFriends} size="lg" />}
                    />
                </Can>
                <Can do="read" on="MedicalCode">
                    <ListItemLink
                        to="/administration/codes"
                        primary="Medical Codes"
                        color={theme.palette.error.main}
                        icon={<FontAwesomeIcon icon={faProcedures} size="lg" />}
                    />
                </Can>
                <Can do="read" on="ServiceModifier">
                    <ListItemLink
                        to="/administration/modifiers"
                        primary="Service Modifiers"
                        color={theme.palette.error.main}
                        icon={<FontAwesomeIcon icon={faFileMedical} size="lg" />}
                    />
                </Can>
                <Can do="read" on="ActivityType">
                    <ListItemLink
                        to="/administration/activities"
                        primary="Activity Types"
                        color={theme.palette.error.main}
                        icon={<FontAwesomeIcon icon={faMedkit} size="lg" />}
                    />
                </Can>
            </List>
            <Divider />
            <List>
                <ListSubheader>Reports</ListSubheader>
                <ListItemLink
                    to="/reports"
                    primary="View Reports"
                    color={theme.palette.error.main}
                    icon={<FontAwesomeIcon icon={faChartBar} size="lg" />}
                />
            </List>
        </Drawer>
    )
}

export default SideDrawer
