import { AppBar, Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export interface TabLink {
    location: string,
    label: string,
    matchPath: string,
    hide?: boolean
}

interface Props {
    tabs: TabLink[];
}

const TabNavigationBar = ({ tabs }: Props) => {
    const match = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);

        const tab = tabs[newValue];
        history.push(tab.location);
    };

    useEffect(() => {
        if (!location || !match || !tabs) return;

        // sync selected tab
        tabs.forEach((tab: TabLink, index: number) => {
            if (matchPath(location.pathname, { path: tab.matchPath })) {

                setTabIndex(index);
                return;
            }
        })

    }, [location, match, tabs]);

    return (
        <AppBar position="static" color="default">
            <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabs && tabs.filter(l => l.hide !== true).map((tab: TabLink, index: number) =>
                    <Tab key={index} label={tab.label} style={{ minWidth: 110 }} />
                )}
            </Tabs>
        </AppBar>
    )
}

export default TabNavigationBar
