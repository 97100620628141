import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid, TextField } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import ServicePicker from 'components/Pickers/ServicePicker';
import { DateTime } from 'luxon';


const UnitAggregate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [services, setServices] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);


    // load data
    const loadData = useCallback((serviceIds: number[], startDate: string, endDate: string) => {
        const url = URI("/Report/UnitAggregate")
            .setSearch({
                serviceIds,
                startDate,
                endDate,
            });

        loadAction(
            url.toString(),
            'Unit Aggregate',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        if (!services
            || services.length === 0
            || !DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid
        ) {
            setTimeout(() => setData([]), 0);
            return;
        }

        const ids = services.map(m => m.id);

        loadData(ids, delayedStartDate, delayedEndDate);
    }, [loadData, services, delayedStartDate, delayedEndDate]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Unit Aggregate</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Unit Aggregate">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Unit Aggregate Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={8}>
                            <ServicePicker
                                multiselect={true}
                                selected={services}
                                setSelected={setServices}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Unit Aggregate ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'DisplayName', title: 'Name' },
                        { field: 'CalendarYear', title: 'Year', width: 190 },
                        { field: 'MonthOfYear', title: 'Month', width: 190 },
                        { field: 'ProcedureCode', title: 'Procedure' },
                        { field: 'TotalDischargedClients', title: 'Discharged Clients', width: 190, type: 'numeric' },
                        { field: 'TotalNewClients', title: 'New Clients', width: 190, type: 'numeric' },
                        { field: 'TotalUnitsUsed', title: 'Units Used', width: 190, type: 'numeric' },
                    ]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default UnitAggregate
