import { faFileMedical } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ModifierForm from './ModifierForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import { ModifierSchema } from 'models/Schemas';

const ModifierCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveModifier = (formData: any) => {
        const data = formToData(formData, ModifierSchema);
        actions.createAction(
            '/Modifier', data,
            `modifier '${data.code}'`,
            () => history.push('/administration/modifiers')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/modifiers">Modifiers</LinkRouter>
                <Typography color="textPrimary">Add Modifier</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add Service Modifier">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Modifier Information"
                    icon={faFileMedical}
                    color={theme.palette.text.primary}
                />
                <FormProvider  {...form}>
                    <form onSubmit={form.handleSubmit(saveModifier)} noValidate>
                        <ModifierForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/administration/modifiers"
                            submitLabel="Save Modifier"
                        />
                    </form>
                </FormProvider >
            </Paper>
        </Container>
    )
}

export default ModifierCreate
