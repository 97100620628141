import { Paper, IconButton, MenuItem, Menu, Tooltip } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientPayerForm from './ClientPayerForm';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import { PayerSchema } from 'models/Schemas';
import { usePopupState, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    client: any
}

const ClientPayerCreate = ({ client }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();
    const popupState = usePopupState({ variant: 'popover', popupId: 'companyMenu' })

    const clientId = client?.id;

    // load data
    const { data: companies } = useFetch('/Company?sort=name', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    const handleCopyCompany = (company: any) => {
        form.reset(company);
        popupState.close();
    }

    const savePayer = (formData: any) => {
        formData.clientId = clientId;
        const data = formToData(formData, PayerSchema);

        createAction(
            '/Payer', data,
            `payer '${data.name}'`,
            () => history.push(`/clients/${clientId}/payers`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Payer">
                <div>
                    <span>* = Required Field</span>
                    <Tooltip title="Fill with Common Company Data">
                        <IconButton
                            aria-label="more"
                            aria-controls="action-menu"
                            aria-haspopup="true"
                            {...bindTrigger(popupState)}
                        >
                            <ImportContactsIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        {...bindMenu(popupState)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        {companies && companies.map((company: any) => (
                            <MenuItem key={company.id} onClick={() => handleCopyCompany(company)}>{company.name}</MenuItem>
                        ))}
                    </Menu>
                </div>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(savePayer)} noValidate autoComplete="off">
                    <ClientPayerForm />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/payers`}
                        submitLabel="Save Payer"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ClientPayerCreate
