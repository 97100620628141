import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import { selectTheme } from 'components/Styles';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Select, SelectRef } from 'react-functional-select';
import { useFetch } from 'use-http';
import URI from 'urijs';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    multiselect?: boolean;
    disabled?: boolean;
    action?: string;
    subject?: string;
    field?: string;
}

const DiagnosisSelect = ({
    label = "Diagnosis",
    name = "diagnosisId",
    required = true,
    multiselect = false,
    disabled = false,
    action,
    subject,
    field
}: Props) => {

    const selectRef = useRef<SelectRef | null>(null);
    const firstLoad = useRef(true);

    const { errors, register, setValue } = useFormContext();
    const hasError = Boolean(errors[name]);

    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const ability = useContext(AbilityContext);
    const { get } = useFetch();

    const formValue = useWatch<any>({ name: name });


    const denied = disabled || Boolean(action && subject && ability.cannot(action, subject, field ?? name));


    const handleChange = useCallback((selectedOption: any) => {
        // bug fix, disabled still allows item to be removed
        if (denied) return;

        let selected = null;

        // set form value
        if (selectedOption) {
            selected = multiselect
                ? selectedOption.map((o: any) => o.id)
                : selectedOption.id;
        }

        // prevent double load
        firstLoad.current = false;

        setValue(name, selected, {
            shouldValidate: true,
            shouldDirty: true
        });
    }, [name, multiselect, denied, setValue]);

    const handleInputChange = useCallback((): void => setLoading(true), []);

    const handleInputBlur = useCallback(() => setOptions([]), []);

    const handleSearchChange = useCallback((value?: string): void => {
        if (!value || value.length < 3) {
            setLoading(false);
            setOptions([]);
            return;
        }

        const url = `/MedicalCode/Search?query=${encodeURI(value)}`

        get(url)
            .then((data: any) => setOptions(data))
            .then(() => setLoading(false));

    }, [get]);


    // set current value when form data is loaded
    useEffect(() => {
        // prevent double loading
        if (!firstLoad.current) {
            return;
        }

        if (!formValue) {
            return;
        }

        firstLoad.current = false;

        if (multiselect) {
            if (!Array.isArray(formValue) || formValue.length === 0) return;

            const uri = URI("/MedicalCode/Load")
                .setSearch({
                    ids: formValue
                });

            setLoading(true);
            get(uri.toString())
                .then((data: any) => selectRef.current && selectRef.current.setValue(data))
                .finally(() => setLoading(false));
        }
        else {
            const url = `/MedicalCode/${encodeURI(formValue)}`

            setLoading(true);
            get(url)
                .then((data: any) => selectRef.current && selectRef.current.setValue(data))
                .finally(() => setLoading(false));
        }
    }, [formValue, multiselect, get])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <Select
                async={true}
                ref={selectRef}
                isDisabled={denied}
                isClearable={!denied}
                isMulti={multiselect}
                isLoading={loading}
                options={options}
                themeConfig={selectTheme}
                onInputChange={handleInputChange}
                onInputBlur={handleInputBlur}
                onSearchChange={handleSearchChange}
                onOptionChange={handleChange}
                inputDelay={800}
                placeholder="Select Diagnosis..."
                noOptionsMsg="Enter search phrase for more options"
                getOptionLabel={(option: any) => `${option.code} - ${option.description}`}
                getOptionValue={(option: any) => option.id}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default DiagnosisSelect
