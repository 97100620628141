import { useCallback } from 'react';
import useNotification from '../Notification';
import { useConfirmation } from '../Confirmation/ConfirmationProvider';
import useFetch from 'use-http';
import { validateResponse } from '../Gateway';

export const useFormActions = () => {
    const confirmation = useConfirmation();
    const { showError, showSuccess } = useNotification();
    const [request, response] = useFetch();


    const transform = useCallback((data: any): any => {
        if (Array.isArray(data)) return data;

        const transformed: any = {};

        Object.keys(data).forEach((key) => {
            // fix empty strings
            if (data[key] === '') {
                transformed[key] = null;
            } else {
                transformed[key] = data[key];
            }
        });

        return transformed;
    }, []);

    const loadAction = useCallback((url: string, title: string, callback: (data: any) => void) => {
        request.get(url)
            .then(data => validateResponse(data, response))
            .then(data => callback(data))
            .catch(error => showError(`Error loading ${title}`, error));
    }, [request, response, showError]);

    const loadData = useCallback((url: string, title: string): Promise<any> => {
        return request.get(url)
            .then(data => validateResponse(data, response))
            .catch(error => {
                showError(`Error loading ${title}`, error);
                return null;
            });
    }, [request, response, showError]);

    const createAction = useCallback((url: string, data: any, title: string, callback: (data: any) => void) => {
        const postData = transform(data);

        request.post(url, postData)
            .then(data => validateResponse(data, response))
            .then(data => {
                showSuccess(`Successfully saved ${title}`);
                callback(data);
            })
            .catch(error => showError(`Error saving ${title}`, error));
    }, [request, response, showSuccess, showError, transform]);

    const createData = useCallback((url: string, data: any, title: string): Promise<any> => {
        const postData = transform(data);

        return request.post(url, postData)
            .then(data => validateResponse(data, response))
            .then(data => {
                showSuccess(`Successfully saved ${title}`);
                return data;
            })
            .catch(error => {
                showError(`Error saving ${title}`, error);
                return null;
            });

    }, [request, response, showSuccess, showError, transform]);

    const updateAction = useCallback((url: string, data: any, title: string, callback: (data: any) => void) => {
        const postData = transform(data);

        request.put(url, postData)
            .then(data => validateResponse(data, response))
            .then(data => {
                showSuccess(`Successfully saved ${title}`);
                callback(data);
            })
            .catch(error => showError(`Error saving ${title}`, error));
    }, [request, response, showSuccess, showError, transform]);

    const updateData = useCallback((url: string, data: any, title: string): Promise<any> => {
        const postData = transform(data);

        return request.put(url, postData)
            .then(data => validateResponse(data, response))
            .then(data => {
                showSuccess(`Successfully saved ${title}`);
                return data;
            })
            .catch(error => {
                showError(`Error saving ${title}`, error);
                return null;
            });

    }, [request, response, showSuccess, showError, transform]);


    const deleteAction = useCallback(async (url: string, title: string, callback: () => void, quiet: boolean = false) => {
        if (!quiet) {
            const confirmed = await confirmation({
                title: "Confirm Delete",
                description: `Are you sure you want to ${title}?`,
                variant: "danger"
            });
            if (!confirmed) {
                return;
            }
        }

        request.delete(url)
            .then(data => validateResponse(data, response))
            .then(() => {
                if (!quiet) showSuccess(`Successfully deleted ${title}`);
                callback();
            })
            .catch(error => showError(`Error deleting ${title}`, error));
    }, [request, response, showSuccess, showError, confirmation]);

    const deleteData = useCallback(async (url: string, title: string): Promise<any> => {
        const confirmed = await confirmation({
            title: "Confirm Delete",
            description: `Are you sure you want to ${title}?`,
            variant: "danger"
        });

        if (!confirmed) {
            return;
        }

        const data: any = await request.delete(url)
            .then(data => validateResponse(data, response))
            .then(() => {
                showSuccess(`Successfully deleted ${title}`);
                return data;
            })
            .catch(error => {
                showError(`Error deleting ${title}`, error);
                return null;
            });

        return data;

    }, [request, response, showSuccess, showError, confirmation]);

    return {
        loadData,
        loadAction,
        createData,
        createAction,
        postData: createData,
        postAction: createAction,
        updateData,
        updateAction,
        deleteData,
        deleteAction
    }
}