import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ModifierList from './ModifierList';
import ModifierCreate from './ModifierCreate';
import ModifierEdit from './ModifierEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ModifierRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Modifier">
                <ModifierCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:modifierId`} action="create" subject="Modifier">
                <ModifierEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="create" subject="Modifier">
                <ModifierList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ModifierRoute
