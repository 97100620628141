import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
} from '@material-ui/core';
import { Can, AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientPayerList = ({ clientId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "Payer")

    const loadPayers = useCallback(() => {
        loadAction(
            `/client/${clientId}/payer`,
            'client payers',
            setData
        );
    }, [loadAction, clientId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/payer/${data.id}`,
            `payer '${data.name}'`,
            loadPayers
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${clientId}/payers/${data.id}`)
    };

    useEffect(() => {
        loadPayers();
    }, [loadPayers]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Client Payers">
                <Can do="create" on="Payer">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/clients/${clientId}/payers/create`)}
                    >
                        Add Payer
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Payers"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="Payers"
                columns={[
                    { field: 'id', title: 'Id', hidden: true, export: true },

                    { field: 'subscriberId', title: 'Subscriber', width: 130 },
                    { field: 'payerId', title: 'Payer Id', width: 100, hidden: true, export: true },
                    { field: 'groupId', title: 'Group Id', width: 100, hidden: true, export: true },

                    { field: 'name', title: 'Name' },
                    { field: 'fullAddress', title: 'Address' },

                    { field: 'addressLine1', title: 'Address', hidden: true, export: true },
                    { field: 'addressLine2', title: 'Address Line 2', hidden: true, export: true },
                    { field: 'city', title: 'City', hidden: true, export: true },
                    { field: 'stateProvince', title: 'State', hidden: true, export: true },
                    { field: 'postalCode', title: 'Zip', hidden: true, export: true },

                    { field: 'payerTypeName', title: 'Type', width: 120 },

                    { field: 'emailAddress', title: 'Email', hidden: true, export: true },
                    { field: 'businessPhone', title: 'Business Phone', width: 170 },
                    { field: 'homePhone', title: 'Home Phone', hidden: true, export: true },
                    { field: 'mobilePhone', title: 'Mobile Phone', width: 170 },
                    { field: 'faxNumber', title: 'Fax Number', hidden: true, export: true },

                    { field: 'clientId', title: 'Client Id', hidden: true, export: true },
                    { field: 'clientFamilyName', title: 'Client Last', hidden: true, export: true },
                    { field: 'clientGivenName', title: 'Client First', hidden: true, export: true },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                ]}
                data={data}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default ClientPayerList
