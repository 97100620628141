import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ClientContactForm from './ClientContactForm';
import { ContactSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientContactEdit = ({ clientId }: Props) => {
    const { contactId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "Contact")

    const loadContact = useCallback(() => {
        loadAction(
            `/Contact/${contactId}`,
            'contact',
            (data) => {
                const formData = dataToForm(data, ContactSchema);
                reset(formData);
            }
        );
    }, [loadAction, contactId, reset]);

    const saveContact = (formData: any) => {
        if (!allowEdit) return;

        formData.clientId = clientId;
        const data = formToData(formData, ContactSchema);

        updateAction(
            `/Contact/${contactId}`, data,
            `contact '${data.familyName}, ${data.givenName}'`,
            () => {
                history.push(`/clients/${clientId}/contacts`);
            }
        );
    }

    useEffect(() => {
        loadContact();
    }, [loadContact]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Contact Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveContact)} noValidate autoComplete="off">
                        <ClientContactForm disabled={!allowEdit} />
                        <FormButtons
                            disabled={!allowEdit}
                            dirty={formState.isDirty}
                            cancelLink={`/clients/${clientId}/contacts`}
                            submitLabel="Save Contact"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientContactEdit
