import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientCaseForm from './ClientCaseForm';
import { CaseSchema } from 'models/Schemas';
import { v4 as uuidv4 } from 'uuid';


interface Props {
    clientId: string
}

const ClientCaseCreate = ({ clientId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm({
        defaultValues: {}
    });
    const { createAction } = useFormActions();

    const saveCase = (formData: any) => {

        delete formData.id;
        formData.clientId = clientId;

        const data = formToData(formData, CaseSchema);

        createAction(
            '/Case', data,
            `case`,
            () => history.push(`/clients/${clientId}/cases`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Case">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveCase)} noValidate autoComplete="off">
                    <ClientCaseForm action="create" />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/cases`}
                        submitLabel="Save Case"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ClientCaseCreate
