import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import CompanyForm from './CompanyForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';

const CompanyEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { companyId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadCompany = useCallback(() => {
        loadAction(
            `/Company/${companyId}`,
            'company',
            data => {
                reset(data);
            }
        );
    }, [loadAction, companyId, reset]);

    const saveCompany = (data: any) => {
        updateAction(
            `/Company/${companyId}`, data,
            `company '${data.code}'`,
            () => {
                history.push('/administration/companies');
            }
        );
    }

    useEffect(() => {
        loadCompany();
    }, [loadCompany]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/companies">Companies</LinkRouter>
                <Typography color="textPrimary">Edit Company</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update Company">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Company Information"
                    icon={faBuilding}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveCompany)} noValidate>
                            <CompanyForm />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/companies"
                                submitLabel="Save Company"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default CompanyEdit
