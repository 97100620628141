import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import LanguageForm from './LanguageForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import { LanguageSchema } from 'models/Schemas';

const LanguageCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveLanguage = (formData: any) => {
        const data = formToData(formData, LanguageSchema);

        actions.createAction(
            '/Language', data,
            `language '${data.code}'`,
            () => history.push('/administration/languages')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/languages">Languages</LinkRouter>
                <Typography color="textPrimary">Add Language</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add Language">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Language Information"
                    icon={faGlobe}
                    color={theme.palette.text.primary}
                />
                <FormProvider  {...form}>
                    <form onSubmit={form.handleSubmit(saveLanguage)} noValidate>
                        <LanguageForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/administration/languages"
                            submitLabel="Save Language"
                        />
                    </form>
                </FormProvider >
            </Paper>
        </Container>
    )
}

export default LanguageCreate
