import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid, InputAdornment, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import RemoteSelect from 'components/Form/RemoteSelect';
import Dropdown from 'components/Form/Dropdown';
import React, { useState, useEffect, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { AbilityContext } from 'components/AuthorizationProvider';
import SecureTextField from 'components/Form/SecureTextField';

interface Props {
    disabled?: boolean
    action?: string;
}

const ClientForm = ({
    disabled = false,
    action = 'update'
}: Props) => {
    const form = useFormContext();
    const theme = useTheme();
    const { errors, register } = form;
    const [showSSN, setShowSSN] = useState<Boolean>(false);
    const ability = useContext(AbilityContext);

    const subject = 'Client';

    const handleClickShowSSN = () => {
        setShowSSN(!showSSN);
    };

    const handleMouseDownSSN = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const allowSSN = ability.can(action, subject, "socialSecurityNumber");
    const allowBirthDate = ability.can(action, subject, "birthDate");

    useEffect(() => {

        const handler = setTimeout(() => {
            setShowSSN(false);
        }, 8000);

        return () => {
            clearTimeout(handler);
        };

    }, [showSSN])

    return (
        <React.Fragment>

            <SectionTitle
                title="Details"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="givenName"
                        label="First Name"
                        required
                        fullWidth
                        error={Boolean(errors.givenName)}
                        helperText={errors.givenName && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="middleName"
                        label="Middle Name"
                        fullWidth
                        error={Boolean(errors.middleName)}
                        helperText={errors.middleName && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="familyName"
                        label="Last Name"
                        required
                        fullWidth
                        error={Boolean(errors.familyName)}
                        helperText={errors.familyName && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Dropdown
                        action={action}
                        subject={subject}
                        name="gender"
                        label="Gender"
                        required={true}
                        items={[
                            { name: 'Male', value: 'M' },
                            { name: 'Female', value: 'F' },
                            { name: 'Other', value: 'O' }
                        ]}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="addressLine1"
                        label="Address"
                        required
                        fullWidth
                        error={Boolean(errors.addressLine1)}
                        helperText={errors.addressLine1 && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="addressLine2"
                        label="Address Line 2"
                        fullWidth
                        error={Boolean(errors.addressLine2)}
                        helperText={errors.addressLine2}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="city"
                        label="City"
                        required
                        fullWidth
                        error={Boolean(errors.city)}
                        helperText={errors.city && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <RemoteSelect
                        action={action}
                        subject={subject}
                        name="stateProvince"
                        label="State"
                        required={true}
                        url="/StateProvince?sort=DisplayOrder"
                        optionRender={(state: any) =>
                            <option key={state.abbreviation} value={state.abbreviation}>{state.description}</option>
                        }
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="postalCode"
                        label="Postal Code"
                        required
                        fullWidth
                        error={Boolean(errors.postalCode)}
                        helperText={errors.postalCode && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <RemoteSelect
                        action={action}
                        subject={subject}
                        name="countyId"
                        label="County"
                        required={false}
                        url="/County"
                        optionRender={(item: any) =>
                            <option key={item.id} value={item.id}>{item.name}</option>
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="emailAddress"
                        label="Email Address"
                        type="email"
                        fullWidth
                        error={Boolean(errors.emailAddress)}
                        helperText={errors.emailAddress && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="homePhone"
                        label="Home Phone"
                        type="tel"
                        fullWidth
                        error={Boolean(errors.homePhone)}
                        helperText={errors.homePhone && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="mobilePhone"
                        label="Mobile Phone"
                        type="tel"
                        fullWidth
                        error={Boolean(errors.mobilePhone)}
                        helperText={errors.mobilePhone && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="socialSecurityNumber"
                        label="Social Security Number"
                        required={allowSSN}
                        fullWidth
                        type={showSSN ? 'text' : 'password'}
                        error={Boolean(form.errors.socialSecurityNumber)}
                        helperText={form.errors.socialSecurityNumber && form.errors.socialSecurityNumber.message}
                        inputRef={form.register(
                            allowSSN
                                ? {
                                    required: 'This field is required',
                                    maxLength: {
                                        value: 9,
                                        message: 'Invalid Social Security Number.  Must be 9 numbers'
                                    },
                                    minLength: {
                                        value: 9,
                                        message: 'Invalid Social Security Number.  Must be 9 numbers'
                                    }
                                }
                                : {}
                        )}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowSSN}
                                        onMouseDown={handleMouseDownSSN}
                                    >
                                        {showSSN ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="birthDate"
                        label="Birth Date"
                        type="date"
                        required={allowBirthDate}
                        fullWidth
                        error={Boolean(form.errors.birthDate)}
                        helperText={form.errors.birthDate && 'This field is required'}
                        inputRef={form.register({ required: allowBirthDate })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <RemoteSelect
                        action={action}
                        subject={subject}
                        name="language"
                        label="Language"
                        required={false}
                        url="/Language?sort=DisplayOrder"
                        optionRender={(language: any) =>
                            <option key={language.id} value={language.name}>{language.description}</option>
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <Dropdown
                        action={action}
                        subject={subject}
                        name="isInterpreterRequired"
                        label="Interpreter"
                        required={false}
                        defaultValue="false"
                        items={[
                            { name: 'Yes', value: 'true' },
                            { name: 'No', value: 'false' }
                        ]}
                    />
                </Grid>

                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="accountingId"
                        label="Accounting Number"
                        fullWidth
                        error={Boolean(errors.accountingId)}
                        helperText={errors.accountingId && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="primaryGeneralLedger"
                        label="Primary General Ledger Account"
                        fullWidth
                        error={Boolean(errors.primaryGeneralLedger)}
                        helperText={errors.primaryGeneralLedger && errors.primaryGeneralLedger.message}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="customerType"
                        label="Customer Type"
                        fullWidth
                        error={Boolean(errors.customerType)}
                        helperText={errors.customerType && errors.customerType.message}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="note"
                        label="Notes"
                        fullWidth
                        multiline
                        minRows="10"
                        error={Boolean(errors.note)}
                        helperText={errors.note && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientForm
