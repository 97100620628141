import React, { Fragment } from 'react'
import {
    Typography,
    Select,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
    FormControl,
    FormHelperText
} from '@material-ui/core';
import { FieldMap, ImportDefinition } from 'models/ImportDefinition'
import { useStyles } from 'components/Styles';
import { useFormContext } from 'react-hook-form';

interface Props {
    importDefinition: ImportDefinition,
    fileFields: FieldMap[] | null
}

export const matchFields = (importDefinition: ImportDefinition, fileFields: FieldMap[]) => {
    const formData: any = {};

    // match headers using expressions
    importDefinition.fields.forEach(definition => {
        if (!definition.expressions) return;

        definition.expressions.some(expression =>
            fileFields.some(field => {
                const regex = new RegExp(expression, 'g');
                const isMatch = regex.test(field.name);

                if (isMatch) {
                    formData[definition.name] = field.index;
                }

                return isMatch;
            })
        )
    });

    return formData;
}

const UploadMapping = ({ importDefinition, fileFields }: Props) => {
    const classes = useStyles();
    const { errors, register } = useFormContext();

    const mapFields = importDefinition?.fields?.filter(f => f.canMap === true)

    return (
        <Fragment>
            {!fileFields &&
                <Typography
                    color="textSecondary"
                    paragraph
                    style={{ padding: 3, textAlign: 'center' }}
                >
                    Click the Upload button to select a comma delimited (csv) file for importing
                </Typography>
            }

            {fileFields &&
                <Fragment>
                    <Typography
                        color="textSecondary"
                        paragraph
                        style={{ padding: 3, textAlign: 'center' }}
                    >
                        Select the uploaded field that maps to the target field
                    </Typography>

                    <TableContainer className={classes.mappingTable}>
                        <Table className={classes.mappingTable} aria-label="mapping table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cellHeader}>
                                        Target Field
                                    </TableCell>
                                    <TableCell className={classes.cellHeader}>
                                        Upload Field
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mapFields.map((definition) => (
                                    <TableRow key={definition.name}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={{textAlign: 'right', fontWeight: 600}}
                                        >
                                            {definition.displayName}:
                                        </TableCell>
                                        <TableCell>
                                            <FormControl
                                                fullWidth
                                                required={definition.isRequired}
                                                error={Boolean(errors[definition.name])}
                                            >
                                                <Select
                                                    fullWidth
                                                    native
                                                    name={definition.name}
                                                    inputRef={register({ required: definition.isRequired })}
                                                    error={Boolean(errors[definition.name])}
                                                >
                                                    <option aria-label="None" value="" />
                                                    {fileFields.map((field: FieldMap) => (
                                                        <option
                                                            key={field.index}
                                                            value={field.index}
                                                        >
                                                            {field.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                                <FormHelperText>
                                                    {Boolean(errors[definition.name]) && "This field is required"}
                                                </FormHelperText>
                                            </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Fragment>
            }
        </Fragment>
    )
}

export default UploadMapping
