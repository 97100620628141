import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TimeLineBadge from './TimelineBadge';
import TimeLineBody from './TimelineBody';
import TimelineDate from './TimelineDate';
import TimelineValue from './TimelineValue';

interface Props {
    item: any;
    showIcon?: boolean
}

const TimelineItemRemoved = ({ item, showIcon = true }: Props) => {
    return (
        <React.Fragment>
            {showIcon &&
                <TimeLineBadge>
                    <FontAwesomeIcon icon={faTrashAlt} />
                </TimeLineBadge>
            }
            <TimeLineBody>
                <span className="timeline-user">{item.activityBy}</span>
                <span className="timeline-text"> removed </span>
                <span className="timeline-entity"> {item.displayName} </span>
                <TimelineValue value={item.description} />
                <span className="timeline-text"> on </span>
                <TimelineDate value={item.activityDate} />
            </TimeLineBody>
        </React.Fragment>
    )
}

export default TimelineItemRemoved
