import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import URI from 'urijs';
import { useHistory } from 'react-router-dom';
import ManagerPicker from 'components/Pickers/ManagerPicker';

const ClientFacility = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const [caseManagers, setCaseManagers] = useState<any[]>([]);

    const firstLoad = useRef(true);

    const loadData = useCallback((caseManagerIds: number[]) => {
        const url = URI("/Report/ClientFacility")
            .setSearch({
                caseManagerIds
            });

        loadAction(
            url.toString(),
            'Client Facility',
            setData
        );
    }, [loadAction])

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${data.ClientId}/facility/${data.FacilityId}`)
    };

    useEffect(() => {
        // prevent double loading of list
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
        
        const ids = caseManagers ? caseManagers.map(m => m.id) : [];

        loadData(ids);
    }, [loadData, caseManagers]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Client Facility</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Clients in a Facility">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Clients in a Facility Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Client Facility ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientId', title: 'Client Id', type: 'numeric', hidden: true },
                        { field: 'CaseId', title: 'Case Id', type: 'numeric', hidden: true },
                        { field: 'CaseManager', title: 'Case Manager' },
                        { field: 'ClientName', title: 'Client Name' },
                        { field: 'TransferDate', title: 'Transfer Date', width: 200, type: 'date' },
                        { field: 'WaiverSpanEndDate', title: 'Waiver Span End', width: 190, type: 'date' },
                        { field: 'FacilityId', title: 'Case Id', width: 130, type: 'numeric', hidden: true },
                        { field: 'FacilityAdmitDate', title: 'Facility Admit', width: 200, type: 'date', defaultSort: 'asc' },
                        { field: 'FacilityDischargedDate', title: 'Facility Discharged', width: 200, type: 'date', defaultSort: 'asc' },
                        { field: 'DaysInFacility', title: 'Days in Facility', width: 190 },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit,
                            position: 'row'
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ClientFacility
