import { Breadcrumbs, Container, Typography, Tooltip, Box } from '@material-ui/core';
import { useFormActions } from 'components/Form/FormActions';
import LinkRouter from 'components/Navigation/LinkRouter';
import TabNavigationBar from 'components/Navigation/TabNavigationBar';
import React, { useCallback, useEffect, useState, Fragment, useContext } from 'react';
import { Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import EligibilityCreate from './Eligibility/EligibilityCreate';
import EligibilityEdit from './Eligibility/EligibilityEdit';
import EligibilityList from './Eligibility/EligibilityList';
import ClientCaseCreate from './Cases/ClientCaseCreate';
import ClientCaseEdit from './Cases/ClientCaseEdit';
import ClientCaseList from './Cases/ClientCaseList';
import ClientDetail from './ClientDetail';
import ClientHistory from './ClientHistory';
import ClientContactCreate from './Contacts/ClientContactCreate';
import ClientContactEdit from './Contacts/ClientContactEdit';
import ClientContactList from './Contacts/ClientContactList';
import ClientPayerCreate from './Payers/ClientPayerCreate';
import ClientPayerEdit from './Payers/ClientPayerEdit';
import ClientPayerList from './Payers/ClientPayerList';
import ClientAgreementCreate from './Agreements/ClientAgreementCreate';
import ClientAgreementEdit from './Agreements/ClientAgreementEdit';
import ClientAgreementList from './Agreements/ClientAgreementList';
import ClientAgreementReallocate from './Agreements/ClientAgreementReallocate';
import ClientNoteCreate from './Notes/ClientNoteCreate';
import ClientNoteEdit from './Notes/ClientNoteEdit';
import ClientNoteList from './Notes/ClientNoteList';
import FacilityStayCreate from './Facility/FacilityStayCreate';
import FacilityStayEdit from './Facility/FacilityStayEdit';
import FacilityStayList from './Facility/FacilityStayList';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';
import { AbilityContext } from 'components/AuthorizationProvider';

const ClientEdit = () => {
    const { clientId } = useParams();
    const match = useRouteMatch();
    const [client, setClient] = useState<any>({});
    const [payer, setPayer] = useState<any>({});
    const [manager, setManager] = useState<any>({});
    const { loadAction } = useFormActions();
    const ability = useContext(AbilityContext);
    const history = useHistory();

    const clientTabs = [
        {
            label: 'Details',
            location: `/clients/${clientId}`,
            matchPath: `${match.path}`
        },
        {
            label: 'Eligibility',
            location: `/clients/${clientId}/eligibility`,
            matchPath: `${match.path}/eligibility`
        },
        {
            label: 'Cases',
            location: `/clients/${clientId}/cases`,
            matchPath: `${match.path}/cases`
        },
        {
            label: 'Facility',
            location: `/clients/${clientId}/facility`,
            matchPath: `${match.path}/facility`
        },
        {
            label: 'Payers',
            location: `/clients/${clientId}/payers`,
            matchPath: `${match.path}/payers`
        },
        {
            label: 'Contacts',
            location: `/clients/${clientId}/contacts`,
            matchPath: `${match.path}/contacts`
        },
        {
            label: 'Agreements',
            location: `/clients/${clientId}/agreements`,
            matchPath: `${match.path}/agreements`
        },
        {
            label: 'Notes',
            location: `/clients/${clientId}/notes`,
            matchPath: `${match.path}/notes`
        },
        {
            label: 'History',
            location: `/clients/${clientId}/history`,
            matchPath: `${match.path}/history`,
            hide: ability.cannot("read", "ClientHistory")
        }
    ];

    const loadClient = useCallback(() => {
        loadAction(
            `/Client/${clientId}`,
            'client',
            (data) => setClient(data)
        );
    }, [loadAction, clientId]);

    const loadSubscriber = useCallback(() => {
        loadAction(
            `/Client/${clientId}/primary-subscriber`,
            'subscriber',
            (data) => setPayer(data)
        );
    }, [loadAction, clientId]);

    const loadManager = useCallback(() => {
        loadAction(
            `/client/${clientId}/manager`,
            'case manager',
            (data) => setManager(data)
        );
    }, [loadAction, clientId])

    useEffect(() => {
        loadClient();
        loadSubscriber();
    }, [loadClient, loadSubscriber]);

    useEffect(() => {
        loadManager();
    }, [loadManager]);

    return (
        <Container maxWidth={false}>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '-10px', marginBottom: '10px' }}
            >
                <Box p={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <LinkRouter to="/">Home</LinkRouter>
                        <LinkRouter to="/clients">Clients</LinkRouter>
                        <Typography color="textPrimary">
                            {client &&
                                <Tooltip interactive
                                    title={
                                        <Fragment>
                                            <Typography color="inherit">{client.fullName}</Typography>
                                            {client.addressLine1}, {client.city}, {client.stateProvince} {client.postalCode}<br />
                                            {client.emailAddress && <Fragment>Email: {client.emailAddress}<br /></Fragment>}
                                            {client.homePhone && <Fragment>Home: {client.homePhone}<br /></Fragment>}
                                            {client.mobilePhone && <Fragment>Mobile: {client.mobilePhone}<br /></Fragment>}
                                            {payer && payer.subscriberId && <Fragment>Subscriber: {payer.subscriberId}<br /></Fragment>}
                                        </Fragment>
                                    }>
                                    <span>{client.fullName}</span>
                                </Tooltip>
                            }
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box p={1}>
                    <span style={{ color: 'rgba(0, 0, 0, 0.38)', marginRight: 15 }}>Case Manager: </span>
                    {manager &&
                        <Tooltip interactive
                            title={
                                <Fragment>
                                    <Typography color="inherit">{manager.fullName}</Typography>
                                    {manager.businessPhone && <Fragment>Phone: {manager.businessPhone}<br /></Fragment>}
                                    {manager.mobilePhone && <Fragment>Mobile: {manager.mobilePhone}<br /></Fragment>}
                                    {manager.faxNumber && <Fragment>Fax: {manager.faxNumber}<br /></Fragment>}
                                </Fragment>
                            }>
                            <span
                                onClick={() => history.push(`/administration/managers/${manager.id}`)}
                                style={{ cursor: 'pointer' }}>
                                {manager.fullName}
                            </span>
                        </Tooltip>
                    }
                </Box>
            </Box>

            <TabNavigationBar tabs={clientTabs} />

            {clientId &&
                <Switch>
                    <ProtectedRoute path={`${match.path}/eligibility/create`} action="create" subject="ClientEligibility">
                        <EligibilityCreate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/eligibility/:eligibilityId`} action="read" subject="ClientEligibility">
                        <EligibilityEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/eligibility`} action="read" subject="ClientEligibility">
                        <EligibilityList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/contacts/create`} action="create" subject="Contact">
                        <ClientContactCreate client={client} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/contacts/:contactId`} action="read" subject="Contact">
                        <ClientContactEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/contacts`} action="read" subject="Contact">
                        <ClientContactList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/payers/create`} action="create" subject="Payer">
                        <ClientPayerCreate client={client} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/payers/:payerId`} action="read" subject="Payer">
                        <ClientPayerEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/payers`} action="read" subject="Payer">
                        <ClientPayerList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/cases/create`} action="create" subject="Case">
                        <ClientCaseCreate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/cases/:caseId`} action="read" subject="Case">
                        <ClientCaseEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/cases`} action="read" subject="Case">
                        <ClientCaseList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/facility/create`} action="create" subject="FacilityStay">
                        <FacilityStayCreate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/facility/:facilityStayId`} action="read" subject="FacilityStay">
                        <FacilityStayEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/facility`} action="read" subject="FacilityStay">
                        <FacilityStayList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/agreements/create`} action="create" subject="ServiceAgreement">
                        <ClientAgreementCreate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/agreements/:agreementId/reallocate`} action="update" subject="ServiceAgreement">
                        <ClientAgreementReallocate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/agreements/:agreementId`} action="read" subject="ServiceAgreement">
                        <ClientAgreementEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/agreements`} action="read" subject="ServiceAgreement">
                        <ClientAgreementList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/notes/create`} action="create" subject="ServiceNote">
                        <ClientNoteCreate clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/notes/:noteId`} action="read" subject="ServiceNote">
                        <ClientNoteEdit clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/notes`} action="read" subject="ServiceNote">
                        <ClientNoteList clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/history`} action="read" subject="ClientHistory">
                        <ClientHistory clientId={clientId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={match.path} action="read" subject="Client">
                        <ClientDetail clientId={clientId} />
                    </ProtectedRoute>
                </Switch>
            }
        </Container>
    )
}

export default ClientEdit
