import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    requiredValue?: { name: string, value: any, required: boolean};
}

const ActivitySelect = ({
    label = "Activity Type",
    name = "activityTypeId",
    required = true,
    disabled = false,
    requiredValue
}: Props) => {

    const { errors, register, setValue } = useFormContext();
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()
    const [filterOptions, setFilterOptions] = useState<any[]>([]);

    const formValue = useWatch<any>({name: name});

    // load data
    const { data: options } = useFetch('/ActivityType?sort=DisplayOrder', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        if (disabled) return;

        // set local selected value
        setSelected(selectedOption);

        // set form value
        if (selectedOption) {
            const selectedValues = selectedOption.map((o: any) => o.id);
            setValue(name, selectedValues, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
        else {
            setValue(name, null, {
                shouldValidate: true,
                shouldDirty: true
            });
        }
    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options) {
            return;
        }

        // find the selected options
        const current: any[] = [];
        const filtered = options.filter((f: any) => f.isActive === true)

        if (!formValue) {
            setSelected(current);
            setFilterOptions(filtered);
            return;
        }

        formValue.forEach((id: string) => {
            const selected = options.find((f: any) => f.id === Number(id));
            current.push(selected);

            if (selected.isActive === false) {
                filtered.push(selected);
            }
        });

        setSelected(current);
        setFilterOptions(filtered);

    }, [formValue, options])

    // register input in form hook
    useEffect(() => {
        register({
            name: name
        }, {
            required: {
                message: 'This field is required',
                value: required
            },
            validate: value => {
                if (!requiredValue || !requiredValue.required)
                    return true;

                if (value && Array.isArray(value)) {
                    if (value.includes(requiredValue.value))
                        return true;
                    else
                        return `Activity type ${requiredValue.name} is required`;
                }
                else if (value !== requiredValue.value) {
                    return `Activity type ${requiredValue.name} is required`;
                }

                return true;
            }
        });
    }, [register, name, required, requiredValue]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}
                styles={selectStyles}
                isClearable={true}
                isMulti={true}
                options={filterOptions}
                getOptionLabel={(option: any) => `${option.name}`}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && errors[name].message}
            </FormHelperText>
        </FormControl>
    )
}

export default ActivitySelect
