import {
    Breadcrumbs,
    Container,
    Paper,
    Typography,
    Grid,
    FormControlLabel,
    Switch
} from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ClientForm from './ClientForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import ClientServiceForm from './ClientServiceForm';
import { ClientSchema } from 'models/Schemas';
import { v4 as uuidv4 } from 'uuid';

const ClientCreate = () => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm({
        defaultValues: {}
    });
    const actions = useFormActions();
    const [createServiceAgreement, setCreateServiceAgreement] = useState<boolean>(false);

    const saveClient = (formData: any) => {
        const data = formToData(formData, ClientSchema);

        const url = createServiceAgreement ? '/client/createWithServices' : '/client';

        actions.createAction(
            url, data,
            `client '${data.familyName}, ${data.givenName}'`,
            (data: any) => history.push(`/clients/${data.id}`)
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/clients">Clients</LinkRouter>
                <Typography color="textPrimary">Add Client</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add New Client">
                    <p>* = Required Field</p>
                </FormHeader>

                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(saveClient)} noValidate autoComplete="off">
                        <ClientForm action="create" />

                        <Grid
                            style={{
                                marginTop: "5px",
                                marginBottom: "5px"
                            }}
                            container
                            direction="row"
                            spacing={3}
                        >
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            name="checkedB"
                                            color="primary"
                                            checked={createServiceAgreement}
                                            onChange={(event) => setCreateServiceAgreement(event.target.checked)}
                                        />
                                    }
                                    label="Create Initial Service Agreement"
                                />
                            </Grid>
                        </Grid>

                        {createServiceAgreement &&
                            <ClientServiceForm />
                        }

                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/clients"
                            submitLabel="Save Client"
                        />
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ClientCreate
