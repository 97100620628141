import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { useFormActions } from 'components/Form/FormActions';
import LinkRouter from 'components/Navigation/LinkRouter';
import TabNavigationBar from 'components/Navigation/TabNavigationBar';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useParams,
    useRouteMatch
    } from 'react-router-dom';
import ProgramDetail from './ProgramDetail';
import ProgramHistory from './ProgramHistory';

const ProgramEdit = () => {
    const { programId } = useParams();
    const match = useRouteMatch();
    const [program, setProgram] = useState<any>({});
    const { loadAction } = useFormActions();

    const tabs = [
        {
            label: 'Details',
            location: `/programs/${programId}`,
            matchPath: `${match.path}`
        },
        {
            label: 'History',
            location: `/programs/${programId}/history`,
            matchPath: `${match.path}/history`
        }
    ];

    const loadProgram = useCallback(() => {
        loadAction(
            `/program/${programId}`,
            'program',
            (data) => setProgram(data)
        );
    }, [loadAction, programId]);

    useEffect(() => {
        loadProgram();
    }, [loadProgram]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/programs">Programs</LinkRouter>
                <Typography color="textPrimary">
                    {program && <span>{program.code}</span>}
                </Typography>
            </Breadcrumbs>

            <TabNavigationBar tabs={tabs} />

            {programId &&
                <Switch>
                    <Route path={`${match.path}/history`}>
                        <ProgramHistory programId={programId} />
                    </Route>
                    <Route path={match.path}>
                        <ProgramDetail programId={programId} />
                    </Route>
                </Switch>
            }
        </Container>
    )
}

export default ProgramEdit
