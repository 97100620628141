import React from 'react';
import './Timeline.css';

interface Props {
    children: React.ReactNode;
}

const Timeline = (props: Props) => {
    return (
        <div className="timeline-container">
            {props.children}
        </div>
    )
}

export default Timeline
