import React from 'react'

interface Props {
    value: string;
    truncate?: number;
    defaultValue?: string;
}

const TimelineValue = ({ value, truncate = 100, defaultValue = '-empty-' }: Props) => {

    const truncateString = (text: string, size: number) => {
        if (text.length <= size) {
            return text
        }
        return text.slice(0, size) + '...'
    }

    return (
        <span className="timeline-value"> {value ? truncateString(value, truncate) : defaultValue} </span>
    )
}

export default TimelineValue
