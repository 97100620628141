import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import { Paper, Toolbar, Grid, FormControl, InputLabel, Select, TextField, InputAdornment, Tooltip, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import Timeline from 'components/Timeline/Timeline';
import TimelineItem from 'components/Timeline/TimelineItem';
import React, { useCallback, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { useDebounce } from 'components/Debounce';
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons';
import { DateTime } from 'luxon';
import URI from 'urijs';

interface Props {
    serviceId: string
}

const ServiceHistory = ({ serviceId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const progress = useProgress();

    const [change, setChange] = useState<string>("");
    const [entity, setEntity] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [filtered, setFiltered] = useState<any[] | undefined>();
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    const delayedSearch = useDebounce(search, 500);

    const loadHistory = useCallback((startDate: string, endDate: string) => {
        const url = URI('/AuditHistory/Report')
            .setSearch({
                startDate,
                endDate,
                serviceId
            });

        loadAction(
            url.toString(),
            'client history',
            data => {
                var mapped = data.map((item: any) => {
                    const changes = item.changes ? JSON.parse(item.changes) : null;
                    return { ...item, changes };
                });
                setData(mapped);
            }
        );
    }, [loadAction, serviceId])


    const searchChange = (instance: any, search: string): boolean => {
        if (instance.key && String(instance.key) === search)
            return true;

        const regEx = new RegExp(search, "i");
        if (instance.displayName && instance.displayName.match(regEx))
            return true;

        if (instance.description && instance.description.match(regEx))
            return true;

        if (instance.activityBy && instance.activityBy.match(regEx))
            return true;

        if (instance.displayName && instance.displayName.match(regEx))
            return true;

        if (!instance.changes)
            return false;

        for (const change of instance.changes) {
            if (change.propertyName && change.propertyName.match(regEx))
                return true;

            if (change.displayName && change.displayName.match(regEx))
                return true;

            if (change.originalFormatted && change.originalFormatted.match(regEx))
                return true;

            if (change.currentFormatted && change.currentFormatted.match(regEx))
                return true;
        }

        return false;
    }

    useEffect(() => {
        const changeFilter = change
            ? (row: any) => row.operation === change
            : () => true;

        const entityFilter = entity
            ? (row: any) => row.entity === entity
            : () => true;

        const searchFilter = delayedSearch
            ? (row: any) => searchChange(row, delayedSearch)
            : () => true;

        const results = data?.filter(row => changeFilter(row) && entityFilter(row) && searchFilter(row));
        setFiltered(results);

    }, [data, change, entity, delayedSearch])

    useEffect(() => {
        if (!DateTime.fromISO(delayedStartDate).isValid || !DateTime.fromISO(delayedEndDate).isValid) {
            setTimeout(() => setData([]), 0);
            return;
        }

        loadHistory(delayedStartDate, delayedEndDate);
    }, [loadHistory, delayedStartDate, delayedEndDate]);
    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Service History">
                <div></div>
            </SectionHeader>
            <SectionTitle
                title="Changes"
                icon={faFileMedicalAlt}
                color={theme.palette.text.primary}
            />
            <Toolbar>
                <Grid
                    style={{
                        marginTop: "5px",
                        marginBottom: "5px"
                    }}
                    container
                    direction="row"
                    spacing={3}
                >
                    <Grid item xs={2}>
                        <TextField
                            name="startDate"
                            label="Start Date"
                            type='date'
                            fullWidth
                            value={startDate}
                            onChange={event => setStartDate(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="endDate"
                            label="End Date"
                            type='date'
                            fullWidth
                            value={endDate}
                            onChange={event => setEndDate(event.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <InputLabel shrink>
                                Change
                            </InputLabel>
                            <Select native
                                value={change}
                                onChange={event => setChange(event.target.value as string)}>
                                <option aria-label="None" value="">- All -</option>
                                <option value="Create">Create</option>
                                <option value="Update">Update</option>
                                <option value="Delete">Delete</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel shrink>
                                Entity
                            </InputLabel>
                            <Select native
                                value={entity}
                                onChange={event => setEntity(event.target.value as string)}>
                                <option aria-label="None" value="">- All -</option>
                                <option value="Service">Service</option>
                                <option value="ServiceRate">Service Rate</option>
                                <option value="ServiceModifier">Service Modifier</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="search"
                            label="Search"
                            fullWidth
                            value={search}
                            onChange={(event: any) => setSearch(event.target.value)}
                            disabled={!Boolean(data)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Tooltip title="Search">
                                            <SearchIcon color="inherit" fontSize="small" />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disabled={!search}
                                            onClick={() => setSearch("")}
                                        >
                                            <ClearIcon color="inherit" fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Toolbar>
            <BlockUi tag="div" blocking={progress.loading}>
                <Timeline>
                    {filtered && filtered.map((item, index) => (
                        <TimelineItem key={index} item={item} />
                    ))}
                </Timeline>
            </BlockUi>
        </Paper>
    )
}

export default ServiceHistory
