import { Paper, Tooltip, IconButton, Menu, MenuItem } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ClientPayerForm from './ClientPayerForm';
import { PayerSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';
import { bindTrigger, bindMenu } from 'material-ui-popup-state/core';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useFetch, CachePolicies } from 'use-http';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

interface Props {
    clientId: string
}

const ClientPayerEdit = ({ clientId }: Props) => {
    const { payerId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();
    const popupState = usePopupState({ variant: 'popover', popupId: 'companyMenu' })

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "Payer")

    // load data
    const { data: companies } = useFetch('/Company?sort=name', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    const loadPayer = useCallback(() => {
        loadAction(
            `/Payer/${payerId}`,
            'payer',
            (data) => {
                const formData = dataToForm(data, PayerSchema);
                reset(formData);
            }
        );
    }, [loadAction, payerId, reset]);

    const savePayer = (formData: any) => {
        if (!allowEdit) return;

        formData.clientId = clientId;
        const data = formToData(formData, PayerSchema);

        updateAction(
            `/Payer/${payerId}`, data,
            `payer '${data.name}'`,
            () => {
                history.push(`/clients/${clientId}/payers`);
            }
        );
    };

    const handleCopyCompany = (company: any) => {
        form.reset(company);
        popupState.close();
    };

    useEffect(() => {
        loadPayer();
    }, [loadPayer]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Payer Information">
                <div>
                    <span>* = Required Field</span>
                    <Tooltip title="Fill with Common Company Data">
                        <IconButton
                            aria-label="more"
                            aria-controls="action-menu"
                            aria-haspopup="true"
                            {...bindTrigger(popupState)}
                        >
                            <ImportContactsIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        {...bindMenu(popupState)}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        {companies && companies.map((company: any) => (
                            <MenuItem key={company.id} onClick={() => handleCopyCompany(company)}>{company.name}</MenuItem>
                        ))}
                    </Menu>
                </div>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(savePayer)} noValidate autoComplete="off">
                        <ClientPayerForm disabled={!allowEdit} />
                        <FormButtons
                            dirty={formState.isDirty}
                            disabled={!allowEdit}
                            cancelLink={`/clients/${clientId}/payers`}
                            submitLabel="Save Payer"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientPayerEdit
