import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientNoteForm from './ClientNoteForm';
import { NoteSchema } from 'models/Schemas';
import { useAuthorization } from 'components/AuthorizationProvider';
import { nextBillingLockDate } from 'services/BillingCycle';
import { v4 as uuidv4 } from 'uuid';
import { deleteStorageValue, useLocalStorage } from 'utils/useLocalStorage';


interface Props {
    clientId: string
}
let showPrompt = false;

const ClientNoteCreate = ({ clientId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const userInfo = useAuthorization();
    const location = useLocation();

    const form = useForm({
        defaultValues: {
            caseManagerId: userInfo.caseManagerId,
            billingLockDate: nextBillingLockDate().toISODate()
        }
    });
    const { createAction } = useFormActions();

    const [unsavedNote, setUnsavedNote] = useLocalStorage("note", "");

    const noteText = (text: string) => {
        showPrompt = text !== "";
        setUnsavedNote(text);
    }

    const saveServiceNote = (formData: any) => {
        formData.caseManagerId = userInfo.caseManagerId;
        formData.isBillingDelayed = false;
        showPrompt = false;
        const data = formToData(formData, NoteSchema);
        deleteStorageValue("note");
        createAction(
            '/ServiceNote', data,
            'service note',
            () => history.push(`/clients/${clientId}/notes`)
        );
    }

    useEffect(() => {
        const unListen = history.listen(async () => {
            if (history.location.pathname !== location.pathname) {
                deleteStorageValue(`note`);
            }

        });
        return unListen;
    }, [history, location]);


    return (
        <Paper className={classes.paper}>
            <Prompt
                message={(location, action) => {
                    return 'Are you sure you want to leave this page without saving?';
                }}
                when={showPrompt}
            />
            <FormHeader title="Add New Note">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveServiceNote)} noValidate autoComplete="off">
                    <ClientNoteForm
                        clientId={clientId}
                        action="create"
                        allowNote={true}
                        noteText={noteText}
                        unsavedNote={unsavedNote}
                    />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/notes`}
                        submitLabel="Save Note"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ClientNoteCreate
