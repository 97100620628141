import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import SignaturePad from 'react-signature-canvas'
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TimeEntryGrid from './TimeEntry/TimeEntryGrid';
import { TimeEntryType, TimeSheetType } from 'models/TimeSheetModel';
import { formatDate, getOtherDate, getPrevDateDay, isValidPayPeriodStart } from 'utils/dateUtils';
import styles from '../../styles/timeSheetStyles.module.css'
import ReactSignatureCanvas from 'react-signature-canvas';
import { useHistory } from 'react-router-dom';
import { TimeSheetListType } from './TimeSheetList';

interface Props {
    disabled?: boolean;
    Name?: string;
    TimeSheetID?: string;
    TimeSheet?: TimeSheetType;
    TimeEntries?: TimeEntryType[];
    isTimeSheetCreate?: boolean;
    isSupe?: boolean;
    TimeSheetList?: TimeSheetListType[];
    isAdmin?: boolean;
}

const TimeSheetForm = ({ disabled = false, Name = "", TimeSheet = undefined, TimeEntries = undefined, isTimeSheetCreate = false, isSupe = false, TimeSheetList = undefined, isAdmin = false }: Props) => {
    const { errors, register, setError, clearErrors } = useFormContext();
    const [date, setDate] = useState<{ fromDate: Date, toDate: Date }>();
    const [openPrevTimeSheet, setOpenPrevTimeSheet] = useState<{ open: boolean, tsId: string }>({ open: false, tsId: '' })

    const [timeSheetComment, setTimeSheetComment] = useState('');

    const handleClose = () => {
        setOpenPrevTimeSheet({ ...openPrevTimeSheet, open: false });
    }

    const history = useHistory();

    // handles when user changes input in start date
    const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | string>, dateField: 'toDate' | 'fromDate') => {
        let toDateVal = ''
        let fromDateVal = ''
        if (dateField === 'fromDate') {
            if (typeof e === 'object') fromDateVal = formatDate(e.toString())
            toDateVal = getOtherDate(fromDateVal, 'forward')
        } else if (dateField === 'toDate') {
            if (typeof e === 'object') toDateVal = formatDate(e.toString())
            fromDateVal = getOtherDate(toDateVal, 'back')
        }

        // If fromDate equals any previous time sheet then display the pop up
        const dateMatch = TimeSheetList?.find(x => x.payPeriodFrom === fromDateVal)
        if (dateMatch) {
            setOpenPrevTimeSheet({ open: true, tsId: dateMatch.id.toString() })
        }
        // Adding space at the end is a little hack to avoid being one day behind (time zone issue)
        setDate({ toDate: new Date(toDateVal + ' '), fromDate: new Date(fromDateVal + ' ') });
    };

    // Signature functions
    const [open, setOpen] = React.useState(false);
    const handleClick = (toggle: boolean) => {
        setOpen(toggle);
    };

    // create a state that will contain our image url
    const [imageURL, setImageURL] = useState<string | null>(null);
    const [sigTimeStamp, setSigTimeStamp] = useState<string>();

    const sigCanvas = useRef<ReactSignatureCanvas>({} as ReactSignatureCanvas);

    /* a function that uses the canvas ref to clear the canvas
    via a method given by react-signature-canvas */
    const clear = () => sigCanvas.current.clear();

    /* a function that uses the canvas ref to trim the canvas
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
    const save = () => {
        const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
        setImageURL(dataUrl);
        setSigTimeStamp(new Date().toLocaleString())
        handleClick(false)

        // Clear any signature errors
        if (errors?.employeeSignature) {
            errors.employeeSignature = ''
            clearErrors()
        }
    }

    useEffect(() => {
        if (TimeSheet) {
            setDate({
                fromDate: TimeSheet.payPeriodFrom ? new Date(TimeSheet.payPeriodFrom + ' ') : new Date,
                toDate: TimeSheet.payPeriodTo ? new Date(TimeSheet.payPeriodTo + ' ') : new Date,
            })

            const hasDrawnSignature = TimeSheet.employeeSignature && TimeSheet.employeeSignature.startsWith('data:image/png;base64')
            // create a state that will contain our image url
            setImageURL(hasDrawnSignature ? TimeSheet.employeeSignature : null);
            setSigTimeStamp(TimeSheet.signatureTimestamp)

            setTimeSheetComment(TimeSheet?.comments);
        }
    }, [TimeSheet])

    useEffect(() => {
        if (isTimeSheetCreate) {
            // By default start on previous Saturday on pay period
            const fromDateNum = isValidPayPeriodStart(getPrevDateDay(1).toDateString()) ? 1 : 8
            const fromDate = getPrevDateDay(fromDateNum)

            const dateMatch = TimeSheetList?.find(ts => ts.payPeriodFrom === formatDate(fromDate.toString()))
            if (dateMatch) {
                // Show the pop up for the existing time sheets
                setOpenPrevTimeSheet({ open: true, tsId: dateMatch.id.toString() })
            }
            setDate({
                fromDate: fromDate,
                toDate: new Date(getOtherDate(formatDate(getPrevDateDay(fromDateNum - 1).toString()), 'forward'))
            })
        }
    }, [])

    return (
        <>
            <Dialog
                open={openPrevTimeSheet.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Time Sheet Found"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Time sheet for current pay period already exists for <br />
                        {date !== undefined && `${formatDate(date?.fromDate?.toString())} to ${formatDate(date?.toDate?.toString())}`}. <br />
                        Would you like to edit that instead? Otherwise previous changes per time sheet period will be overwritten.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={() => { history.push(`/TimeSheet/edit/${openPrevTimeSheet.tsId}`) }} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                    container
                    direction="row"
                    spacing={3}
                >
                    <Grid item xs={6}>
                        <TextField
                            disabled={true}
                            name="employeeFirstName"
                            fullWidth
                            value={Name || (TimeSheet && `${TimeSheet?.caseManagerFamilyName}, ${TimeSheet?.caseManagerGivenName}`)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            autoOk
                            disabled={disabled || !isTimeSheetCreate}
                            name="payPeriodFrom"
                            label="Pay Period From"
                            fullWidth
                            required={true}
                            format="MM/dd/yyyy"
                            error={Boolean(errors.date)}
                            helperText={errors.date && 'This field is required'}
                            inputRef={register({
                                required: 'This field is required'
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => handleChangeDate(e, 'fromDate')}
                            value={date?.fromDate || undefined}
                            placeholder={'mm/dd/yyyy'}
                            shouldDisableDate={(date: Date) => { return date.getDay() !== 6 || !isValidPayPeriodStart(date.toDateString()) }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            autoOk
                            disabled={disabled || !isTimeSheetCreate}
                            name="payPeriodTo"
                            label="Pay Period To"
                            fullWidth
                            required={true}
                            format="MM/dd/yyyy"
                            error={Boolean(errors.date)}
                            helperText={errors.date && 'This field is required'}
                            inputRef={register({
                                required: 'This field is required'
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={e => handleChangeDate(e, 'toDate')}
                            value={date?.toDate || undefined}
                            placeholder={'mm/dd/yyyy'}
                            shouldDisableDate={(date: Date) => { return date.getDay() !== 5 || !isValidPayPeriodStart(date.toDateString()) }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimeEntryGrid startDate={date?.fromDate || new Date()} timeEntries={TimeEntries} disabled={disabled} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            name="comments"
                            label="Notes/Memo"
                            value={timeSheetComment}
                            multiline
                            minRows={4}
                            fullWidth
                            onChange={e => {
                                if(TimeSheet?.status === 'OPEN')
                                    setTimeSheetComment(e.target.value);
                            }}
                            variant='outlined'
                            inputProps={{ maxLength: 1000 }}
                            inputRef={register()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {!isTimeSheetCreate &&
                            <>
                                <Button variant="outlined" onClick={() => handleClick(true)} disabled={isSupe && !isAdmin}>
                                    Open signature dialog*
                                </Button>
                                {errors?.employeeSignature && <div style={{ color: 'red', padding: '8px 2px', fontSize: '16px' }}>Signature required*</div>}

                                <Dialog open={open} onClose={() => handleClick(false)}>
                                    <DialogTitle>Employee Signature</DialogTitle>
                                    <DialogContent>
                                        <div className={styles.sigContainer}>
                                            <SignaturePad
                                                ref={sigCanvas}
                                                canvasProps={{
                                                    className: styles.signatureCanvas
                                                }}
                                            />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => handleClick(false)}>Cancel</Button>
                                        <Button onClick={clear}>Clear</Button>
                                        <Button onClick={save}>Save</Button>
                                    </DialogActions>
                                </Dialog>

                                {imageURL ? (
                                    <>
                                        <img
                                            src={imageURL}
                                            alt="my signature"
                                            className={styles.signatureImage}
                                        />
                                        <TextField
                                            style={{ display: 'none' }}
                                            name="signatureTimestamp"
                                            label="Signature Timestamp"
                                            type="string"
                                            required={true}
                                            error={Boolean(errors['Missing Signature'])}
                                            helperText={errors.date && 'This field is required'}
                                            inputRef={register({
                                                required: 'This field is required'
                                            })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={TimeSheet && TimeSheet.signatureTimestamp || sigTimeStamp}
                                        />
                                        <div>Signed at: {sigTimeStamp}</div>
                                    </>
                                ) : null}
                                <TextField
                                    style={{ display: 'none' }}
                                    name="employeeSignature"
                                    label="Employee Signature"
                                    type="string"
                                    fullWidth
                                    required={true}
                                    error={Boolean(errors['Missing Signature'])}
                                    helperText={errors.date && 'This field is required'}
                                    inputRef={register({
                                        required: 'This field is required'
                                    })}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={TimeSheet && TimeSheet.employeeSignature || imageURL}
                                />
                            </>
                        }
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        </>
    )
}

export default TimeSheetForm
