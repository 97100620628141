import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Button,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { AbilityContext, Can } from 'components/AuthorizationProvider';
import { useAuthorization } from "components/AuthorizationProvider";

export type TimeSheetListType = {
    caseManagerGivenName: string,
    caseManagerId: number,
    caseManagerFamilyName: string,
    created: string,
    createdBy: string,
    id: number,
    payPeriodFrom: string,
    payPeriodTo: string,
    status: string,
    submitted: number,
    supervisorEmailAddress: string,
    supervisorId: number,
    tableData: {},
    updated: string,
    updatedBy: string
}

const TimeSheetList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "TimeSheet")
    const isAdmin = ability.can("manage", "all") || ability.can("manage", "TimeSheet") || ability.can("readAll", "TimeSheet")

    const userInfo = useAuthorization();
    const loadTimeSheet = useCallback(() => {
        loadAction(
            '/TimeSheet',
            'TimeSheet',
            (data) => {
                // Reduce results to case manager or supervisor
                // TODO: Move this logic to the API in the initial query for performance
                const userId = userInfo.caseManagerId
                let filteredData
                if (isAdmin) filteredData = data
                else filteredData = data.filter((x: TimeSheetListType) => x.caseManagerId === userId || x.supervisorId === userId)
                setData(filteredData)
            }
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/TimeSheet/${data.id}`,
            `TimeSheet '${data.id}'`,
            loadTimeSheet
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/TimeSheet/edit/${data.id}`)
    };

    useEffect(() => {
        loadTimeSheet();
    }, [loadTimeSheet]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <Typography color="textPrimary">TimeSheets</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <SectionHeader title="TimeSheets">
                    <Can do="create" on="TimeSheet">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/TimeSheet/create')}
                        >
                            Create TimeSheet
                        </Button>
                    </Can>
                </SectionHeader>
                <SectionTitle
                    title="TimeSheet List"
                    icon={faCalendar}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile="TimeSheets"
                    columns={[
                        { field: 'id', title: 'Id', hidden: true },

                        { field: 'rowVersion', title: 'Row Version', hidden: true, export: true },

                        { field: 'caseManagerId', title: 'Case Manager Id', hidden: true, export: true, type: "numeric" },
                        { field: 'caseManagerGivenName', title: 'Case Manager First Name' },
                        { field: 'caseManagerFamilyName', title: 'Case Manager Last Name' },

                        { field: 'payPeriodFrom', title: 'Pay Period From', type: 'date' },
                        { field: 'payPeriodTo', title: 'Pay Period To', type: 'date', defaultSort: 'desc' },

                        { field: 'supervisorId', title: 'Supervisor\'s ID' },
                        { field: 'supervisorEmailAddress', title: 'Supervisor\'s Email' },
                        // { field: 'employeeSignature', title: 'Employee\'s Signature' },
                        { field: 'signatureTimestamp', title: 'Signature Timestamp' },

                        { field: 'status', title: 'Status' },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true }
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Sheet',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Sheet',
                            onClick: handleDelete,
                            hidden: !allowDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}


export default TimeSheetList
