import React from 'react'
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useProgress } from '../Progress/ProgressProvider'


interface Props {
    dirty: boolean,
    cancelLink?: string,
    saveLabel?: string,
    submitLabel: string,
    disabled?: boolean
    children?: React.ReactNode;
    saveTimeSheet?: (formData: any) => void
}

const FormButtons = ({ dirty, cancelLink, saveLabel = '', submitLabel, disabled = false, children , saveTimeSheet = undefined}: Props) => {
    const history = useHistory();
    const progress = useProgress();

    return (
        <Grid
            style={{
                marginTop: "5px",
                marginBottom: "5px"
            }}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                {children}
            </Grid>
            <Grid item>
                <Grid
                    style={{
                        marginTop: "5px",
                        marginBottom: "5px"
                    }}
                    container
                    justifyContent="flex-end"
                    spacing={3}
                >
                    {cancelLink &&
                        <Grid item>
                            <Button
                                type="button"
                                onClick={() => history.push(cancelLink)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    }
                    { saveLabel && 
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={disabled || dirty === false || progress.loading}
                                onClick={saveTimeSheet}
                            >
                                {saveLabel}
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={ 
                                disabled || 
                                // If it is the time sheet page, allow user to be able to always submit
                                (dirty === false && submitLabel !== "Submit TimeSheet") || 
                                progress.loading}
                        >
                            {submitLabel}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

    )
}

export default FormButtons
