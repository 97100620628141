import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AgreementForm from './AgreementForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { formToData } from 'services/DataTransform';
import { AgreementSchema } from 'models/Schemas';

const AgreementCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();

    const saveServiceAgreement = (formData: any) => {
        const data = formToData(formData, AgreementSchema);

        createAction(
            '/ServiceAgreement', data,
            `service agreement '${data.serviceAgreementNumber}'`,
            () => history.push('/agreements')
        );
    }
    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/agreements">Agreements</LinkRouter>
                <Typography color="textPrimary">Add Agreement</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <FormHeader title="Add New Agreement">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Service Agreement Information"
                    icon={faNotesMedical}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(saveServiceAgreement)} noValidate autoComplete="off">
                        <AgreementForm creating={true} />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/agreements"
                            submitLabel="Save Agreement"
                        />
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default AgreementCreate
