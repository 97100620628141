import { DateTime } from "luxon";

const PayPeriodDays = 14;
const PayPeriodSeed = DateTime.local(2022, 1, 8);

export const payPeriod = (dateTime: Date | DateTime = DateTime.local()) => {
  const date = dateTime instanceof Date ? DateTime.fromJSDate(dateTime) : dateTime;
  const difference = date.diff(PayPeriodSeed, "days");
  const offset = difference.as("days");
  const daysToNextPeriod = offset % PayPeriodDays;

  if (daysToNextPeriod === 0) {
    return {
      startDate: date,
      endDate: date.plus({ days: PayPeriodDays - 1 }),
    };
  }

  var nextStart = date.plus({ days: PayPeriodDays - daysToNextPeriod });
  var startDate = nextStart.plus({ days: -PayPeriodDays });
  var endDate = startDate.plus({ days: PayPeriodDays - 1 });

  return {
    startDate,
    endDate,
  };
};

export const previousPeriod = (dateTime: Date | DateTime = DateTime.local()) => {
  const date = dateTime instanceof Date ? DateTime.fromJSDate(dateTime) : dateTime;
  return payPeriod(date.plus({ days: PayPeriodDays * -1 }));
}

export const nextPeriod = (dateTime: Date | DateTime = DateTime.local()) => {
  const date = dateTime instanceof Date ? DateTime.fromJSDate(dateTime) : dateTime;
  return payPeriod(date.plus({ days: PayPeriodDays }));
}
