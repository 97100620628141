import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid, TextField, Box, Button, FormControl, InputLabel, Select } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';


const UnitsDelayedBilling = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { loadAction, postAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [selection, setSelection] = useState<any[] | undefined>();

    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());
    const [showReleased, setShowReleased] = useState<boolean>(false);

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    // load data

    const loadData = useCallback((startDate: string, endDate: string, showReleased: boolean) => {
        const url = URI("/Report/UnitsDelayedBilling")
            .setSearch({
                startDate,
                endDate,
                showReleased
            });

        loadAction(
            url.toString(),
            'Units Delayed For Billing',
            setData
        );
    }, [loadAction])

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${data.ClientId}/notes/${data.ServiceNoteId}`)
    };

    const handleSelectionChange = (data: any[], rowData?: any) => {
        setSelection(data);
    };

    const clearDelayedBilling = () => {
        if (!selection?.length) return

        const ids: any[] = selection.map((s: any) => s.ServiceNoteId);
        const data = { ids };

        postAction(
            '/ServiceNote/ClearDelayedBilling', data,
            'service notes',
            () => {
                setSelection([]);
                loadData(delayedStartDate, delayedEndDate, showReleased);
            }
        );
    };

    useEffect(() => {
        if (!DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid) {
            setTimeout(() => setData([]), 0);
            return;
        }

        loadData(delayedStartDate, delayedEndDate, showReleased);
    }, [loadData, delayedStartDate, delayedEndDate, showReleased]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Units Delayed For Billing</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Units Delayed For Billing">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Units Delayed For Billing Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={4}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Show Released
                                </InputLabel>

                                <Select native
                                    value={showReleased}
                                    onChange={(event: any) => setShowReleased(event.target.value)}
                                >
                                    <option value="false">false</option>
                                    <option value="true">true</option>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                </fieldset>

                <Box padding="5px">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!Boolean(selection?.length)}
                        onClick={clearDelayedBilling}
                    >
                        Release for Billing
                    </Button>
                </Box>

                <DataGrid
                    exportFile={`Units Delayed For Billing ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name' },
                        { field: 'CoverageStatus', title: 'Coverage Status' },
                        { field: 'TypeWaiver', title: 'Type Waiver' },
                        { field: 'Spenddown', title: 'Spenddown', width: 140, type: 'numeric' },
                        { field: 'ServiceAgreementNumber', title: 'Service Agreement', width: 200 },
                        { field: 'DateOfServiceDelayed', title: 'Date of Service', width: 190, type: 'date', defaultSort: 'asc' },
                        { field: 'UnitsDelayed', title: 'Units Delayed', width: 190, type: 'numeric' },
                        { field: 'Charge', title: 'Charge', width: 190, type: 'numeric' },
                        { field: 'ServiceNote', title: 'Service Note', hidden: true, export: true },
                        { field: 'IsBillingDelayed', title: 'Delayed', type: 'boolean' },
                        { field: 'BillingDelayedReason', title: 'Delayed Reason', width: 190 },
                        { field: 'BillingDelayedReleased', title: 'Delay Released', type: 'date', width: 190 },
                    ]}
                    data={data}
                    detailPanel={(rowData: any) => (
                        <Box
                            padding={1.5}
                            style={{
                                border: '1px solid #e6e6e6',
                                borderRadius: 4,
                                overflow: 'auto',
                                maxHeight: '400px'
                            }}
                        >
                            <Typography style={{ whiteSpace: 'pre-line' }}>
                                {rowData.ServiceNote}
                            </Typography>
                        </Box>
                    )}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                    options={{
                        selection: true
                    }}
                    onSelectionChange={handleSelectionChange}
                />
            </Paper>
        </Container>
    )
}

export default UnitsDelayedBilling
