import React, { createContext, useContext, useState } from 'react';
import Progress from './Progress';

export interface ProgressOptions {
    loading: boolean;
    start: () => void;
    complete: () => void;
}

export const ProgressContext = createContext<ProgressOptions>({
    loading: false,
    start: () => { },
    complete: () => { }
});

export const useProgress = () => useContext(ProgressContext);

interface Props {
    children: React.ReactNode
}

export const ProgressProvider = ({ children }: Props) => {
    const [status, setStatus] = useState<{ count: number, loading: boolean }>({
        count: 0,
        loading: false
    });

    const start = React.useCallback(() => {
        setStatus(current => {
            const count = current.count + 1;
            return {
                count: count,
                loading: count > 0
            }
        });
    }, []);

    const complete = React.useCallback(() => {
        setStatus(current => {
            const count = Math.max(0, current.count - 1)
            return {
                count: count,
                loading: count > 0
            }
        });
    }, []);

    return (
        <ProgressContext.Provider
            value={{
                loading: status.loading,
                start,
                complete,
            }}
        >
            <Progress
                loading={status.loading}
                incrementDuration={500}
                color="#f44336"
            />
            {children}
        </ProgressContext.Provider>
    )
}
