import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import ManagerSelect from 'components/Form/ManagerSelect';
import DiagnosisSelect from 'components/Form/DiagnosisSelect'
import ServiceSelect from 'components/Form/ServiceSelect';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Grid,
    TextField,
} from '@material-ui/core';

interface Props {
    disabled?: boolean;
}

const ClientServiceForm = ({ disabled = false }: Props) => {
    const form = useFormContext();
    const theme = useTheme();
    const { errors, register } = form;
    const [serviceAutoRenew, setServiceAutoRenew] = useState(false);

    return (
        <React.Fragment>

            <SectionTitle
                title="Initial Services"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={6}>
                    <ManagerSelect
                        disabled={disabled}
                        name="caseManagerId"
                        label="Case Manager"
                        required={true}
                    />

                </Grid>

                <Grid item xs={6}>
                    <DiagnosisSelect
                        disabled={disabled}
                        name="primaryDiagnosisId"
                        label="Primary Diagnosis"
                        required={true}
                        multiselect={false}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        name="transferDate"
                        label="Transfer Date"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.transferDate)}
                        helperText={errors.transferDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="waiverSpanStartDate"
                        label="Waiver Span Start Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.waiverSpanStartDate)}
                        helperText={errors.waiverSpanStartDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="waiverSpanEndDate"
                        label="Waiver Span End Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.waiverSpanEndDate)}
                        helperText={errors.waiverSpanEndDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="exitDate"
                        label="Exit Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.exitDate)}
                        helperText={errors.exitDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ServiceSelect
                        name="serviceId"
                        label="Services"
                        required={true}
                        multiple={true}
                        serviceAutoRenew={setServiceAutoRenew}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        name="startDate"
                        label="Service Start"
                        required
                        fullWidth
                        type="date"
                        error={Boolean(form.errors.startDate)}
                        helperText={form.errors.startDate && 'This field is required'}
                        inputRef={form.register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        name="unitsAllocated"
                        label="Service Units"
                        required
                        fullWidth
                        type="number"
                        error={Boolean(form.errors.unitsAllocated)}
                        helperText={form.errors.unitsAllocated && 'This field is required'}
                        inputRef={form.register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientServiceForm
