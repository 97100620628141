import { runWithAdal } from 'react-adal'
import { authenticationContext } from './services/Authentication'

const DO_NOT_LOGIN = false

runWithAdal(
    authenticationContext,
    () => {
        // eslint-disable-next-line
        require('./boot.tsx')
    },
    DO_NOT_LOGIN
)
