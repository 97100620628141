import React, {createContext, useContext, useState, useRef} from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog';

const ConfirmationContext = createContext<(options: ConfirmationOptions) => Promise<boolean>>(Promise.reject);

export const useConfirmation = () => useContext(ConfirmationContext);

interface Props {
    children: React.ReactNode
}

export const ConfirmationProvider = (props: Props) => {
    const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null);

    const awaitingPromiseRef = useRef<{
        resolve: (confirmed: boolean) => void;
        reject: () => void;
    }>();

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options);
        return new Promise<boolean>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(false);
        }

        setConfirmationState(null);
    };

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve(true);
        }

        setConfirmationState(null);
    };

    return (
        <React.Fragment>
            <ConfirmationContext.Provider
                value={openConfirmation}
                children={props.children}
            />

            <ConfirmationDialog
                open={Boolean(confirmationState)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                title={confirmationState?.title ?? ''}
                description={confirmationState?.description ?? ''}
                variant={confirmationState?.variant ?? 'danger'}
            />
        </React.Fragment>
    );
};
