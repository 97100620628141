import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import RemoteSelect from 'components/Form/RemoteSelect';

const CompanyForm = () => {
    const form = useFormContext();
    const { errors, register } = form;

    return (
        <Grid
            style={{
                marginTop: "5px",
                marginBottom: "5px"
            }}
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Name"
                    required
                    fullWidth
                    error={Boolean(errors.name)}
                    helperText={errors.name && 'This field is required'}
                    inputRef={register({ required: true })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="addressLine1"
                    label="Address"
                    fullWidth
                    error={Boolean(errors.addressLine1)}
                    helperText={errors.addressLine1 && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <TextField
                    name="city"
                    label="City"
                    fullWidth
                    error={Boolean(errors.city)}
                    helperText={errors.city && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <RemoteSelect
                    name="stateProvince"
                    label="State"
                    required={false}
                    url="/StateProvince?sort=DisplayOrder"
                    optionRender={(state: any) =>
                        <option key={state.abbreviation} value={state.abbreviation}>{state.description}</option>
                    }
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    name="postalCode"
                    label="Postal Code"
                    fullWidth
                    error={Boolean(errors.postalCode)}
                    helperText={errors.postalCode && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    name="emailAddress"
                    label="Email Address"
                    type="email"
                    fullWidth
                    error={Boolean(errors.emailAddress)}
                    helperText={errors.emailAddress && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    name="businessPhone"
                    label="Business Phone"
                    type="tel"
                    fullWidth
                    error={Boolean(form.errors.businessPhone)}
                    helperText={form.errors.businessPhone && 'This field is required'}
                    inputRef={form.register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    name="homePhone"
                    label="Home Phone"
                    type="tel"
                    fullWidth
                    error={Boolean(errors.homePhone)}
                    helperText={errors.homePhone && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <TextField
                    name="mobilePhone"
                    label="Mobile Phone"
                    type="tel"
                    fullWidth
                    error={Boolean(errors.mobilePhone)}
                    helperText={errors.mobilePhone && 'This field is required'}
                    inputRef={register()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default CompanyForm
