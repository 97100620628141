import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import ActivitySelect from 'components/Form/ActivitySelect';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import ManagerSelect from 'components/Form/ManagerSelect';
import { DateTime } from 'luxon';
import { AbilityContext } from 'components/AuthorizationProvider';
import SecureTextField from 'components/Form/SecureTextField';
import TemplateSelector from 'components/Form/TemplateSelector';

interface Props {
    disabled?: boolean;
    locked?: boolean;
    action?: string;
}

const AgreementNoteForm = ({
    disabled = false,
    locked = false,
    action = 'update'
}: Props) => {

    const { errors, register, getValues, setValue } = useFormContext();
    const theme = useTheme();
    const ability = useContext(AbilityContext);
    const subject = 'ServiceNote';

    const allowEdit = ability.can("update", "ServiceNote");
    const allowOverride = ability.can("override", "ServiceNote");
    const denied = (locked && !allowOverride) || !allowEdit;
    const inRange = (date: string, serviceAgreement: any): boolean => {
        if (!serviceAgreement || !date) return true;

        const serviceDate = DateTime.fromISO(date);
        if (!serviceDate) return true;

        const startDate = DateTime.fromISO(serviceAgreement.startDate);
        const endDate = DateTime.fromISO(serviceAgreement.endDate);

        if (!startDate || !startDate.isValid) return true;

        return endDate && endDate.isValid
            ? serviceDate >= startDate && serviceDate <= endDate
            : serviceDate >= startDate;
    }

    const handleCopyTemplate = (template: any) => {
        if (!allowEdit) return;

        setValue("note", template.body, {
            shouldDirty: true,
            shouldValidate: true
        })
    }

    return (
        <React.Fragment>
            <SectionTitle
                title="Service Note"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >

                <Grid item xs={6}>
                    <ManagerSelect
                        readOnly={true}
                        disabled={denied}
                        name="caseManagerId"
                        label="Case Manager"
                        required={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="billingLockDate"
                        label="Billing Lock Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.billingLockDate)}
                        helperText={errors.billingLockDate && 'This field is required'}
                        inputRef={register()}
                        readOnly={true}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="serviceDate"
                        label="Service Date"
                        required
                        fullWidth
                        type="date"
                        error={Boolean(errors.serviceDate)}
                        helperText={errors.serviceDate && errors.serviceDate.message}
                        inputRef={register({
                            required: 'This field is required',
                            validate: {
                                serviceDate: value => {
                                    const { serviceAgreement } = getValues();
                                    return inRange(value, serviceAgreement) || 'Service Date must be within Service Agreement dates'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={denied}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ActivitySelect
                        disabled={denied}
                        name="activityTypes"
                        label="Activities"
                        required={true}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="unitsUsed"
                        label="Units Used"
                        required
                        fullWidth
                        type="number"
                        error={Boolean(errors.unitsUsed)}
                        helperText={errors.unitsUsed && errors.unitsUsed.message}
                        inputRef={register({
                            required: 'This field is required',
                            min: {
                                value: 0,
                                message: 'Units must be greater than or equal to zero'
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={denied}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'end' }}>
                        <TemplateSelector onTemplateSelected={handleCopyTemplate} />
                    </div>

                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="note"
                        label="Note"
                        required
                        fullWidth
                        multiline
                        minRows="15"
                        error={Boolean(errors.note)}
                        helperText={errors.note && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={!allowEdit}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default AgreementNoteForm
