import React, { useContext } from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { AbilityContext } from 'components/AuthorizationProvider';

type Props = {
    action: string,
    subject: string
    field?: string,
    readOnly?: boolean
}

const SecureTextField = (props: Props & TextFieldProps) => {
    const ability = useContext(AbilityContext);
    const deny = ability.cannot(props.action, props.subject, props.field ?? props.name)
    const { InputLabelProps, InputProps, ...rest } = props;

    const readOnly = props.readOnly !== undefined ? props.readOnly : deny

    const localLabel = {
        ...InputLabelProps,
        shrink: true,
        className: readOnly ? 'Mui-disabled' : ''
    };

    const localInput = {
        ...InputProps,
        readOnly: readOnly,
        className: readOnly ? 'Mui-disabled' : ''
    };

    return (
        <TextField
            InputLabelProps={localLabel}
            InputProps={localInput}
            {...rest}
        />
    )
}

export default SecureTextField
