import React from 'react'
import TimelineItemAdded from 'components/Timeline/TimelineItemAdded';
import TimelineItemChanged from 'components/Timeline/TimelineItemChanged';
import TimelineItemRemoved from 'components/Timeline/TimelineItemRemoved';
import TimelineItemOther from 'components/Timeline/TimelineItemOther';

interface Props {
    item: any;
    showIcon?: boolean
}

const TimelineItem = ({ item, showIcon = true }: Props) => {
    const selectItem = (item: any) => {
        if (item.operation === 'Create') {
            return <TimelineItemAdded item={item} showIcon={showIcon} />
        }
        if (item.operation === 'Delete') {
            return <TimelineItemRemoved item={item} showIcon={showIcon} />
        }
        if (item.operation === 'Update') {
            return <TimelineItemChanged item={item} showIcon={showIcon} />
        }

        return <TimelineItemOther item={item} showIcon={showIcon} />
    };

    const classNames = () =>{
        let names = ['timeline-item'];
        if (!showIcon) names.push('timeline-item-hide-icon');
        return names.join(' ');
    }

    return (
        <div className={classNames()}>
            {selectItem(item)}
        </div>
    )
}

export default TimelineItem
