import React, { useState, createContext } from "react";

interface Props {
    children: React.ReactNode;
    file?: string;
}

export const defaultConfiguration = {
    version: process.env.REACT_APP_VERSION,
    clientUrl: process.env.REACT_APP_CLIENT_URL,
    serviceUrl: process.env.REACT_APP_SERVICE_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: process.env.REACT_APP_TENANT_ID,
    environment: process.env.REACT_APP_ENVIRONMENT
}

export interface IConfiguration {
    version: string | undefined,
    clientUrl: string | undefined,
    serviceUrl: string | undefined,
    clientId: string | undefined,
    tenantId: string | undefined,
    environment: string | undefined
}

export const ConfigurationContext = createContext<IConfiguration>(defaultConfiguration);

export const ConfigurationProvider = (props: Props) => {
    const [configuration] = useState<IConfiguration>(defaultConfiguration);

    return (
        <ConfigurationContext.Provider value={configuration}>
            {props.children}
        </ConfigurationContext.Provider>
    )
}

export default ConfigurationProvider
