import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid, TextField } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import { DateTime } from 'luxon';


const OutputBillableUnits = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    // load data
    const loadData = useCallback((startDate: string, endDate: string) => {
        const url = URI("/Report/OutputBillableUnitsAccountingLegacy")
            .setSearch({
                startDate,
                endDate,
            });

        loadAction(
            url.toString(),
            'Output Billable Units to Accounting (Legacy)',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        if (!DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid
        ) {
            setTimeout(() => setData([]), 0);
            return;
        }

        loadData(delayedStartDate, delayedEndDate);
    }, [loadData, delayedStartDate, delayedEndDate]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Output Billable Units to Accounting (Legacy)</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Output Billable Units to Accounting (Legacy)">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Output Billable Units to Accounting Report (Legacy)"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={4}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={(event: any) => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={(event: any) => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Output Billable Units to Accounting ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'Username', title: 'User Name', width: 190 },
                        { field: 'Customer ID', title: 'Customer Id', width: 190 },
                        { field: 'Invoice/CM #', title: 'Invoice CM Number', width: 190 },
                        { field: 'Customer PO', title: 'Customer PO', width: 190 },
                        { field: 'Date', title: 'Date', width: 190, type: 'date' },
                        { field: 'Date Due', title: 'Date Due', width: 190, type: 'date' },
                        { field: 'Sales Representative ID', title: 'Sales Representative Id', width: 190 },
                        { field: 'Accounts Receivable Account', title: 'Accounts Receivable Account', width: 190 },
                        { field: 'Number of Distributions', title: 'Number of Distributions', width: 140 },
                        { field: 'Quantity', title: 'Quantity', width: 140, type: 'numeric' },
                        { field: 'Description', title: 'Description', width: 240 },
                        { field: 'G/L Account', title: 'GL Account', width: 140 },
                        { field: 'Tax Type', title: 'Tax Type', width: 140 },
                        { field: 'Amount', title: 'Amount', width: 140, type: 'currency' },
                        { field: 'Sales Tax Authority', title: 'Sales Tax Authority', width: 140 },]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default OutputBillableUnits
