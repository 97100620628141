import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import TimeSheetList from './TimeSheetList';
import TimeSheetCreate from './TimeSheetCreate';
import TimeSheetEdit from './TimeSheetEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const TimeSheetRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="TimeSheet">
                <TimeSheetCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/edit/:timeSheetId`} action="read" subject="TimeSheet">
                <TimeSheetEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="TimeSheet">
                <TimeSheetList />
            </ProtectedRoute>
        </Switch>
    )
}

export default TimeSheetRoute
