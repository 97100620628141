import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import { NavigationLink } from 'models/NavigationLink';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
        },        
        sectionLink: (props: Props) => ({
            color: theme.palette.text.primary,
            display: "block",
            fontWeight: "bold",
            textDecoration: "none",
            padding: "0.3rem"
        })
    }),
);

interface Props {
    icon: IconDefinition,
    title: string,
    color: string,
    links: NavigationLink[]
}

const DashboardSection = (props: Props) => {
    const classes = useStyles(props);

    return (
        <Paper className={classes.paper}>
            <SectionTitle 
                title={props.title} 
                icon={props.icon} 
                color={props.color} 
            />
            <dl>
                {props.links.filter(l => l.hide !== true).map((link, index) => (
                    <dd key={index}>
                        <Link
                            className={classes.sectionLink}
                            to={link.location}>
                            {link.label}
                        </Link>
                    </dd>
                ))}
            </dl>
        </Paper>
    )
}

export default DashboardSection
