import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { useFormActions } from 'components/Form/FormActions';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import { dataToForm } from 'services/DataTransform';
import { TimeEntrySchema, TimeSheetSchema } from 'models/Schemas';
import { TimeEntryType, TimeSheetType } from 'models/TimeSheetModel';
import TimeSheetDetail from './TimeSheetDetail';
import TimeSheetHistory from './TimeSheetHistory';
import { AbilityContext, useAuthorization } from 'components/AuthorizationProvider';
import { User } from './TimeSheetCreate';

const TimeSheetEdit = () => {
    const { timeSheetId } = useParams<{ timeSheetId: string }>();
    const match = useRouteMatch();
    const [timeSheet, setTimeSheet] = useState<TimeSheetType>();
    const [timeEntries, setTimeEntries] = useState<TimeEntryType[]>();
    const { loadAction } = useFormActions();
    const ability = useContext(AbilityContext);
    const userInfo = useAuthorization();
    const isAdmin = ability.can("manage", "all")
    const isReadOnlyAdmin = ability.can("readAll", "TimeSheet")

    const [userData, setUserData] = useState<User>();
    const [delayed, setDelayed] = useState(true);

    // Get the manager and supervisor info
    const loadManagers = useCallback((id: string) => {
        loadAction(
            `/CaseManager/${id}`,
            'case managers',
            (managerData) => {
                if (managerData?.supervisorId) {
                    loadAction(
                        `/CaseManager/${managerData.supervisorId.toString()}`,
                        'case managers',
                        (supervisorData) => {
                            setUserData(
                                {
                                    FirstName: managerData.givenName,
                                    LastName: managerData.familyName,
                                    FullName: managerData.fullName,
                                    CaseManagerId: managerData.id,
                                    SupervisorId: supervisorData.id,
                                    SupervisorEmailAddress: supervisorData.emailAddress,
                                } as User
                            )
                        }
                    )
                }
            }
        );
    }, [loadAction])

    useEffect(() => {
        loadManagers(userInfo.caseManagerId.toString());
    }, [loadManagers])

    // Add a delay so we don't show the error message too early while the user
    // is fetching the supervisor information asynchronously
    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 1000);
        return () => clearTimeout(timeout);
    }, []);

    const tabs = [
        {
            label: 'Details',
            location: `/timesheet/${timeSheetId}`,
            matchPath: `${match.path}`
        },
        {
            label: 'History',
            location: `/timesheet/${timeSheetId}/history`,
            matchPath: `${match.path}/history`
        }
    ];

    const loadTimeSheet = useCallback(() => {
        loadAction(
            `/TimeSheet/${timeSheetId}`,
            'TimeSheet',
            (data) => {
                const formData = dataToForm(data, TimeSheetSchema);
                setTimeSheet(formData);
            }
        );
        loadAction(
            `/TimeEntry?q=TimeSheetId%3D${timeSheetId}`,
            'TimeEntry',
            (data: Array<any>) => {
                // Time Entries were coming back as not sorted. Need to ascend by date for rows to line up properly
                const sortedData = data.sort((obj1, obj2) => new Date(obj1['entryDate']).getTime() - new Date(obj2['entryDate']).getTime())
                const formData = dataToForm(sortedData, TimeEntrySchema);
                setTimeEntries(formData);
            }
        );
    }, [loadAction, timeSheetId]);

    useEffect(() => {
        loadTimeSheet();
    }, [loadTimeSheet]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/TimeSheet">TimeSheet</LinkRouter>
                <Typography color="textPrimary">
                    {timeSheet && <span>{timeSheet.payPeriodFrom} - {timeSheet.payPeriodTo}</span>}
                </Typography>
            </Breadcrumbs>

            {timeSheetId && !delayed &&
                <Switch>
                    <Route path={`${match.path}/history`}>
                        <TimeSheetHistory timeSheetId={timeSheetId} />
                    </Route>
                    <Route path={match.path}>
                        <TimeSheetDetail
                            timeSheetId={timeSheetId}
                            timeSheet={timeSheet}
                            timeEntries={timeEntries}
                            isSupervisor={userInfo.caseManagerId === timeSheet?.supervisorId}
                            isAdmin={isAdmin}
                            isReadOnlyAdmin={isReadOnlyAdmin} />
                    </Route>
                </Switch>
            }
        </Container>
    )
}

export default TimeSheetEdit
