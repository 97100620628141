import { faBook } from '@fortawesome/free-solid-svg-icons';
import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ProgramForm from './ProgramForm';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';

interface Props {
    programId: string
}

const ProgramDetail = ({ programId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadProgram = useCallback(() => {
        loadAction(
            `/program/${programId}`,
            'program',
            data => {
                reset(data);
            }
        );
    }, [loadAction, programId, reset]);

    const saveProgram = (data: any) => {
        updateAction(
            `/program/${programId}`, data,
            `program '${data.code}'`,
            () => {
                history.push('/programs');
            }
        );
    }

    useEffect(() => {
        loadProgram();
    }, [loadProgram]);

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Program Details">
                <p>* = Required Field</p>
            </FormHeader>
            <SectionTitle
                title="Program"
                icon={faBook}
                color={theme.palette.text.primary}
            />
            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveProgram)} noValidate>
                        <ProgramForm />
                        <FormButtons
                            dirty={formState.isDirty}
                            cancelLink="/programs"
                            submitLabel="Save Program"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ProgramDetail
