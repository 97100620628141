import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ContactTypeList from './ContactTypeList';
import ContactTypeCreate from './ContactTypeCreate';
import ContactTypeEdit from './ContactTypeEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ContactTypeRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`}  action="create" subject="ContactType">
                <ContactTypeCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:contactTypeId`}  action="update" subject="ContactType">
                <ContactTypeEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="ContactType">
                <ContactTypeList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ContactTypeRoute
