import React from 'react'
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";

import AnnualAssessment from './AnnualAssessment';
import BpcAssessmentPaperwork from './BpcAssessmentPaperwork';
import ImpendingDischarge from './ImpendingDischarge';
import NewClients from './NewClients';
import QualityVisit from './QualityVisit';
import QuarterlyAddress from './QuarterlyAddress';


const ReportRoute = () => {
    let match = useRouteMatch();

    return (
        <Switch>
            <Route path={`${match.path}/annual-assessment`}>
                <AnnualAssessment />
            </Route>
            <Route path={`${match.path}/bpc-assessment-paperwork`}>
                <BpcAssessmentPaperwork />
            </Route>
            <Route path={`${match.path}/impending-discharge`}>
                <ImpendingDischarge />
            </Route>
            <Route path={`${match.path}/new-clients`}>
                <NewClients />
            </Route>
            <Route path={`${match.path}/quality-visit`}>
                <QualityVisit />
            </Route>            
            <Route path={`${match.path}/quarterly-address`}>
                <QuarterlyAddress />
            </Route>            
        </Switch>
    )
}

export default ReportRoute
