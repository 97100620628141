import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select,
    makeStyles
} from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    name: string;
    required: boolean;
    disabled?: boolean;
    defaultValue?: string | number | boolean;
    items: { name: string, value: string | number | boolean }[];
    action?: string;
    subject?: string;
    field?: string;
    timeEntryData?: string;
}

const useStyles = makeStyles(() => ({
    textField: {
    },
    input: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        paddingLeft: "10px",
    },
    ErrorHelper: {
        marginTop: "0px"
    }
}));

const Dropdown = ({
    name,
    required,
    disabled = false,
    items,
    defaultValue = '',
    action,
    subject,
    field, 
    timeEntryData = ''
}: Props) => {
    const { errors, register, setValue } = useFormContext();
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>(defaultValue);
    const ability = useContext(AbilityContext);
    const classes = useStyles();

    const formValue = useWatch<any>({ name: name });

    const denied = disabled || Boolean(action && subject && ability.cannot(action, subject, field ?? name));

    // sync selected value
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;

        // set local selected value
        setSelected(selectedValue);

        // set form value
        setValue(name, selectedValue, {
            shouldValidate: true,
            shouldDirty: true
        });
    }

    useEffect(() => {
        if (formValue !== undefined) {
            setSelected(formValue);
        }
    }, [formValue])

    useEffect(() => {
        register({ name: name }, { required: required });
        if (timeEntryData) setValue(name, timeEntryData);
    }, [register, name, required, timeEntryData]);

    return (
        <FormControl
            fullWidth
            required={required}
            disabled={denied}
            error={hasError}
        >
            <Select native
                disabled={denied}
                value={timeEntryData ? timeEntryData : selected}
                onChange={handleChange}
                className={classes.input}
            >
                <option aria-label="None" value="" />
                {items && items.map((item: any) => (
                    <option key={item.value} value={item.value}>{item.name}</option>
                ))}
            </Select>

            <FormHelperText
                className={classes.ErrorHelper}
            >
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default Dropdown
