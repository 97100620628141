import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import RemoteSelect from 'components/Form/RemoteSelect';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Grid,
    TextField,
} from '@material-ui/core';

interface Props {
    disabled?: boolean;
}

const ClientContactForm = ({ disabled = false }: Props) => {
    const form = useFormContext();
    const theme = useTheme();
    const { errors, register, getValues } = form;

    return (
        <React.Fragment>
            <SectionTitle
                title="Details"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={4}>
                    <TextField
                        disabled={disabled}
                        name="givenName"
                        label="First Name"
                        required
                        fullWidth
                        error={Boolean(errors.givenName)}
                        helperText={errors.givenName && errors.givenName.message}
                        inputRef={register({
                            validate: {
                                givenName: value => {
                                    const { familyName, agencyName } = getValues();
                                    return Boolean((value && familyName) || agencyName) || 'First & Last Name or Agency Name required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="middleName"
                        label="Middle Name"
                        fullWidth
                        error={Boolean(errors.middleName)}
                        helperText={errors.middleName && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        disabled={disabled}
                        name="familyName"
                        label="Last Name"
                        required
                        fullWidth
                        error={Boolean(errors.familyName)}
                        helperText={errors.familyName && errors.familyName.message}
                        inputRef={register({
                            validate: {
                                familyName: value => {
                                    const { givenName, agencyName } = getValues();
                                    return Boolean((value && givenName) || agencyName) || 'First & Last Name or Agency Name required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={disabled}
                        name="agencyName"
                        label="Agency Name"
                        required
                        fullWidth
                        error={Boolean(errors.agencyName)}
                        helperText={errors.agencyName && errors.agencyName.message}
                        inputRef={register({
                            validate: {
                                agencyName: value => {
                                    const { givenName, familyName } = getValues();
                                    return Boolean((familyName && givenName) || value) || 'First & Last Name or Agency Name required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="title"
                        label="Title"
                        fullWidth
                        error={Boolean(errors.title)}
                        helperText={errors.title && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <RemoteSelect
                        disabled={disabled}
                        label="Contact Type"
                        name="contactTypeId"
                        required={true}
                        url="/ContactType?sort=DisplayOrder"
                        optionRender={(type: any) =>
                            <option key={type.id} value={type.id}>{type.name}</option>
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        name="addressLine1"
                        label="Address"
                        fullWidth
                        error={Boolean(errors.addressLine1)}
                        helperText={errors.addressLine1 && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        disabled={disabled}
                        name="city"
                        label="City"
                        fullWidth
                        error={Boolean(errors.city)}
                        helperText={errors.city && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <RemoteSelect
                        disabled={disabled}
                        name="stateProvince"
                        label="State"
                        url="/StateProvince?sort=DisplayOrder"
                        optionRender={(state: any) =>
                            <option key={state.abbreviation} value={state.abbreviation}>{state.description}</option>
                        }
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="postalCode"
                        label="Postal Code"
                        fullWidth
                        error={Boolean(errors.postalCode)}
                        helperText={errors.postalCode && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        name="emailAddress"
                        label="Email Address"
                        type="email"
                        fullWidth
                        error={Boolean(errors.emailAddress)}
                        helperText={errors.emailAddress && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="homePhone"
                        label="Home Phone"
                        type="tel"
                        required
                        fullWidth
                        error={Boolean(errors.homePhone)}
                        helperText={errors.homePhone && errors.homePhone.message}
                        inputRef={register({
                            validate: {
                                homePhone: value => {
                                    const { businessPhone, mobilePhone } = getValues();
                                    return Boolean(value || businessPhone || mobilePhone) || 'Home, Business or Mobile Phone required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="businessPhone"
                        label="Business Phone"
                        required
                        fullWidth
                        error={Boolean(errors.businessPhone)}
                        helperText={errors.businessPhone && errors.businessPhone.message}
                        inputRef={register({
                            validate: {
                                businessPhone: value => {
                                    const { homePhone, mobilePhone } = getValues();
                                    return Boolean(value || homePhone || mobilePhone) || 'Home, Business or Mobile Phone required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="mobilePhone"
                        label="Mobile Phone"
                        type="tel"
                        required
                        fullWidth
                        error={Boolean(errors.mobilePhone)}
                        helperText={errors.mobilePhone && errors.mobilePhone.message}
                        inputRef={register({
                            validate: {
                                mobilePhone: value => {
                                    const { businessPhone, homePhone } = getValues();
                                    return Boolean(value || businessPhone || homePhone) || 'Home, Business or Mobile Phone required'
                                }
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        name="faxNumber"
                        label="Fax Number"
                        fullWidth
                        error={Boolean(errors.faxNumber)}
                        helperText={errors.faxNumber && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        name="note"
                        label="Notes"
                        fullWidth
                        multiline
                        minRows="10"
                        error={Boolean(errors.note)}
                        helperText={errors.note && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientContactForm
