import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Button,
    Container,
    Paper,
    Select,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions'
import { AbilityContext, Can } from 'components/AuthorizationProvider';
import useFetch from 'use-http';
import { MTableToolbar } from 'material-table';
import { useAuthorization } from 'components/AuthorizationProvider';

const AgreementList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const userInfo = useAuthorization();

    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [filteredData, setFilteredData] = useState<any[]>([]);
    const { data: managers } = useFetch('/CaseManager?sort=FamilyName&q=IsActive%3Dtrue', []);
    const [filter, setFilter] = useState<string>(String(userInfo.caseManagerId));

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "ServiceAgreement")

    const loadServiceAgreements = useCallback(() => {
        loadAction(
            '/ServiceAgreement',
            'service agreements',
            setData
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/ServiceAgreement/${data.id}`,
            `service agreement '${data.serviceAgreementNumber}'`,
            loadServiceAgreements
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/Agreements/${data.id}`)
    };

    const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;
        setFilter(selectedValue);
    }

    useEffect(() => {
        loadServiceAgreements();
    }, [loadServiceAgreements]);

    useEffect(() => {
        const filtered = data?.filter((row: any) => {
            if (!filter || filter === '' || !row.caseManagerId) {
                return true;
            }

            return row.caseManagerId === Number(filter)
        })

        setFilteredData(filtered);
    }, [data, filter]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <Typography color="textPrimary">Agreements</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <SectionHeader title="Service Agreements">
                    <Can do="create" on="ServiceAgreement">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/Agreements/create')}
                        >
                            Add Agreement
                        </Button>
                    </Can>
                </SectionHeader>
                <SectionTitle
                    title="Service Agreements"
                    icon={faNotesMedical}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile="ServiceAgreements"
                    columns={[
                        { field: 'id', title: 'Id', hidden: true, export: true },

                        { field: 'serviceAgreementNumber', title: 'Agreement Number', width: 200 },
                        { field: 'lineNumber', title: 'Line Number', width: 150, type: 'numeric' },
                        { field: 'subscriberId', title: 'Subscriber ID', width: 150, type: 'numeric' },

                        { field: 'clientFullName', title: 'Client', cellStyle: { minWidth: 150 } },
                        { field: 'clientFamilyName', title: 'Last', hidden: true, export: true },
                        { field: 'clientGivenName', title: 'First', hidden: true, export: true },
                        { field: 'clientEmail', title: 'Email', hidden: true, export: true },

                        { field: 'caseManagerName', title: 'Manager', cellStyle: { minWidth: 150 } },
                        { field: 'caseManagerFamilyName', title: 'Manager Last', hidden: true, export: true },
                        { field: 'caseManagerGivenName', title: 'Manager First', hidden: true, export: true },

                        { field: 'serviceId', title: 'Service Id', hidden: true, export: true },
                        { field: 'procedureCode', title: 'Procedure', hidden: true, export: true },
                        { field: 'serviceName', title: 'Service Name', cellStyle: { minWidth: 150 } },

                        { field: 'startDate', title: 'Start', width: 160, type: 'date', defaultSort: 'desc' },
                        { field: 'endDate', title: 'End', width: 160, type: 'date' },

                        { field: 'unitsAllocated', title: 'Units Allocated', width: 180, type: 'numeric' },
                        {
                            field: 'billableUnitsUsed',
                            title: 'Billable Units',
                            width: 180,
                            type: 'numeric',
                            cellStyle: (data: any[], rowData: any) => ({
                                color: rowData.billableUnitsUsed >= rowData.unitsAllocated ? theme.palette.error.main : 'inherit'
                            })
                        },

                        { field: 'isArchived', title: 'Archived', width: 140, type: 'boolean' },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                    ]}
                    data={filteredData}
                    components={{
                        Toolbar: props => (
                            <div className={classes.toolbarWrapper}>
                                <Select native
                                    className={classes.toolbarSelector}
                                    value={filter}
                                    onChange={handleFilterChange}>
                                    <option aria-label="None" value="">- All Case Managers -</option>
                                    {managers && managers.map((item: any) => (
                                        <option key={item.id} value={item.id}>{item.fullName}</option>
                                    ))}
                                </Select>
                                <MTableToolbar
                                    classes={{
                                        spacer: classes.clearSpacer
                                    }}
                                    {...props}
                                />
                            </div>
                        )
                    }}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete,
                            hidden: !allowDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default AgreementList
