import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { useAuthorization } from "components/AuthorizationProvider";
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';

interface Props {

}

const ImpendingDischarge = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const userInfo = useAuthorization();
    const history = useHistory();
   
    const loadData = useCallback(() => {
        if (!userInfo || !userInfo.caseManagerId) return;

        loadAction(
            `/Notification/ImpendingDischarge?caseManagerId=${userInfo.caseManagerId}`,
            'Impending Discharge',
            setData
        );
    }, [loadAction, userInfo])

    const handleEdit = (event: any, data: any) => {
        if (!data.ClientId) return;
        
        history.push(`/clients/${data.ClientId}/facility/${data.FacilityStayId}`)
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Impending Discharge</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Impending Discharge">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Impending Discharge Notification"
                    icon={faBell}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile={`Impending Discharge ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name', defaultSort: 'asc' },
                        { field: 'Admitted', title: 'Admitted Date', width: 180, type: 'date' },
                        { field: 'DaysInFacility', title: 'Days', width: 180 },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ImpendingDischarge
