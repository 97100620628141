export const formatDate = (date: string, mdyFormat: boolean = false) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if (mdyFormat)
        return [month, day, year].join('-')

    return [year, month, day].join('-');
}

export const getOtherDate = (date: string, direction: 'back' | 'forward') => {
    let otherDate = '';
    if (date) {
        otherDate = direction === 'forward' ? formatDate(new Date(new Date(date).getTime() + 12096e5).toDateString()) : 
            formatDate(new Date(new Date(date).getTime() - 10368e5).toDateString());
    }
    return otherDate;
}

export function getPrevDateDay(dayNum: number){
    var today = new Date();
    var lastDay = new Date(new Date().setDate(today.getDate()-today.getDay()-dayNum));
    return lastDay
}

export function isValidPayPeriodStart(date: string) {
    // Using Jan 8 2022 as a baseline for pay periods
    const startPayPeriod = new Date('01/08/2022').getTime()
    const toDate = new Date(date).getTime()
    const weeks = Math.round((toDate - startPayPeriod) / 604800000)

    // If weeks is even then it's a valid pay period
    return weeks % 2 === 0
}

export const convertTimeStringToMilliseconds = (t: string) => {
    if (t && typeof t === 'string') {
        //const shortTime = getShortTime(t);
        return Number(t.split(':')[0]) * 60 * 60 * 1000 + Number(t.split(':')[1]) * 60 * 1000
    }
    return 0
}   

export const msToTime = (ms: number) => {
    return Math.round((ms / (1000 * 60 * 60)) * 4) / 4
}

export const getShortTime = (t: string) => {
    // Check date format. If more than one colon in a date string like this: "2022-08-17T14:06:00.0000000" and reduce to "14:06"
    let convertedTime = t
    if (typeof t === 'string' && (t.match(/:/g) || []).length > 1) {
        const removedDate = t.split('T')[1];
        const time = removedDate.split(':');
        convertedTime = `${time[0]}:${time[1]}`;
    }
    return convertedTime;
}

// Get time format from hours in decimal format. "1.25" -> "1:15"
export const getTimeFormat = (num: string) => { 
    var decimalTime = parseFloat(num) * 3600
    var hours = Math.floor((decimalTime / 3600))
    decimalTime = decimalTime - (hours * 3600)
    var minutes = Math.floor((decimalTime / 60))
    decimalTime = decimalTime - (minutes * 60)

    let hoursStr = hours.toString()
    let minStr = minutes.toString()
    if (hours < 10)
        hoursStr = '0' + hours
    
    if (minutes < 10)
        minStr = '0' + minutes
    
    return `${hoursStr}:${minStr}`
}

export const getTimeFormatted = (time: string) => {
    let formattedTime = ''
    if (time) {
        const timeSplit = time.split(':')
        if (timeSplit[0] >= '12') {
            const first = timeSplit[0] === '12' ? timeSplit[0] : Number(timeSplit[0]) - 12
            formattedTime = `${first}:${timeSplit[1]} PM`
        } else {
            formattedTime = `${time} AM`
        }
    }
    return formattedTime
}