import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid, FormControl, InputLabel } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles, selectStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import URI from 'urijs';
import ReactSelect from 'react-select';
import { useHistory } from 'react-router-dom';
import ManagerPicker from 'components/Pickers/ManagerPicker';
import ServicePicker from 'components/Pickers/ServicePicker';

const ClientList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [caseManagers, setCaseManagers] = useState<any[]>([]);
    const [service, setService] = useState<any | null>(null);
    const [active, setActive] = useState<any | null>({ value: true, text: 'Active' });

    const firstLoad = useRef(true);

    const loadData = useCallback((caseManagerIds: number[], serviceId: number | null, active: boolean | null) => {
        const url = URI("/Report/ClientList")
            .setSearch({
                caseManagerIds,
                serviceId,
                active
            });

        loadAction(
            url.toString(),
            'Client List',
            setData
        );
    }, [loadAction])

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${data.ClientId}`)
    };

    useEffect(() => {
        // prevent double loading of list
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
        
        const ids = caseManagers ? caseManagers.map(m => m.id) : [];
        const serviceId = service?.id;
        let isActive = active?.value;
        if (isActive === '')
            isActive = null

        loadData(ids, serviceId, isActive);
    }, [loadData, caseManagers, service, active]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Client List</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Client List">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Client List Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={6}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <ServicePicker
                                selected={service}
                                setSelected={setService}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel shrink>
                                    Active
                                </InputLabel>

                                <ReactSelect
                                    styles={selectStyles}
                                    isClearable={true}
                                    options={[
                                        { value: true, text: 'Active' },
                                        { value: false, text: 'Inactive' },

                                    ]}
                                    getOptionLabel={(option: any) => option.text}
                                    getOptionValue={(option: any) => option.value}
                                    value={active}
                                    onChange={selected => {
                                        setActive(selected);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Client List ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientId', title: 'Client Id', type: 'numeric', hidden: true },
                        { field: 'CaseId', title: 'Case Id', type: 'numeric', hidden: true },
                        { field: 'CaseManager', title: 'Case Manager' },
                        { field: 'ClientName', title: 'Client Name', defaultSort: 'asc' },
                        { field: 'ServiceType', title: 'Services' },
                        { field: 'TransferDate', title: 'Transfer Date', width: 200, type: 'date' },
                        { field: 'WaiverSpanStartDate', title: 'Waiver Span Start', width: 190, type: 'date' },
                        { field: 'WaiverSpanEndDate', title: 'Waiver Span End', width: 190, type: 'date' },
                        { field: 'ExitDate', title: 'Exit Date', width: 150, type: 'date' },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit,
                            position: 'row'
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ClientList
