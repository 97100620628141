import React from 'react'

interface Props {
    color?: string;
}

const Spinner = ({ color = '#29d' }: Props) => {
    return (
        <div
            style={{
                display: 'block',
                position: 'fixed',
                right: 8,
                top: 22,
                zIndex: 1200,
            }}
        >
            <div
                style={{
                    animation: '400ms linear infinite spinner',
                    borderBottom: '2px solid transparent',
                    borderLeft: `2px solid ${color}`,
                    borderRadius: '50%',
                    borderRight: '2px solid transparent',
                    borderTop: `2px solid ${color}`,
                    boxSizing: 'border-box',
                    height: 18,
                    width: 18,
                }}
            />
        </div>
    )
}

export default Spinner
