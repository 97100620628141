import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ServiceRateForm from './ServiceRateForm';
import { ServiceRateSchema } from 'models/Schemas';


interface Props {
    serviceId: string
}

const ServiceRateCreate = ({ serviceId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();

    const saveServiceRate = (formData: any) => {
        formData.serviceId = serviceId;
        const data = formToData(formData, ServiceRateSchema);

        createAction(
            '/ServiceRate', data,
            'Service Rate',
            () => history.push(`/services/${serviceId}/rates`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Rate">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveServiceRate)} noValidate autoComplete="off">
                    <ServiceRateForm serviceId={serviceId} />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/services/${serviceId}/rates`}
                        submitLabel="Save Rate"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ServiceRateCreate
