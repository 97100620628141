import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';

import './App.css';
import 'react-block-ui/style.css';

import GatewayProvider from './components/Gateway';
import ApplicationBar from './components/Navigation/ApplicationBar';
import SideDrawer from './components/Navigation/SideDrawer';
import Footer from 'components/Navigation/Footer';

import AdministrationRoute from './pages/Administration/AdministrationRoute';
import AgreementRoute from './pages/Agreements/AgreementRoute';
import ClientRoute from './pages/Clients/ClientRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import ProgramRoute from './pages/Programs/ProgramRoute';
import ServiceRoute from './pages/Services/ServiceRoute';
import ReportRoute from './pages/Reports/ReportRoute';
import NotificationRoute from './pages/Notifications/NotificationRoute';
import TimeSheetRoute from './pages/TimeTracker/TimeSheetRoute';
import Permissions from './pages/Permissions';

import { ConfirmationProvider } from './components/Confirmation/ConfirmationProvider';
import { ConfigurationProvider } from 'components/Configuration';
import { ProgressProvider } from 'components/Progress/ProgressProvider';
import { AuthorizationProvider } from 'components/AuthorizationProvider';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      maxWidth: '100%'
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

const App = () => {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <BrowserRouter getUserConfirmation={(message, callback) => {
      const allowTransition = window.confirm(message);
      callback(allowTransition);
    }}
    >
      <ConfigurationProvider>
        <AuthorizationProvider>
          <ProgressProvider>
            <GatewayProvider>
              <ConfirmationProvider>
                <SnackbarProvider
                  preventDuplicate
                  maxSnack={3}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                >
                  <Router>
                    <div className={classes.root}>
                      <CssBaseline />
                      <ApplicationBar
                        isDrawerOpen={isDrawerOpen}
                        handleDrawerOpen={handleDrawerOpen}
                      />
                      <SideDrawer
                        isDrawerOpen={isDrawerOpen}
                        handleDrawerClose={handleDrawerClose}
                      />
                      <main
                        className={clsx(classes.content, {
                          [classes.contentShift]: isDrawerOpen,
                        })}
                      >
                        <div className={classes.appBarSpacer + " hide-print"} />
                        <ErrorBoundary FallbackComponent={ErrorFallback}>
                          <Switch>
                            <Route path="/administration">
                              <AdministrationRoute />
                            </Route>
                            <Route path="/agreements">
                              <AgreementRoute />
                            </Route>
                            <Route path="/clients">
                              <ClientRoute />
                            </Route>
                            <Route path="/programs">
                              <ProgramRoute />
                            </Route>
                            <Route path="/services">
                              <ServiceRoute />
                            </Route>
                            <Route path="/reports">
                              <ReportRoute />
                            </Route>
                            <Route path="/notifications">
                              <NotificationRoute />
                            </Route>
                            <Route path="/TimeSheet">
                              <TimeSheetRoute />
                            </Route>
                            <Route path="/permissions">
                              <Permissions />
                            </Route>
                            <Route path="/" exact>
                              <Dashboard />
                            </Route>
                          </Switch>
                        </ErrorBoundary>
                        <Footer />
                      </main>
                    </div>
                  </Router>
                </SnackbarProvider>
              </ConfirmationProvider>
            </GatewayProvider>
          </ProgressProvider>
        </AuthorizationProvider>
      </ConfigurationProvider>
    </BrowserRouter>
  );
}

export default App;
