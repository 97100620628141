import React, { useState, useCallback, useEffect } from 'react'
import { Box, Breadcrumbs, Button, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import URI from 'urijs';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';


const OutputNewClients = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction, postAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    // load data
    const loadData = useCallback(() => {
        const url = URI("/Report/OutputNewClientsAccounting");

        loadAction(
            url.toString(),
            'Output New Clients to Accounting',
            setData
        );
    }, [loadAction])

    const markExported = () => {
        if (!data?.length) return

        const ids: any[] = data.map((s: any) => s.ClientId);
        const postData = { ids };

        postAction(
            '/client/markExported', postData,
            'clients',
            exportData
        );
    };

    const exportData = () => {
        if (!data?.length) return

        const formatted = data.map((row: any) => {
            return {
                CustomerID: row.CustomerID,
                CustomerName: row.CustomerName,
                BillToAddressLine1: row.BillToAddressLine1,
                BillToAddressLine2: row.BillToAddressLine2,
                BillToCity: row.BillToCity,
                BillToState: row.BillToState,
                BillToZip: row.BillToZip,
                CustomerType: row.CustomerType,
                Telephone: row.Telephone,
                GLSalesAccount: row.GLSalesAccount,
                AdmitDate: DateTime.fromISO(row.AdmitDate).toLocaleString(),
                MANumber: row.MANumber,
                SSNumber: row.SSNumber,
                DateOfBirth: DateTime.fromISO(row.DateOfBirth).toLocaleString()
            };
        });

        const content = Papa.unparse(formatted);
        var filename = `Output New Clients ${DateTime.local().toISODate()}`;

        var blob = new Blob([content], {
            type: "text/csv;charset=utf-8"
        });

        saveAs(blob, filename);

        setTimeout(() => {
            loadData();
        }, 100)
    }

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Output New Clients to Accounting</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Output New Clients to Accounting">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Output New Clients to Accounting Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <Box padding="5px">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!Boolean(data?.length)}
                        onClick={markExported}
                    >
                        Export Clients
                    </Button>
                </Box>

                <DataGrid
                    exportFile={`Output New Clients to Accounting ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientId', title: 'Client Id', hidden: true, export: false },
                        { field: 'CustomerID', title: 'Customer ID', width: 190 },
                        { field: 'CustomerName', title: 'Customer Name', width: 190 },
                        { field: 'BillToAddressLine1', title: 'Address Line 1', width: 240 },
                        { field: 'BillToAddressLine2', title: 'Address Line 2', width: 190 },
                        { field: 'BillToCity', title: 'City', width: 190 },
                        { field: 'BillToState', title: 'State', width: 190 },
                        { field: 'BillToZip', title: 'Zip', width: 190 },
                        { field: 'CustomerType', title: 'Customer Type', width: 190 },
                        { field: 'Telephone', title: 'Telephone', width: 190 },
                        { field: 'GLSalesAccount', title: 'GL Account', width: 190 },
                        { field: 'AdmitDate', title: 'Admit Date', width: 190, type: 'date' },
                        { field: 'MANumber', title: 'MA Number', width: 190 },
                        { field: 'SSNumber', title: 'SS Number', width: 190 },
                        { field: 'DateOfBirth', title: 'Date of Birth', width: 190, type: 'date' },                        
                    ]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default OutputNewClients
