import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Breadcrumbs, Container, Paper, Typography, TextField, Grid } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import URI from 'urijs';
import { useDebounce } from 'components/Debounce';
import { DateTime } from 'luxon';
import ManagerPicker from 'components/Pickers/ManagerPicker';
import ClientPicker from 'components/Pickers/ClientPicker';
import CaseManagerList from 'components/Reports/CaseManagerList';
import ClientList from 'components/Reports/ClientList';
import { ClientNoteReportModel } from 'models/ClientNoteReprtModel';


const ClientNotesPrint = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<ClientNoteReportModel>();

    const [caseManagers, setCaseManagers] = useState<any[]>([]);
    const [clients, setClients] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>(DateTime.local().minus({ months: 1 }).toISODate());
    const [endDate, setEndDate] = useState<string>(DateTime.local().toISODate());
    const [minimumUnits, setMinimumUnits] = useState<string>('');
    const [maximumUnits, setMaximumUnits] = useState<string>('');

    const delayedStartDate = useDebounce(startDate, 500);
    const delayedEndDate = useDebounce(endDate, 500);

    const firstLoad = useRef(true);

    // load data
    const loadData = useCallback((caseManagerIds: number[], clientIds: number[], startDate: string, endDate: string, minimumUnits: number | null, maximumUnits: number | null) => {
        const url = URI("/Report/ClientNotesPrint")
            .setSearch({
                caseManagerIds,
                clientIds,
                startDate,
                endDate,
                minimumUnits,
                maximumUnits
            });

        loadAction(
            url.toString(),
            'Client Notes',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        // prevent double loading of list
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        if (!DateTime.fromISO(delayedStartDate).isValid
            || !DateTime.fromISO(delayedEndDate).isValid
        ) {
            setTimeout(() => setData(undefined), 0);
            return;
        }

        const caseManagerIds = caseManagers ? caseManagers.map(m => m.id) : [];
        const clientIds =  clients ? clients.map(m => m.id) : [];
        const min = minimumUnits ? parseFloat(minimumUnits) : null;
        const max = maximumUnits ? parseFloat(maximumUnits) : null;
        loadData(caseManagerIds, clientIds, delayedStartDate, delayedEndDate, min, max);
    }, [loadData, caseManagers, clients, delayedStartDate, delayedEndDate, minimumUnits, maximumUnits]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs className={"hide-print"} aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Client Notes Printable</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper + " hide-print"}>
                <SectionHeader title="Client Notes Printable">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Printable Client Notes Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard hide-print">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={2}
                    >
                        <Grid item xs={2}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ClientPicker
                                selected={clients}
                                setSelected={setClients}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="startDate"
                                label="Start Date"
                                type='date'
                                fullWidth
                                value={startDate}
                                onChange={event => setStartDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="endDate"
                                label="End Date"
                                type='date'
                                fullWidth
                                value={endDate}
                                onChange={event => setEndDate(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="minimumUnits"
                                label="Minimum Units"
                                type='number'
                                fullWidth
                                value={minimumUnits}
                                onChange={event => setMinimumUnits(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                name="maximumUnits"
                                label="Maximum Units"
                                type='number'
                                fullWidth
                                value={maximumUnits}
                                onChange={event => setMaximumUnits(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </fieldset>


            </Paper>
            <div>
                    {
                    data === undefined || (data.CaseManagerCount === 0 && data.ClientCount === 0) ? "" 
                    : data.CaseManagerCount > 0 ? 
                    <CaseManagerList data={data.CaseManagers} startDate={startDate} endDate={endDate}/> :
                    data.ClientCount > 0 ? 
                    <ClientList data={data.Clients} startDate={startDate} endDate={endDate} totalUnits={data.TotalUnits} totalBillableUnits={data.TotalBillableUnits} totalNonBillableUnits={data.TotalNonBillableUnits}/>
                    : ""
                    }
            </div>

        </Container>
    )
}

export default ClientNotesPrint
