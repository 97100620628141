import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Button,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions'
import { AbilityContext, Can } from 'components/AuthorizationProvider';

const ManagerList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "CaseManager")

    const loadManagers = useCallback(() => {
        loadAction(
            '/CaseManager',
            'case managers',
            setData
        );
    }, [loadAction]);

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/CaseManager/${data.id}`,
            `case manager '${data.userName}'`,
            loadManagers
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/administration/managers/${data.id}`)
    };

    useEffect(() => {
        loadManagers();
    }, [loadManagers]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <Typography color="textPrimary">Managers</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Case Managers">
                    <Can do="create" on="CaseManager">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/administration/managers/create')}
                        >
                            Add Manager
                        </Button>
                    </Can>
                </SectionHeader>
                <SectionTitle
                    title="Case Manager List"
                    icon={faUserFriends}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile="Case Managers"
                    columns={[
                        { field: 'id', title: 'Id', hidden: true, export: true, type: 'numeric' },

                        { field: 'employeeNumber', title: 'Employee', width: 150 },
                        { field: 'userName', title: 'User Name', hidden: true },

                        { field: 'givenName', title: 'First' },
                        { field: 'middleName', title: 'Middle', width: 150, hidden: true, export: true },
                        { field: 'familyName', title: 'Last', defaultSort: 'asc' },

                        { field: 'emailAddress', title: 'Email' },
                        { field: 'businessPhone', title: 'Business Phone', width: 190 },
                        { field: 'homePhone', title: 'Home Phone', hidden: true, export: true },
                        { field: 'mobilePhone', title: 'Mobile Phone', width: 190 },
                        { field: 'faxNumber', title: 'Fax Number', hidden: true, export: true },

                        { field: 'isActive', title: 'Active', width: 120, type: 'boolean' },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },

                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete,
                            hidden: !allowDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ManagerList
