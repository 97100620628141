import { faBook } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormLabel, Grid, Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import TimeSheetForm from './TimeSheetForm';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { TimeEntryType, TimeSheetType } from 'models/TimeSheetModel';
import { BlockRounded, CheckCircleRounded } from '@material-ui/icons';
import { useAuthorization } from 'components/AuthorizationProvider';

interface Props {
    timeSheetId: string
    timeSheet?: TimeSheetType
    timeEntries?: TimeEntryType[],
    isSupervisor?: boolean,
    isAdmin?: boolean
    isReadOnlyAdmin?: boolean
}

const TimeSheetDetail = ({ timeSheetId, timeSheet = undefined, timeEntries = undefined, isSupervisor = false, isAdmin = false, isReadOnlyAdmin = false }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const { createAction, updateAction } = useFormActions();
    const { handleSubmit, formState, errors } = form;
    const progress = useProgress();
    const userInfo = useAuthorization();
    const isOwner = userInfo.caseManagerId === Number(timeSheet?.caseManagerId ?? 0)
    const disabled = (timeSheet?.submitted === '1' && !isAdmin) || ((isReadOnlyAdmin && isSupervisor) || (!isAdmin && !isOwner))
    const [submitValidation, setSubmitValidation] = useState<boolean>(false)
    const validationAcknowledged = useRef<{ acknowledged: boolean, formData: any }>({ acknowledged: false, formData: {} })

    const handleSubmitClose = () => {
        setSubmitValidation(false)
    }

    const handleSubmitProceed = () => {
        setSubmitValidation(false)
        validationAcknowledged.current = { ...validationAcknowledged.current, acknowledged: true }

        editTimeSheet(validationAcknowledged.current.formData, false, true)
    }

    const editTimeSheet = (formData: any, onEdit: boolean = false, onSubmit: boolean = false) => {
        const formKeys = Object.keys(formData);
        const adminSubmit = onSubmit && isAdmin
        const totalHourKeys = formKeys.filter(x => x.includes('totalHours'))
        const entryLength = totalHourKeys.length
        let totalHours = 0
        for (let i = 0; i < totalHourKeys.length; i++) {
            const hours = formData[totalHourKeys[i]]
            totalHours += Number(hours)
        }

        if (totalHours < 80 && onSubmit && !validationAcknowledged.current.acknowledged) {
            setSubmitValidation(true)
        } else {
            let TimeSheetData = {
                ...timeSheet,
                employeeSignature: formData.employeeSignature,
                signatureTimestamp: formData.signatureTimestamp,
                comments: formData.comments,
                submitted: adminSubmit ? 1 : onEdit ? 0 : 1,
                status: adminSubmit ? 'CLOSED' : onEdit ? 'OPEN' : 'PENDING APPROVAL',
                notifyCaseManager: ''
            }

            let entryArray = []
            for (var i = 0; i < entryLength; i++) {
                entryArray.push({
                    "timeSheetId": Number(timeSheetId),
                    "id": timeEntries?.[i]?.id ?? 0,
                    "caseManagerId": timeEntries?.[0]?.caseManagerId ?? 0,
                    "employeeNumber": timeEntries?.[0]?.employeeNumber ?? 0
                } as TimeEntryType)
            }
            for (var i = 0; i < formKeys.length; i++) {
                let entryKey = formKeys[i];
                if (entryKey.includes("-")) {
                    let valueSplit = formKeys[i].split("-");
                    let tempObj = entryArray[Number(valueSplit[1])];
                    const formRow = formData[entryKey];
                    const keyName = valueSplit[0];
                    const newEntry = { [keyName]: formRow };
                    Object.assign(tempObj, newEntry);
                }
            }

            const updateEntries = entryArray.filter(x => x.id !== 0)
            const createEntries = entryArray.filter(x => x.id === 0)

            createEntries.forEach((element) => {
                // Allow saving of time entries with no time out record
                // if (element.totalHours !== "0") {
                    createAction(
                        '/TimeEntry',
                        element,
                        '',
                        () => { }
                    );
                // }
            });

            updateEntries.forEach((element, index) => {
                if (element && element.id) {
                    updateAction(
                        `/TimeEntry/${element.id}`,
                        element,
                        '',
                        () => {
                            if (index === entryArray.length - 1) {
                                if (onEdit) {
                                    history.push(`/TimeSheet/edit/${timeSheetId}`)
                                } else
                                    history.push(`/TimeSheet`)

                                updateAction(
                                    `/TimeSheet/${timeSheetId}`,
                                    TimeSheetData,
                                    '',
                                    () => { }
                                )
                            }
                        }
                    );
                }
            });
        }
    }

    const manageTimeSheet = (approved: boolean = false) => {
        let TimeSheetData = {
            ...timeSheet,
            submitted: approved ? 1 : 0,
            status: approved ? 'CLOSED' : 'OPEN',
            employeeSignature: approved ? timeSheet?.employeeSignature : '',
            signatureTimestamp: approved ? timeSheet?.signatureTimestamp : '',
            notifyCaseManager: approved ? 'Approved' : 'Denied'
        }

        updateAction(
            `/TimeSheet/${timeSheetId}`,
            TimeSheetData,
            '',
            () => {
                history.push(`/TimeSheet`)
            }
        );
    }

    const saveTimeSheet = () => {
        editTimeSheet(form.getValues(), true, false)
    }

    const submitTimeSheet = (formData: any) => {
        validationAcknowledged.current = { ...validationAcknowledged.current, formData: formData }

        editTimeSheet(formData, false, true)
    }

    const isSupe = isSupervisor || isAdmin
    const showApprovalButtons = timeSheet &&
        timeSheet.submitted === '1' &&
        timeSheet.status === 'PENDING APPROVAL' &&
        isSupe

    return (
        <Paper className={classes.paper}>
            <Dialog
                open={submitValidation}
                onClose={handleSubmitClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your time sheet does not add up to 80 total hours for the period. Are you sure you would like to submit for approval?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSubmitClose}>No</Button>
                    <Button onClick={handleSubmitProceed} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <FormHeader title="TimeSheet Details">
                <p>* = Required Field</p>
            </FormHeader>
            <SectionTitle
                title="TimeSheet"
                icon={faBook}
                color={theme.palette.text.primary}
            />
            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(submitTimeSheet)} noValidate>
                        <TimeSheetForm
                            TimeSheetID={timeSheetId}
                            TimeSheet={timeSheet}
                            TimeEntries={timeEntries}
                            disabled={disabled}
                            isSupe={isSupe}
                            isAdmin={isAdmin}
                        />
                        <FormButtons
                            dirty={formState.isDirty}
                            cancelLink="/TimeSheet"
                            saveLabel="Save TimeSheet"
                            submitLabel="Submit TimeSheet"
                            saveTimeSheet={saveTimeSheet}
                            disabled={disabled || Boolean(Object.keys(errors).length)}
                        />
                        {showApprovalButtons &&
                            <Grid
                                style={{
                                    marginTop: "5px",
                                    marginBottom: "5px"
                                }}
                                container
                                justifyContent="flex-end"
                                spacing={3}
                            >
                                <Grid item>
                                    <FormLabel>{timeSheet?.status}</FormLabel>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size='large'
                                        onClick={() => manageTimeSheet(true)}
                                    >
                                        <FormLabel>Approve</FormLabel>
                                        <CheckCircleRounded />
                                    </Button>
                                </Grid>

                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size='large'
                                        onClick={() => manageTimeSheet(false)}
                                    >
                                        <FormLabel>Decline</FormLabel>
                                        <BlockRounded />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default TimeSheetDetail
