import React, { useState, useCallback, useEffect } from 'react'
import { Box, Breadcrumbs, Button, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { useAuthorization } from "components/AuthorizationProvider";
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';


const BpcAssessmentPaperwork = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction, postAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const [selection, setSelection] = useState<any[] | undefined>();
    const userInfo = useAuthorization();
    const history = useHistory();

    const loadData = useCallback(() => {
        if (!userInfo || !userInfo.caseManagerId) return;

        loadAction(
            `/Notification/BpcAssessmentPaperwork?caseManagerId=${userInfo.caseManagerId}`,
            'BPC Assessment Paperwork',
            setData
        );
    }, [loadAction, userInfo])

    const handleEdit = (event: any, data: any) => {
        if (!data.ClientId) return;

        history.push(`/clients/${data.ClientId}`)
    };

    const handleSelectionChange = (data: any[], rowData?: any) => {
        setSelection(data);
    };

    const markCompleted = () => {
        if (!selection?.length) return

        const ids: any[] = selection.map((s: any) => s.ServiceAgreementId);
        const data = { ids };

        postAction(
            '/serviceAgreement/markCompleted', data,
            'service agreements',
            () => {
                setSelection([]);
                loadData();
            }
        );
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">BPC Assessment Paperwork</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="BPC Assessment Paperwork">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="BPC Assessment Paperwork Notification"
                    icon={faBell}
                    color={theme.palette.text.primary}
                />

                <Box padding="5px">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!Boolean(selection?.length)}
                        onClick={markCompleted}
                    >
                        Mark Completed
                    </Button>
                </Box>
                
                <DataGrid
                    exportFile={`BPC Assessment Paperwork ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name', defaultSort: 'asc' },
                        { field: 'ServiceAgreementNumber', title: 'Service Agreement Number', width: 250 },
                        { field: 'ServiceName', title: 'Service', width: 250 },
                        { field: 'StartDate', title: 'Agreement Start', width: 180, type: 'date' },
                        { field: 'EndDate', title: 'Agreement End', width: 180, type: 'date' },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit,
                            position: 'row'
                        }
                    ]}
                    options={{
                        selection: true
                    }}
                    onSelectionChange={handleSelectionChange}
                />
            </Paper>
        </Container>
    )
}

export default BpcAssessmentPaperwork
