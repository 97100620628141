import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import EligibilityForm from './EligibilityForm';
import { EligibilitySchema } from 'models/Schemas';


interface Props {
    clientId: string
}

const EligibilityCreate = ({ clientId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();

    const saveEligibility = (formData: any) => {
        formData.clientId = clientId;
        const data = formToData(formData, EligibilitySchema);

        createAction(
            '/ClientEligibility', data,
            'Client Eligibility',
            () => history.push(`/clients/${clientId}/eligibility`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Eligibility">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveEligibility)} noValidate autoComplete="off">
                    <EligibilityForm />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/eligibility`}
                        submitLabel="Save Eligibility"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default EligibilityCreate
