import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ServiceList from './ServiceList';
import ServiceCreate from './ServiceCreate';
import ServiceEdit from './ServiceEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ServiceRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Service">
                <ServiceCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:serviceId`} action="read" subject="Service">
                <ServiceEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="Service">
                <ServiceList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ServiceRoute
