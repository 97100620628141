import React, { useState, useCallback, useEffect } from 'react'
import { Box, Breadcrumbs, Button, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import { useAuthorization } from 'components/AuthorizationProvider';
import { useHistory } from 'react-router-dom';

interface Props {

}

const QuarterlyAddress = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction, postAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const [selection, setSelection] = useState<any[] | undefined>();
    const userInfo = useAuthorization();
    const history = useHistory();

    const loadData = useCallback(() => {
        if (!userInfo || !userInfo.caseManagerId) return;

        loadAction(
            `/Notification/QuarterlyAddress?caseManagerId=${userInfo.caseManagerId}`,
            'Quarterly Address',
            setData
        );
    }, [loadAction, userInfo])

    const handleEdit = (event: any, data: any) => {
        if (!data.ClientId) return;

        history.push(`/clients/${data.ClientId}`)
    };

    const handleSelectionChange = (data: any[], rowData?: any) => {
        setSelection(data);
    };

    const markVerified = () => {
        if (!selection?.length) return

        const ids: any[] = selection.map((s: any) => s.ClientId);
        const data = { ids };

        postAction(
            '/client/markVerified', data,
            'clients',
            () => {
                setSelection([]);
                loadData();
            }
        );
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Quarterly Address</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Quarterly Address">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Quarterly Address Reminder"
                    icon={faBell}
                    color={theme.palette.text.primary}
                />

                <Box padding="5px">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!Boolean(selection?.length)}
                        onClick={markVerified}
                    >
                        Mark Verified
                    </Button>
                </Box>

                <DataGrid
                    exportFile={`Quarterly Address ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name', defaultSort: 'asc' },
                        { field: 'AddressLine1', title: 'Address' },
                        { field: 'City', title: 'City' },
                        { field: 'StateProvince', title: 'State', width: 140 },
                        { field: 'PostalCode', title: 'Zip', width: 140 },
                        { field: 'LastVerification', title: 'Last Verification', width: 200, type: 'date' },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit,
                            position: 'row'
                        }
                    ]}
                    options={{
                        selection: true
                    }}
                    onSelectionChange={handleSelectionChange}
                />
            </Paper>
        </Container>
    )
}

export default QuarterlyAddress
