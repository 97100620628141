import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import CaseSelect from 'components/Form/CaseSelect';
import ServiceSelect from 'components/Form/ServiceSelect';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Dropdown from 'components/Form/Dropdown';

interface Props {
    disabled?: boolean;
    clientId: string;
    multipleServices?: boolean,
    creating?: boolean;
}

const ClientAgreementForm = ({ clientId, disabled = false, multipleServices = false, creating = false }: Props) => {
    const { errors, register, getValues } = useFormContext();
    const theme = useTheme();

    const [endDate, setEndDate] = useState<string | any>();
    const [startDate, setStartDate] = useState<string | any>();
    const [autoRenew, setAutoRenew] = useState(false);

    useEffect(() => {
        if(getValues("autoRenew") !== undefined){
            if(creating)
                setAutoRenew(true)
            else
                setAutoRenew(getValues("autoRenew"));
        }

    })

    const updateCheckState = (evt: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    {
        setAutoRenew(checked);
    }

    return (
        <React.Fragment>

            <SectionTitle
                title="Service Agreement"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={2}>
                    <TextField
                        disabled={disabled}
                        name="serviceAgreementNumber"
                        label="Service Agreement Number"
                        fullWidth
                        error={Boolean(errors.serviceAgreementNumber)}
                        helperText={errors.serviceAgreementNumber && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        disabled={disabled}
                        name="lineNumber"
                        label="Line Number"
                        fullWidth
                        error={Boolean(errors.lineNumber)}
                        helperText={errors.lineNumber && 'This field is required'}
                        inputRef={register()}
                        inputProps={{
                            min: '0'
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CaseSelect
                        disabled={disabled}
                        name="caseId"
                        label="Case"
                        required={true}
                        clientId={clientId}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ServiceSelect
                        disabled={disabled}
                        name="serviceId"
                        label="Service"
                        required={true}
                        multiple={multipleServices}
                    />
                </Grid>


                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="startDate"
                        label="Start Date"
                        required
                        fullWidth
                        type="date"
                        value={startDate}
                        error={Boolean(errors.startDate)}
                        helperText={errors.startDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="endDate"
                        label="End Date"
                        fullWidth
                        type="date"
                        value={endDate}
                        error={Boolean(errors.endDate)}
                        helperText={errors.endDate && (errors.endDate.message || 'This field is required')}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="unitsAllocated"
                        label="Units Allocated"
                        required
                        fullWidth
                        type="number"
                        error={Boolean(errors.unitsAllocated)}
                        helperText={errors.unitsAllocated && errors.unitsAllocated.message}
                        inputRef={register({
                            required: 'This field is required',
                            min: {
                                value: 1,
                                message: 'Units must be greater than zero'
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Dropdown
                        disabled={disabled}
                        name="isArchived"
                        label="Archived"
                        required={false}
                        defaultValue="false"
                        items={[
                            { name: 'Yes', value: 'true' },
                            { name: 'No', value: 'false' }
                        ]}
                    />
                </Grid>
                <Grid item xs={4}>
                <FormControlLabel
                    name="autoRenew"
                    control={<Checkbox checked={autoRenew} onChange={updateCheckState} />}
                    label="Auto Renew"
                    labelPlacement="start"
                    inputRef={register()}
                />
            </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default ClientAgreementForm
