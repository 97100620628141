import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        gridHeader: {
            marginBottom: "10px"
        },
        sectionHeader: {
            color: theme.palette.primary.main,
        }        
    }),
);

interface Props {
    title: string;
    children?: JSX.Element;
    middle?: JSX.Element;
}

const FormHeader = (props: Props) => {
    const classes = useStyles(props);

    return (
        <Grid
            className={classes.gridHeader}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            <Grid item>
                <Typography
                    className={classes.sectionHeader}
                    component="h1"
                    variant="h5"
                    gutterBottom>
                    {props.title}
                </Typography>
            </Grid>
            {props.middle && <Grid item>{props.middle}</Grid>}
            <Grid item>
                {props.children}
            </Grid>
        </Grid>
    )
}

export default FormHeader
