import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import CodeList from './CodeList';
import CodeCreate from './CodeCreate';
import CodeEdit from './CodeEdit';
import CodeImport from './CodeImport';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const CodeRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="MedicalCode">
                <CodeCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/import`} action="update" subject="MedicalCode">
                <CodeImport />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:codeId`} action="update" subject="MedicalCode">
                <CodeEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="MedicalCode">
                <CodeList />
            </ProtectedRoute>
        </Switch>
    )
}

export default CodeRoute
