import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ManagerList from './ManagerList';
import ManagerCreate from './ManagerCreate';
import ManagerEdit from './ManagerEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ManagerRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="CaseManager">
                <ManagerCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:managerId`} action="read" subject="CaseManager">
                <ManagerEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="CaseManager">
                <ManagerList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ManagerRoute
