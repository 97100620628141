import React, { useContext } from 'react'
import { Box } from '@material-ui/core';
import { ConfigurationContext } from 'components/Configuration'
import { DateTime } from 'luxon';

interface Props {

}

const Footer = (props: Props) => {
    const configuration = useContext(ConfigurationContext);
    const year = DateTime.local().year;

    return (
        <Box
            className="footer hide-print"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center">
                <Box>{configuration.environment}</Box>
                <Box>Copyright © {year} Bristol Place Corporation. All Rights Reserved.</Box>
                <Box>v{configuration.version}</Box>
        </Box>
    )
}

export default Footer
