import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientAgreementForm from './ClientAgreementForm';
import { AgreementMultipleSchema } from 'models/Schemas';
import { v4 as uuidv4 } from 'uuid';


interface Props {
    clientId: string
}

const ClientAgreementCreate = ({ clientId }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm({
        defaultValues: {}
    });
    const { createAction } = useFormActions();

    const saveServiceAgreement = (formData: any) => {
        formData.clientId = clientId;
        const data = formToData(formData, AgreementMultipleSchema);

        createAction(
            '/ServiceAgreement/create/multiple', data,
            `service agreement '${data.serviceAgreementNumber}'`,
            () => history.push(`/clients/${clientId}/agreements`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Agreement">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveServiceAgreement)} noValidate autoComplete="off">
                    <ClientAgreementForm clientId={clientId} multipleServices={true} creating={true} />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/agreements`}
                        submitLabel="Save Agreement"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ClientAgreementCreate
