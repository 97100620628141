import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import CompanyForm from './CompanyForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';

const CompanyCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveCompany = (data: any) => {
        actions.createAction(
            '/Company', data,
            `Company '${data.id}'`,
            () => history.push('/administration/companies')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/companies">Companies</LinkRouter>
                <Typography color="textPrimary">Add Company</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Add Company">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Company Information"
                    icon={faBuilding}
                    color={theme.palette.text.primary}
                />
                <FormProvider  {...form}>
                    <form onSubmit={form.handleSubmit(saveCompany)} noValidate>
                        <CompanyForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/administration/companies"
                            submitLabel="Save Company"
                        />
                    </form>
                </FormProvider >
            </Paper>
        </Container>
    )
}

export default CompanyCreate
