import {
    createStyles,
    makeStyles,
    Theme
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            color: theme.palette.text.secondary,
            maxWidth: '100%'
        },
        sectionTitle: {
            color: theme.palette.text.primary,
            borderBottomStyle: "solid",
            borderBottomWidth: "2px",
            borderBottomColor: theme.palette.text.primary
        },
        sectionIcon: {
            marginRight: "10px"
        },
        mappingTable: {
            width: 800,
            margin: "auto"
        },
        cellHeader: {
            fontWeight: 600
        },
        cellError: {
            color: theme.palette.error.main
        },
        cellWarning: {
            color: theme.palette.warning.main
        },
        toolbarSelector: {
            marginRight: theme.spacing(2),
            minWidth: '200px'
        },
        table: {
            minWidth: 650,
        },
        spacer: {
            flex: '1 1 10%'
        },
        actions: {
            color: theme.palette.text.secondary,
        },
        title: {
            overflow: 'hidden'
        },
        formControlLabel: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        clearSpacer: {
            flex: 0
        },
        toolbarWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        }
    }),
);

export const selectStyles = {
    container: () => ({
        marginTop: '16px',
    }),
    control: (provided: any, { isFocused }: any) => ({
        ...provided,
        border: 0,
        borderRadius: 0,
        borderBottom: isFocused ? '2px solid #3f51b5' : '1px solid rgba(0, 0, 0, 0.42)',
        boxShadow: null,
        minHeight: 32,
        '&:hover': {
            borderColor: null,
            borderBottom: '2px solid rgba(0, 0, 0, 0.87)'
        }
    }),
    valueContainer: (provided: any, state: any) => ({
        ...provided,
        padding: 0
    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        maxWidth: 200
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        width: 0
    }),
    clearIndicator: (provided: any, state: any) => ({
        ...provided,
        padding: 4,
        cursor: 'pointer'
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        padding: 4,
        cursor: 'pointer'
    }),
    loadingIndicator: (provided: any, state: any) => ({
        ...provided,
        padding: 4,
        cursor: 'pointer'
    }),
    option: (provided: any, { isSelected, isFocused, isDisabled }: any) => ({
        ...provided,
        backgroundColor: isSelected
            ? '#EBEBEB'
            : isFocused
                ? '#F5F5F5'
                : 'transparent',
        color: 'inherit',
        ':active': {
            backgroundColor: !isDisabled && (isSelected ? '#EBEBEB' : '#F5F5F5'),
        },
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        zIndex: 999
    })
}

export const selectTheme = {
    color: {
        iconSeparator: 'transparent'
    },
    icon: {
        padding: '0 0.4rem'
    },
    control: {
        borderWidth: '0',
        borderStyle: 'none',
        borderRadius: '0',
        padding: '0.3rem 0',
        css: 'margin-top: 10px; border-bottom: 1px solid rgba(0, 0, 0, 0.42);box-shadow: none;',
        focusedCss: 'border-bottom: 2px solid #3f51b5;'
    },
    multiValue: {
        borderRadius: '2px',
        backgroundColor: 'rgb(230, 230, 230)',
        css: 'max-width: 300px;',
        label: {
            borderRadius: '0',
            fontSize: '0.85em'
        },
        clear: {
            borderRadius: '0'
        }
    }
}