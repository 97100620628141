import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Paper,
} from '@material-ui/core';
import { Can, AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientAgreementList = ({ clientId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "ServiceAgreement")

    const loadServiceAgreements = useCallback(() => {
        loadAction(
            `/Client/${clientId}/agreement`,
            'service agreements',
            setData
        );
    }, [loadAction, clientId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/ServiceAgreement/${data.id}`,
            `service agreement '${data.serviceAgreementNumber}'`,
            loadServiceAgreements
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/clients/${clientId}/agreements/${data.id}`)
    };

    useEffect(() => {
        loadServiceAgreements();
    }, [loadServiceAgreements]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Client Service Agreements">
                <Can do="create" on="ServiceAgreement">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/clients/${clientId}/agreements/create`)}
                    >
                        Add Agreement
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Service Agreements"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="ServiceAgreements"
                columns={[
                    { field: 'id', title: 'Id', hidden: true, export: true },

                    { field: 'serviceAgreementNumber', title: 'Agreement Number', width: 200 },
                    { field: 'lineNumber', title: 'Line Number', width: 150, type: 'numeric' },
                    { field: 'subscriberId', title: 'Subscriber ID', width: 150, type: 'numeric' },

                    { field: 'clientFamilyName', title: 'Last', hidden: true, export: true },
                    { field: 'clientGivenName', title: 'First', hidden: true, export: true },
                    { field: 'clientEmail', title: 'Email', hidden: true, export: true },

                    { field: 'caseManagerFamilyName', title: 'Manager Last', hidden: true, export: true },
                    { field: 'caseManagerGivenName', title: 'Manager First', hidden: true, export: true },
                    { field: 'serviceId', title: 'Service Id', hidden: true, export: true },
                    { field: 'procedureCode', title: 'Procedure', hidden: true, export: true },
                    { field: 'serviceName', title: 'Service Name', cellStyle: { minWidth: 150 } },

                    { field: 'startDate', title: 'Start', width: 160, type: 'date', defaultSort: 'desc' },
                    { field: 'endDate', title: 'End', width: 160, type: 'date' },

                    { field: 'unitsAllocated', title: 'Units Allocated', width: 180, type: 'numeric' },
                    {
                        field: 'billableUnitsUsed',
                        title: 'Billable Units',
                        width: 180,
                        type: 'numeric',
                        cellStyle: (data: any[], rowData: any) => ({
                            color: rowData.billableUnitsUsed >= rowData.unitsAllocated ? theme.palette.error.main : 'inherit'
                        })
                    },

                    { field: 'isArchived', title: 'Archived', width: 140, type: 'boolean' },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                ]}
                data={data}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default ClientAgreementList
