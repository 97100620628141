export const ClientSchema = {
    id: 'number',
    birthDate: 'date',
    isInterpreterRequired: 'boolean',
    countyId: 'number',

    // with services data
    transferDate: 'date',
    waiverSpanStartDate: 'date',
    waiverSpanEndDate: 'date',
    exitDate: 'date',
    caseManagerId: 'number',
    primaryDiagnosisId: 'number',

    startDate: 'date',
    endDate: 'date',
    unitsAllocated: 'number',
    isActive: 'boolean',
};

export const CaseSchema = {
    id: 'number',
    clientId: 'number',
    caseManagerId: 'number',
    primaryDiagnosisId: 'number',
    transferDate: 'date',
    waiverSpanStartDate: 'date',
    waiverSpanEndDate: 'date',
    exitDate: 'date',
    isArchived: 'boolean'
};

export const AgreementSchema = {
    id: 'number',
    caseId: 'number',
    serviceId: 'number',
    startDate: 'date',
    endDate: 'date',
    unitsAllocated: 'number',
    isArchived: 'boolean'
};

export const AgreementMultipleSchema = {
    id: 'number',
    caseId: 'number',
    startDate: 'date',
    endDate: 'date',
    unitsAllocated: 'number',
    isArchived: 'boolean'
};

export const ReallocateSchema = {
    serviceAgreementId: 'number',
    originalEndDate: 'date',
    originalUnitsAllocated: 'number',
    newStartDate: 'date',
    newEndDate: 'date',
    newUnitsAllocated: 'number',
    facilityStayAdmittedDate: 'date',
    facilityStayDischargedDate: 'date'
};

export const ContactSchema = {
    id: 'number',
    clientId: 'number',
    contactTypeId: 'number'
};

export const PayerSchema = {
    id: 'number',
    clientId: 'number',
    payerTypeId: 'number'
};

export const ServiceSchema = {
    id: 'number',
    unitRate: 'number',
    modifier1Id: 'number',
    modifier2Id: 'number',
    modifier3Id: 'number',
    modifier4Id: 'number',
};

export const NoteSchema = {
    id: 'number',
    serviceDate: 'date',
    billingLockDate: 'date',
    billedDate: 'date',
    unitsUsed: 'number',
    serviceAgreementId: 'number',
    caseManagerId: 'number',
    isBillingDelayed: 'boolean',
    billingDelayedReasonId: 'number'
};

export const ActivityTypeSchema = {
    id: 'number',
    displayOrder: 'number',
    isBillable: 'boolean',
    isActive: 'boolean'
}

export const LanguageSchema = {
    id: 'number',
    displayOrder: 'number',
}

export const ContactTypeSchema = {
    id: 'number',
    displayOrder: 'number',
}

export const EligibilitySchema = {
    id: 'number',
    clientId: 'number',
    billingDelayedReasonId: 'number',
    eligibilityYear: 'number',
    eligibilityMonth: 'number',
    eligibilityDate: 'date',
    planDate: 'date',
    planBeginDate: 'date',
    planEndDate: 'date',
    otherAdditionalCoverage1EffectiveDate: 'date',
    otherAdditionalCoverage1EndDate: 'date',
    otherAdditionalCoverage2EffectiveDate: 'date',
    otherAdditionalCoverage2EndDate: 'date',
    spenddownAmount: 'number',
    isBillingDelayed: 'boolean'
}

export const ServiceRateSchema = {
    id: 'number',
    serviceId: 'number',
    effectiveStart: 'date',
    effectiveEnd: 'date',
    unitRate: 'number'
}

export const FacilityStaySchema = {
    id: 'number',
    caseId: 'number',
    admitted: 'date',
    discharged: 'date'
}

export const CaseManagerSchema = {
    id: 'number',
    isSupervisor: 'boolean',
    isActive: 'boolean'
}

export const MedicalCodeSchema = {
    isActive: 'boolean'
}

export const ModifierSchema = {
    isActive: 'boolean'
}

export const TimeEntrySchema = {
    id: 'number',
    timeSheetId: 'number',
    entryDate: 'date',
    amTimeIn: 'date',
    amTimeOut: 'date',
    pmTimeIn: 'date',
    pmTimeOut: 'date',
    otherHours: 'number',
    totalHours: 'number'
}

export const TimeSheetSchema = {
    id: 'number',
    caseManagerId: 'number',
    payPeriodFrom: 'date',
    payPeriodTo: 'date',
    submitted: 'number'
}
