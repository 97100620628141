import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import CompanyList from './CompanyList';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const CompanyRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Company">
                <CompanyCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:companyId`} action="create" subject="Company">
                <CompanyEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="create" subject="Company">
                <CompanyList />
            </ProtectedRoute>
        </Switch>
    )
}

export default CompanyRoute
