import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import ManagerSelect from 'components/Form/ManagerSelect';
import DiagnosisSelect from 'components/Form/DiagnosisSelect'
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid, TextField } from '@material-ui/core';
import SecureTextField from 'components/Form/SecureTextField';

interface Props {
    disabled?: boolean;
    action?: string;
}

const ClientCaseForm = ({
    disabled = false,
    action = 'update'
}: Props) => {

    const form = useFormContext();
    const theme = useTheme();
    const { errors, register } = form;

    const subject = 'Case';

    return (
        <React.Fragment>

            <SectionTitle
                title="Client Case"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >

                <Grid item xs={2}>
                    <TextField
                        name="id"
                        label="Case Number"
                        fullWidth
                        error={Boolean(form.errors.caseNumber)}
                        helperText={form.errors.caseNumber && 'This field is required'}
                        inputRef={form.register()}
                        InputLabelProps={{
                            shrink: true,
                            className: 'Mui-disabled'
                        }}
                        InputProps={{
                            readOnly: true,
                            className: 'Mui-disabled'
                        }}

                    />
                </Grid>


                <Grid item xs={4}>
                    <DiagnosisSelect
                        action={action}
                        subject={subject}
                        name="primaryDiagnosisId"
                        label="Primary Diagnosis"
                        required={true}
                        multiselect={false}
                    />
                </Grid>

                <Grid item xs={6}>
                    <DiagnosisSelect
                        action={action}
                        subject={subject}
                        name="diagnoses"
                        label="Secondary Diagnosis"
                        required={false}
                        multiselect={true}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ManagerSelect
                        action={action}
                        subject={subject}
                        name="caseManagerId"
                        label="Case Manager"
                        required={true}
                    />

                </Grid>

                <Grid item xs={2}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="transferDate"
                        label="Transfer Date"
                        type="date"
                        required
                        fullWidth
                        error={Boolean(errors.transferDate)}
                        helperText={errors.transferDate && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="waiverSpanStartDate"
                        label="Waiver Span Start Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.waiverSpanStartDate)}
                        helperText={errors.waiverSpanStartDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="waiverSpanEndDate"
                        label="Waiver Span End Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.waiverSpanEndDate)}
                        helperText={errors.waiverSpanEndDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="exitDate"
                        label="Exit Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.exitDate)}
                        helperText={errors.exitDate && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="note"
                        label="Notes"
                        fullWidth
                        multiline
                        minRows="10"
                        error={Boolean(errors.note)}
                        helperText={errors.note && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientCaseForm
