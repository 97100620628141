import {
    AppBar,
    IconButton,
    Toolbar,
    Typography
} from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Menu as MenuIcon } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import SearchBar from './SearchBar';
import ProfilePopover from './ProfilePopover';
import NotificationPopover from './NotificationPopover';
import { ConfigurationContext } from 'components/Configuration'
import Switch from '@material-ui/core/Switch';
import { yellow } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const drawerWidth = 240;

const YellowSwitch = withStyles({
    switchBase: {
      color: yellow[300],
      '&$checked': {
        color: yellow[500],
      },
      '&$checked + $track': {
        backgroundColor: yellow[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        userName: {
            marginLeft: '10px'
        }
    }),
);

interface Props {
    isDrawerOpen: boolean
    handleDrawerOpen: ((event: any) => void) | undefined;
}

const ApplicationBar = (props: Props) => {
    const classes = useStyles();
    const configuration = useContext(ConfigurationContext);

    const [state, setState] = useState(true);
    
    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState(event.target.checked);
    };     

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.isDrawerOpen,
            }) + " hide-print"}
        >
            <Toolbar className={`env-${configuration.environment}`}>
                <IconButton
                    edge="start"
                    className={clsx(classes.menuButton, props.isDrawerOpen && classes.menuButtonHidden)}
                    color="inherit"
                    aria-label="menu"
                    onClick={props.handleDrawerOpen}>
                    <MenuIcon />
                </IconButton>

                <Typography variant="h6" className={classes.title}>
                    Bristol Place Case Management
                </Typography>
                <div className="env-text">
                    {configuration.environment}
                </div>
                <div>
                    <FormControlLabel
                        value="top"
                        control={
                            <YellowSwitch checked={state} onChange={handleSwitchChange} />
                        }
                        label="Display Only Active Clients"
                        labelPlacement="start"
                    />
                </div>
                <SearchBar clientType={state} />

                <NotificationPopover />

                <ProfilePopover />
            </Toolbar>
        </AppBar>
    )
}

export default ApplicationBar
