import {
    Paper,

} from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import EligibilityForm from './EligibilityForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { dataToForm, formToData } from 'services/DataTransform';
import { EligibilitySchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider'
import { AbilityContext } from 'components/AuthorizationProvider';
import { useParams } from 'react-router-dom';

interface Props {
    clientId: string
}

const EligibilityEdit = ({ clientId }: Props) => {
    const { eligibilityId } = useParams();
    const classes = useStyles();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "ClientEligibility")

    const loadEligibility = useCallback(() => {
        loadAction(
            `/ClientEligibility/${eligibilityId}`,
            'client',
            (data) => {
                const formData = dataToForm(data, EligibilitySchema);
                reset(formData);
            }
        );
    }, [loadAction, eligibilityId, reset]);

    const saveEligibility = (formData: any) => {
        if (!allowEdit) return;

        formData.clientId = clientId;
        const data = formToData(formData, EligibilitySchema);

        updateAction(
            `/ClientEligibility/${eligibilityId}`, data,
            'Client Eligibility',
            () => { }
        );
    }

    useEffect(() => {
        loadEligibility();
    }, [loadEligibility]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Client Eligibility">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveEligibility)} noValidate autoComplete="off">
                        <EligibilityForm disabled={!allowEdit} />
                        <FormButtons
                            disabled={!allowEdit}
                            dirty={formState.isDirty}
                            submitLabel="Save Eligibility"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default EligibilityEdit
