import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import TemplateList from './TemplateList';
import TemplateCreate from './TemplateCreate';
import TemplateEdit from './TemplateEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const TemplateRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Template">
                <TemplateCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:templateId`} action="create" subject="Template">
                <TemplateEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="create" subject="Template">
                <TemplateList />
            </ProtectedRoute>
        </Switch>
    )
}

export default TemplateRoute
