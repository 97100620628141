import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography,
    Button
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';

const CompanyList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const loadCompanies = useCallback(() => {
        loadAction(
            '/Company',
            'companies',
            setData
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/Company/${data.id}`,
            `company '${data.company}'`,
            loadCompanies
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/administration/companies/${data.id}`)
    };

    useEffect(() => {
        loadCompanies();
    }, [loadCompanies]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <Typography color="textPrimary">Companies</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Common Companies">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/administration/companies/create')}
                    >
                        Add Company
                    </Button>
                </SectionHeader>
                <SectionTitle
                    title="Company List"
                    icon={faBuilding}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile='Companies'
                    columns={[
                        { field: 'id', title: 'Id', hidden: true, export: true, type: 'numeric' },

                        { field: 'name', title: 'Name', defaultSort: 'asc' },
                        { field: 'addressLine1', title: 'Address', width: 150 },
                        { field: 'city', title: 'City', width: 150 },
                        { field: 'stateProvince', title: 'State', width: 120 },
                        { field: 'postalCode', title: 'Zip', width: 120 },

                        { field: 'businessPhone', title: 'Business Phone', width: 180 },
                        { field: 'homePhone', title: 'Home Phone', hidden: true, export: true },
                        { field: 'mobilePhone', title: 'Mobile Phone', width: 170 },
                        { field: 'faxNumber', title: 'Fax Number', width: 170 },
                        { field: 'emailAddress', title: 'Email', hidden: true, export: true },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },

                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default CompanyList
