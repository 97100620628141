import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ServiceForm from './ServiceForm';
import { dataToForm, formToData } from 'services/DataTransform';
import { ServiceSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    serviceId: string
}

const ServiceDetail = ({ serviceId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "Service")

    const loadService = useCallback(() => {
        loadAction(
            `/service/${serviceId}`,
            'service',
            (data) => {
                const formData = dataToForm(data, ServiceSchema);
                reset(formData);
            }
        );
    }, [loadAction, serviceId, reset]);

    const saveService = (formData: any) => {
        if (!allowEdit) return;

        const data = formToData(formData, ServiceSchema);

        updateAction(
            `/service/${serviceId}`, data,
            `service '${data.procedureCode}'`,
            () => {
                history.push('/services');
            }
        );
    }

    useEffect(() => {
        loadService();
    }, [loadService]);

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Service Details">
                <p>* = Required Field</p>
            </FormHeader>
            <SectionTitle
                title="Service"
                icon={faFileMedicalAlt}
                color={theme.palette.text.primary}
            />
            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveService)} noValidate>
                        <ServiceForm disabled={!allowEdit}/>
                        <FormButtons
                            dirty={formState.isDirty}
                            cancelLink="/services"
                            submitLabel="Save Service"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ServiceDetail
