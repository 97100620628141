import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    multiple?: boolean
    disabled?: boolean
}

const ServiceSelect = ({
    label = "Service",
    name = "serviceId",
    required = true,
    multiple = false,
    disabled = false,
}: Props) => {

    const form = useFormContext();
    const { errors, register, setValue } = form;
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()

    const formValue = useWatch<any>({name: name});

    // load data
    const { data: options } = useFetch('/Service?sort=procedureCode', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);
        // set form value
        if (selectedOption) {
            if (multiple) {
                const selectedValues = selectedOption.map((o: any) => o.id);
                setValue(name, selectedValues, {
                    shouldValidate: true,
                    shouldDirty: true
                });
            }
            else {
                setValue(name, selectedOption.id, {
                    shouldValidate: true,
                    shouldDirty: true
                });

            }
        }
        else {
            setValue(name, null, {
                shouldValidate: true,
                shouldDirty: true
            });
        }

    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options || !formValue) {
            return;
        }

        // find the selected options
        if (multiple) {
            const current: any[] = [];

            formValue.forEach((id: string) => {
                const selected = options.find((f: any) => f.id === Number(id));
                current.push(selected);
            });

            setSelected(current);
        } else {
            const current = options.find((f: any) => f.id === Number( formValue));
            setSelected(current);
        }

    }, [formValue, options, multiple])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required, min: 1 });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}                        
                styles={selectStyles}
                isClearable={true}
                isMulti={multiple}
                options={options}
                getOptionLabel={(option: any) => `${option.procedureCode} - ${option.name}`}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default ServiceSelect
