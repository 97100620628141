import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ClientNoteForm from './ClientNoteForm';
import { NoteSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext, useAuthorization } from 'components/AuthorizationProvider';
import { deleteStorageValue, useLocalStorage } from 'utils/useLocalStorage';
import { DateTime } from 'luxon';

interface Props {
    clientId: string
}
let showPrompt = false;

const ClientNoteEdit = ({ clientId }: Props) => {
    const { noteId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState, getValues, setError, clearErrors } = form;
    const progress = useProgress();
    const [locked, setLocked] = useState<boolean>(false);
    const [noteCaseManagerId, setNoteCaseManagerId] = useState<number>(0);

    const userInfo = useAuthorization();
    const ability = useContext(AbilityContext);
    const isOwnNote = userInfo.caseManagerId === Number(noteCaseManagerId);
    const isCaseManager = userInfo.allowCaseManager;
    const isPara = userInfo.allowParaprofessional;
    const isAdmin = userInfo.allowAdministrator;
    const allowEdit = ability.can("update", "ServiceNote")
    const allowOverride = ability.can("override", "ServiceNote")
    const allowed = (!locked || allowOverride || (allowEdit && !(isCaseManager || isPara)));
    const allowNote = isOwnNote || isAdmin;
    const denied = !allowed;

    const [unsavedNote, setUnsavedNote] = useLocalStorage(`note_${noteId}`, "");
    const location = useLocation();

    const loadNote = useCallback(() => {
        loadAction(
            `/ServiceNote/${noteId}`,
            'service note',
            (data) => {
                setLocked(data.isLocked);
                setNoteCaseManagerId(data.caseManagerId);
                const formData = dataToForm(data, NoteSchema);

                reset(formData);
            }
        );

    }, [loadAction, noteId, reset]);



    const noteText = (text: string) => {
        showPrompt = text !== "";
        setUnsavedNote(text);
    }

    const saveNote = (formData: any) => {
        if (denied && !allowNote) return;

        const { serviceAgreement } = getValues();

        const serviceDate = DateTime.fromISO(formData.serviceDate);
        const startDate = DateTime.fromISO(serviceAgreement.startDate);
        const endDate = DateTime.fromISO(serviceAgreement.endDate);

        if(serviceDate < startDate || serviceDate > endDate)
        {
            setError("serviceDate", {
                message: 'Service Date must be within Service Agreement dates',
                shouldFocus: true,
            });
            return;
        }else {
            clearErrors("serviceDate");
        }

        showPrompt =false;
        const data = formToData(formData, NoteSchema);
        deleteStorageValue(`note_${noteId}`);
        updateAction(
            `/ServiceNote/${noteId}`, data,
            `service note`,
            () => {
                history.push(`/clients/${clientId}/notes`);
            }
        );
    }

    useEffect(() => {
        loadNote();

        const unListen = history.listen(async () => {
           if(history.location.pathname !== location.pathname){
               deleteStorageValue(`note_${noteId}`);
           }

        });
        return unListen;
    }, [history, loadNote, location, noteId]);


    return (
        <Paper className={classes.paper}>
            <Prompt
            message={(location, action) => {
                return 'Are you sure you want to leave this page without saving?';
            }}
            when={showPrompt}
            />
            <FormHeader title="Service Note Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveNote)} noValidate autoComplete="off">
                        <ClientNoteForm
                            clientId={clientId}
                            action="update"
                            disabled={denied}
                            allowNote={allowNote}
                            noteText={noteText}
                            unsavedNote={unsavedNote}
                        />
                        <FormButtons
                            dirty={formState.isDirty}
                            disabled={denied && !allowNote}
                            cancelLink={`/clients/${clientId}/notes`}
                            submitLabel="Save Note"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientNoteEdit
