import React, { useState, useCallback, useEffect } from 'react'
import { Breadcrumbs, Container, Paper, Typography } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import { useAuthorization } from "components/AuthorizationProvider";
import { useHistory } from 'react-router-dom';

interface Props {

}

const NewClients = (props: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const userInfo = useAuthorization();
    const history = useHistory();

    const loadData = useCallback(() => {
        if (!userInfo || !userInfo.caseManagerId) return;

        loadAction(
            `/Notification/NewClients?caseManagerId=${userInfo.caseManagerId}`,
            'New Clients',
            setData
        );
    }, [loadAction, userInfo])

    const handleEdit = (event: any, data: any) => {
        if (!data.ClientId) return;

        history.push(`/clients/${data.ClientId}/notes`)
    };

    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">New Clients</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="New Clients">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="New Clients Notification"
                    icon={faBell}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile={`New Clients ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'ClientName', title: 'Client Name', cellStyle: { minWidth: 200 } },
                        { field: 'ServiceAgreementNumber', title: 'Service Agreement Number', width: 250 },
                        { field: 'ServiceName', title: 'Service', width: 250 },
                        { field: 'ActivityType', title: 'Missing Activity', width: 250 },
                        { field: 'TransferDate', title: 'Transfer Date', width: 200, type: 'date' },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'preview',
                            tooltip: 'View Item',
                            onClick: handleEdit
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default NewClients
