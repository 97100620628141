import {
    faProcedures,
    faUserFriends,
    faFileMedical,
    faMedkit,
    faGlobe,
    faAddressCard,
    faBuilding,
    faScroll
} from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Grid,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useContext } from 'react';
import DashboardSection from '../Dashboard/DashboardSection';
import { AbilityContext, Can } from 'components/AuthorizationProvider';


interface Props {

}

const AdministrationDashboard = (props: Props) => {
    const theme = useTheme();
    const ability = useContext(AbilityContext);

    const managerLinks = [
        { location: "/administration/managers/create", label: "Add New Case Manager", hide: ability.cannot("create", "CaseManager") },
        { location: "/administration/managers", label: "View/Edit Case Managers", hide: ability.cannot("read", "CaseManager") },
    ];

    const codeLinks = [
        { location: "/administration/codes/create", label: "Add New Medical Codes", hide: ability.cannot("create", "MedicalCode") },
        { location: "/administration/codes", label: "View/Edit Medical Codes", hide: ability.cannot("read", "MedicalCode") },
    ];

    const modifierLinks = [
        { location: "/administration/modifiers/create", label: "Add New Service Modifier", hide: ability.cannot("create", "ServiceModifier") },
        { location: "/administration/modifiers", label: "View/Edit Service Modifiers", hide: ability.cannot("read", "ServiceModifier") },
    ];

    const activityLinks = [
        { location: "/administration/activities/create", label: "Add New Activity Type", hide: ability.cannot("create", "ActivityType") },
        { location: "/administration/activities", label: "View/Edit Activity Type", hide: ability.cannot("read", "ActivityType") },
    ];

    const languageLinks = [
        { location: "/administration/languages/create", label: "Add New Language", hide: ability.cannot("create", "Language") },
        { location: "/administration/languages", label: "View/Edit Language", hide: ability.cannot("read", "Language") },
    ];

    const contactTypeLinks = [
        { location: "/administration/contactTypes/create", label: "Add New Contact Type", hide: ability.cannot("create", "ContactType") },
        { location: "/administration/contactTypes", label: "View/Edit Contact Type", hide: ability.cannot("read", "ContactType") },
    ];

    const companyLinks = [
        { location: "/administration/companies/create", label: "Add New Company", hide: ability.cannot("create", "Company") },
        { location: "/administration/companies", label: "View/Edit Company", hide: ability.cannot("read", "Company") },
    ];

    const templateLinks = [
        { location: "/administration/templates/create", label: "Add New Note Template", hide: ability.cannot("create", "NoteTemplate") },
        { location: "/administration/templates", label: "View/Edit Note Template", hide: ability.cannot("read", "NoteTemplate") },
    ];

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <Typography color="textPrimary">Administration</Typography>
            </Breadcrumbs>
            <Grid container spacing={3}>
                <Can do="read" on="CaseManager">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Case Managers"
                            icon={faUserFriends}
                            color={theme.palette.primary.main}
                            links={managerLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="MedicalCode">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Medical Codes"
                            icon={faProcedures}
                            color={theme.palette.primary.main}
                            links={codeLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="ServiceModifier">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Service Modifiers"
                            icon={faFileMedical}
                            color={theme.palette.primary.main}
                            links={modifierLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="ActivityType">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Activity Types"
                            icon={faMedkit}
                            color={theme.palette.primary.main}
                            links={activityLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Language">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Language Types"
                            icon={faGlobe}
                            color={theme.palette.primary.main}
                            links={languageLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="ContactType">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Contact Types"
                            icon={faAddressCard}
                            color={theme.palette.primary.main}
                            links={contactTypeLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="Company">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Company Addresses"
                            icon={faBuilding}
                            color={theme.palette.primary.main}
                            links={companyLinks}
                        />
                    </Grid>
                </Can>
                <Can do="read" on="NoteTemplate">
                    <Grid item xs={6}>
                        <DashboardSection
                            title="Note Templates"
                            icon={faScroll}
                            color={theme.palette.primary.main}
                            links={templateLinks}
                        />
                    </Grid>
                </Can>
            </Grid>
        </Container>)
}

export default AdministrationDashboard
