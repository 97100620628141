import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import ActivityList from './ActivityList';
import ActivityCreate from './ActivityCreate';
import ActivityEdit from './ActivityEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ActivityRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="ActivityType">
                <ActivityCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:activityId`} action="update" subject="ActivityType">
                <ActivityEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="ActivityType">
                <ActivityList />
            </ProtectedRoute>
        </Switch>
    )
}

export default ActivityRoute
