import React, { useContext } from 'react';
import { AbilityContext } from 'components/AuthorizationProvider';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Container, Theme, createStyles } from '@material-ui/core';

interface Props {

}

const subjects: string[] = [
    'ActivityType',
    'Case',
    'CaseManager',
    'Client',
    'ClientEligibility',
    'Contact',
    'ContactType',
    'County',
    'FacilityStay',
    'Language',
    'MedicalCode',
    'Modifier',
    'Payer',
    'Program',
    'Reports',
    'Service',
    'ServiceAgreement',
    'ServiceNote',
    'TimeSheet'
]

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 650,
        },
        table: {
            width: 650,
        },
        header: {
            fontWeight: 600,
        },
        allowed: {
            backgroundColor: theme.palette.success.main
        },
        denied: {
            backgroundColor: theme.palette.error.main
        }
    }),
);

const Permissions = (props: Props) => {
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    return (
        <Container className={classes.table} >
            <Table
                className={classes.table}
                size="small"
                aria-label="Permission Table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" className={classes.header}>View</TableCell>
                        <TableCell align="center" className={classes.header}>Create</TableCell>
                        <TableCell align="center" className={classes.header}>Update</TableCell>
                        <TableCell align="center" className={classes.header}>Delete</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subjects.map((subject) => (
                        <TableRow key={subject}>
                            <TableCell component="th" scope="row" className={classes.header}>
                                {subject.replace(/([a-z])([A-Z])/g, '$1 $2')}
                            </TableCell>
                            {ability.can('read', subject)
                                ? <TableCell align="center" className={classes.allowed}>Allowed</TableCell>
                                : <TableCell align="center" className={classes.denied}>Denied</TableCell>
                            }
                            {ability.can('create', subject)
                                ? <TableCell align="center" className={classes.allowed}>Allowed</TableCell>
                                : <TableCell align="center" className={classes.denied}>Denied</TableCell>
                            }
                            {ability.can('update', subject)
                                ? <TableCell align="center" className={classes.allowed}>Allowed</TableCell>
                                : <TableCell align="center" className={classes.denied}>Denied</TableCell>
                            }
                            {ability.can('delete', subject)
                                ? <TableCell align="center" className={classes.allowed}>Allowed</TableCell>
                                : <TableCell align="center" className={classes.denied}>Denied</TableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Container>
    )
}

export default Permissions
