import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import ServiceRateForm from './ServiceRateForm';
import { ServiceRateSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    serviceId: string
}

const ServiceRateEdit = ({ serviceId }: Props) => {
    const { rateId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "ServiceRate")

    const loadRate = useCallback(() => {
        loadAction(
            `/ServiceRate/${rateId}`,
            'rate',
            (data) => {
                const formData = dataToForm(data, ServiceRateSchema);
                reset(formData);
            }
        );
    }, [loadAction, rateId, reset]);

    const saveRate = (formData: any) => {
        if (!allowEdit) return;

        formData.serviceId = serviceId;
        const data = formToData(formData, ServiceRateSchema);

        updateAction(
            `/ServiceRate/${rateId}`, data,
            `Service Rate`,
            () => {
                history.push(`/services/${serviceId}/rates`);
            }
        );
    }

    useEffect(() => {
        loadRate();
    }, [loadRate]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Service Rate Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveRate)} noValidate autoComplete="off">
                        <ServiceRateForm serviceId={serviceId} disabled={!allowEdit} />
                        <FormButtons
                            dirty={formState.isDirty}
                            disabled={!allowEdit}
                            cancelLink={`/services/${serviceId}/rates`}
                            submitLabel="Save Rate"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ServiceRateEdit
