import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TimeLineBadge from './TimelineBadge';
import TimeLineBody from './TimelineBody';
import TimelineDate from './TimelineDate';
import TimelineValue from './TimelineValue';

interface Props {
    item: any;
    showIcon?: boolean
}

const TimelineItemChanged = ({ item, showIcon = true }: Props) => {
    return (
        <React.Fragment>
            {showIcon &&
                <TimeLineBadge>
                    <FontAwesomeIcon icon={faEdit} />
                </TimeLineBadge>
            }
            <TimeLineBody>
                <span className="timeline-user">{item.activityBy}</span>
                <span className="timeline-text"> changed </span>
                <span className="timeline-entity"> {item.displayName} </span>
                <TimelineValue value={item.description} />
                <span className="timeline-text"> on </span>
                <TimelineDate value={item.activityDate} />
                <ul className="timeline-item-changes">
                    {item.changes.map((change: any, index: number) =>
                        <li className="timeline-item-change" key={index}>
                            <span><TimelineValue value={change.displayName} /> </span>
                            <span> from <TimelineValue value={change.originalFormatted} /> </span>
                            <span> to <TimelineValue value={change.currentFormatted} /> </span>
                        </li>
                    )}
                </ul>
            </TimeLineBody>
        </React.Fragment>

    )
}

export default TimelineItemChanged
