import { Checkbox, FormControlLabel, Grid, TextField } from '@material-ui/core';
import ModifierSelect from 'components/Form/ModifierSelect';
import ProgramSelect from 'components/Form/ProgramSelect';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
    disabled?: boolean;
}

const ServiceForm = ({ disabled = false }: Props) => {
    const { errors, register, getValues } = useFormContext();
    const [autoRenew, setAutoRenew] = useState(false);
    
    useEffect(() => {
        if(getValues("autoRenew") !== undefined)
            setAutoRenew(getValues("autoRenew"));
    })

    const updateCheckState = (evt: ChangeEvent<HTMLInputElement>, checked: boolean) =>
    {
        setAutoRenew(checked);
    }

    const getSubmissionCode = (val: string) => {
        const programCodes = ['CADI', 'BI', 'DD', 'PP', 'IF', 'CTPD', 'VADD'];
        if (val && programCodes.includes(val.toUpperCase())) {
            const subCodeIndex = programCodes.findIndex(x => val.toUpperCase() === x);
            return `0${subCodeIndex}`
        }
        return ''
    }

    const initProgramCode = getValues('programCode')
    const [subCode, setSubCode] = useState<string>('')

    const onProgramCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        const newCode = getSubmissionCode(val)
        setSubCode(newCode)
    }

    return (
        <React.Fragment>
            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="procedureCode"
                        label="Procedure Code"
                        required
                        fullWidth
                        error={Boolean(errors.procedureCode)}
                        helperText={errors.procedureCode && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="programCode"
                        label="Program Code"
                        required
                        fullWidth
                        error={Boolean(errors.programCode)}
                        helperText={errors.programCode && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={onProgramCodeChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        disabled={disabled}
                        name="name"
                        label="Name"
                        required
                        fullWidth
                        error={Boolean(errors.name)}
                        helperText={errors.name && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled={disabled}
                        name="description"
                        label="Description"
                        fullWidth
                        multiline
                        minRows="2"
                        inputRef={register}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ProgramSelect
                        disabled={disabled}
                        name="programId"
                        label="Program"
                        required={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="generalLedgerAccount"
                        label="General Ledger"
                        required
                        fullWidth
                        error={Boolean(errors.generalLedgerAccount)}
                        helperText={errors.generalLedgerAccount && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={disabled}
                        name="customerType"
                        label="Customer Type"
                        fullWidth
                        error={Boolean(errors.customerType)}
                        helperText={errors.customerType && 'This field is required'}
                        inputRef={register()}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ModifierSelect
                        disabled={disabled}
                        name="modifier1Id"
                        label="Modifier 1"
                        required={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ModifierSelect
                        disabled={disabled}
                        name="modifier2Id"
                        label="Modifier 2"
                        required={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ModifierSelect
                        disabled={disabled}
                        name="modifier3Id"
                        label="Modifier 3"
                        required={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ModifierSelect
                        disabled={disabled}
                        name="modifier4Id"
                        label="Modifier 4"
                        required={false}
                        multiselect={false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        disabled={true}
                        name="submissionCode"
                        label="Submission Code"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={getSubmissionCode(initProgramCode) || subCode}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControlLabel 
                        name="autoRenew" 
                        control={<Checkbox checked={autoRenew} onChange={updateCheckState} />} 
                        label="Auto Renew"
                        labelPlacement="start"
                        inputRef={register()}
                    />
                </Grid>                
            </Grid>
        </React.Fragment>
    )
}

export default ServiceForm
