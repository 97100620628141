import React from 'react';
import Container from '@material-ui/core/Container';
import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';

const useStyles = makeStyles(() =>
    createStyles({
        sectionHeading: {
            textAlign: 'center',
            marginTop: '20px'
        },
        sectionSubHeading: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        clientHeader: {
            marginBottom: 10,
        },
        columnLabel: {
            fontWeight: 'bold',
            textAlign: 'center'
        },
        columnData: {
            textAlign: 'center'
        },
        unitSubtotal: {
            textAlign: 'right',
            fontWeight: 'bold',
            margin: '20px'
        },
        unitTotal: {
            textAlign: 'right', 
            fontWeight: 'bold', 
            margin: '30px 20px 30px 0px',
            fontSize: '1.2em'
        }
    }),
);

const formatDate = (date: string) => {
    return DateTime.fromISO(date, {zone: 'utc'}).toFormat('MM/dd/yyyy');
};

interface Props {
    data: any;
    startDate: string;
    endDate: string;
}

const CaseManagerList = ({
    data,
    startDate,
    endDate
}: Props) => {
    const classes = useStyles();

    return (
        data.map((item :any, managerIndex: number) => <div key={"manager-" + managerIndex}>
            <h2 className={classes.sectionHeading}>Case Manager: {item.CaseManagerName}</h2>
            <p className={classes.sectionSubHeading}>{formatDate(startDate)} - {formatDate(endDate)}</p>
            {item.Clients.map((client: any, clientIndex: number) => <div key={"client-" + managerIndex + "-" + clientIndex}>
                <p>Client</p>
                <h4 className={classes.clientHeader}>{client.ClientName}</h4>
                <Container maxWidth={false}>
                    <Grid container>
                        <Grid item xs={2}>
                            <p className={classes.columnLabel}>Date</p>
                        </Grid>
                        <Grid item xs={2}>
                            <p className={classes.columnLabel}>Activity</p>
                        </Grid>
                        <Grid item xs={1}>
                            <p className={classes.columnLabel}>Units</p>
                        </Grid>
                        <Grid item xs={7}>
                            <p style={{fontWeight: 'bold' }}>Note</p>
                        </Grid>
                    </Grid>
                        {client.Notes.map((note: any, noteIndex: number) => 
                            <Grid container key={"note-" + managerIndex + "-" + clientIndex + "-" + noteIndex}>
                                <Grid item xs={2}>
                                    <p className={classes.columnData}>{formatDate(note.DateOfService)}</p>
                                </Grid>
                                <Grid item xs={2}>
                                    <p className={classes.columnData}>{note.CaseManagerActivity}</p>
                                </Grid>
                                <Grid item xs={1}>
                                    <p className={classes.columnData}>{note.UnitsUsed}</p>
                                </Grid>
                                <Grid item xs={7}>
                                    <p>{note.Note}</p>
                                </Grid>
                            </Grid>
                        )}
                        <p className={classes.unitSubtotal}>Total Units: {client.TotalUnits}, Billable: {client.TotalBillableUnits}, Non-Billable: {client.TotalNonBillableUnits}</p>
                </Container>
                
            </div>)}
            <p className={classes.unitTotal}>{item.CaseManagerName}'s total units from {formatDate(startDate)} to {formatDate(endDate)}: {item.TotalUnits}, Billable: {item.TotalBillableUnits}, Non-Billable: {item.TotalNonBillableUnits}</p>
        </div>)
    )
}

export default CaseManagerList