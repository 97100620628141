import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ContactTypeForm from './ContactTypeForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { dataToForm, formToData } from 'services/DataTransform';
import { ContactTypeSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';

const ContactTypeEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { contactTypeId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadContactType = useCallback(() => {
        loadAction(
            `/ContactType/${contactTypeId}`,
            'contact type',
            data => {
                const formData = dataToForm(data, ContactTypeSchema);
                reset(formData);
            }
        );
    }, [loadAction, contactTypeId, reset]);

    const saveContactType = (formData: any) => {
        const data = formToData(formData, ContactTypeSchema);

        updateAction(
            `/ContactType/${contactTypeId}`, data,
            `contact type '${data.name}'`,
            () => {
                history.push('/administration/contactTypes');
            }
        );
    }

    useEffect(() => {
        loadContactType();
    }, [loadContactType]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/contacttypes">Contact Types</LinkRouter>
                <Typography color="textPrimary">Edit Contact Type</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update Contact Type">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Contact Type Information"
                    icon={faAddressCard}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveContactType)} noValidate>
                            <ContactTypeForm />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/contactTypes"
                                submitLabel="Save Contact Type"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ContactTypeEdit
