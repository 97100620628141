import { makeStyles, Grid, TextField } from '@material-ui/core';
import { AddBoxRounded } from '@material-ui/icons';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
    textField: {
    },
    inputLeft: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        borderColor: "black",
        paddingLeft: "10px",
        backgroundColor: "lightgray",
        borderTopLeftRadius: "5px",
    },
    input: {
        borderRightStyle: "solid",
        borderBottomStyle: "solid",
        borderColor: "black",
        paddingLeft: "10px",
        backgroundColor: "lightgray",
    },
    inputRight: {
        borderBottomStyle: "solid",
        paddingLeft: "10px",
        borderColor: "black",
        backgroundColor: "lightgray",
        borderTopRightRadius: "5px",
    }
}));

const TimeSheetRowHeader = () => {
    const classes = useStyles();

    return (
        <Grid
            container
            direction="row"
        >
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'\nDays'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.inputLeft
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    disabled={true}
                    defaultValue={'\nDate'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'\nTime In'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'\nTime Out'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'\nTime In'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'\nTime Out'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    disabled={true}
                    defaultValue={'Other Hours\nType'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'Other\nHours'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'Hours\nWorked'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.input
                    }}
                />
            </Grid>
            <Grid item xs={1}>
                <TextField
                    disabled={true}
                    defaultValue={'Total\nHours'}
                    fullWidth
                    multiline
                    minRows={3}
                    InputProps={{
                        className: classes.inputRight
                    }}
                />
            </Grid>
            <Grid item xs={1}>
            </Grid>
        </Grid>
    )
}

export default TimeSheetRowHeader
