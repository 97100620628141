import { faFileMedicalAlt } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Button,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions'
import { AbilityContext, Can } from 'components/AuthorizationProvider';


const ServiceList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "Service")

    const loadServices = useCallback(() => {
        loadAction(
            '/service',
            'services',
            setData
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/service/${data.id}`,
            `service '${data.code}'`,
            loadServices
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/services/${data.id}`)
    };

    useEffect(() => {
        loadServices();
    }, [loadServices]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <Typography color="textPrimary">Services</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <SectionHeader title="Services">
                    <Can do="create" on="Service">
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/services/create')}
                        >
                            Add Service
                        </Button>
                    </Can>
                </SectionHeader>
                <SectionTitle
                    title="Service List"
                    icon={faFileMedicalAlt}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile="Services"
                    columns={[
                        { field: 'id', title: 'Id', hidden: true },
                        
                        { field: 'procedureCode', title: 'Procedure', width: 180 },
                        { field: 'name', title: 'Name', defaultSort: 'asc' },
                        { field: 'description', title: 'Description' },
                        { field: 'programId', title: 'Program Id', hidden: true, export: true },
                        { field: 'programCode', title: 'Program', cellStyle: { minWidth: 150, maxWidth: 250 } },
                        { field: 'programName', title: 'Program Name', hidden: true, export: true },
                        { field: 'generalLedgerAccount', title: 'General Ledger', cellStyle: { minWidth: 150, maxWidth: 250 } },
                        
                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete,
                            hidden: !allowDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default ServiceList
