import React from 'react'
import { Switch, useRouteMatch } from "react-router-dom";
import LanguageList from './LanguageList';
import LanguageCreate from './LanguageCreate';
import LanguageEdit from './LanguageEdit';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const LanguageRoute = () => {
    const match = useRouteMatch();

    return (
        <Switch>
            <ProtectedRoute path={`${match.path}/create`} action="create" subject="Language">
                <LanguageCreate />
            </ProtectedRoute>
            <ProtectedRoute path={`${match.path}/:languageId`} action="update" subject="Language">
                <LanguageEdit />
            </ProtectedRoute>
            <ProtectedRoute path={match.path} action="read" subject="Language">
                <LanguageList />
            </ProtectedRoute>
        </Switch>
    )
}

export default LanguageRoute
