import React from 'react'
import { Link, LinkProps } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

interface Props {
    icon?: React.ReactElement;
    primary: string;
    to: string;
    color?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    itemIcon: (props: Props) => ({
        minWidth: "30px",
        color: props.color
    }),
}));

const ListItemLink = (props: Props) => {
    const { icon, primary, to } = props;

    const renderLink = React.useMemo(
        () =>
            React.forwardRef<any, Omit<LinkProps, 'to'>>((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    const classes = useStyles(props);

    return (
        <li>
            <ListItem button component={renderLink}>
                {icon ? <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

export default ListItemLink