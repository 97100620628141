import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionTitle: (props: Props) => ({
            color: props.color,
            borderBottomStyle: "solid",
            borderBottomWidth: "2px",
            borderBottomColor: props.color
        }),
        sectionIcon: {
            marginRight: "10px"
        }
    }),
);

interface Props {
    icon: IconDefinition,
    title: string,
    color: string
}

const SectionTitle = (props: Props) => {
    const classes = useStyles(props);

    return (
        <Typography
            className={classes.sectionTitle}
            component="h2"
            variant="h6"
            gutterBottom
        >
            {props.icon && <FontAwesomeIcon
                icon={props.icon}
                className={classes.sectionIcon}
            />}
            {props.title}
        </Typography>
    )
}

export default SectionTitle
