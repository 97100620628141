import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { useFormActions } from 'components/Form/FormActions';
import LinkRouter from 'components/Navigation/LinkRouter';
import TabNavigationBar from 'components/Navigation/TabNavigationBar';
import React, { useCallback, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useParams,
    useRouteMatch
} from 'react-router-dom';
import ServiceDetail from './ServiceDetail';
import ServiceHistory from './ServiceHistory';
import ServiceRateList from './Rates/ServiceRateList';
import ServiceRateCreate from './Rates/ServiceRateCreate';
import ServiceRateEdit from './Rates/ServiceRateEdit';
import { dataToForm } from 'services/DataTransform';
import { ServiceSchema } from 'models/Schemas';
import ProtectedRoute from 'components/Navigation/ProtectedRoute';

const ServiceEdit = () => {
    const { serviceId } = useParams();
    const match = useRouteMatch();
    const [service, setService] = useState<any>({});
    const { loadAction } = useFormActions();

    const tabs = [
        {
            label: 'Details',
            location: `/services/${serviceId}`,
            matchPath: `${match.path}`
        },
        {
            label: 'Rates',
            location: `/services/${serviceId}/rates`,
            matchPath: `${match.path}/rates`
        },
        {
            label: 'History',
            location: `/services/${serviceId}/history`,
            matchPath: `${match.path}/history`
        }
    ];

    const loadService = useCallback(() => {
        loadAction(
            `/service/${serviceId}`,
            'service',
            (data) => {
                const formData = dataToForm(data, ServiceSchema);
                setService(formData);
            }
        );
    }, [loadAction, serviceId]);

    useEffect(() => {
        loadService();
    }, [loadService]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/services">Services</LinkRouter>
                <Typography color="textPrimary">
                    {service && <span>{service.procedureCode} - {service.name}</span>}
                </Typography>
            </Breadcrumbs>

            <TabNavigationBar tabs={tabs} />

            {serviceId &&
                <Switch>
                    <ProtectedRoute path={`${match.path}/rates/create`} action="create" subject="ServiceRate">
                        <ServiceRateCreate serviceId={serviceId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/rates/:rateId`} action="read" subject="ServiceRate">
                        <ServiceRateEdit serviceId={serviceId} />
                    </ProtectedRoute>
                    <ProtectedRoute path={`${match.path}/rates`} action="read" subject="ServiceRate">
                        <ServiceRateList serviceId={serviceId} />
                    </ProtectedRoute>
                    <Route path={`${match.path}/history`}>
                        <ServiceHistory serviceId={serviceId} />
                    </Route>
                    <Route path={match.path}>
                        <ServiceDetail serviceId={serviceId} />
                    </Route>
                </Switch>
            }
        </Container>
    )
}

export default ServiceEdit
