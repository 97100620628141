import { faBook } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ProgramForm from './ProgramForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';

const ProgramCreate = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const actions = useFormActions();

    const saveProgram = (data: any) => {
        actions.createAction(
            '/program', data,
            `program '${data.code}'`,
            () => history.push('/programs')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/programs">Programs</LinkRouter>
                <Typography color="textPrimary">Add Program</Typography>
            </Breadcrumbs>

            <Paper className={classes.paper}>
                <FormHeader title="Add New Program">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Program Information"
                    icon={faBook}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <form onSubmit={form.handleSubmit(saveProgram)} noValidate>
                        <ProgramForm />
                        <FormButtons
                            dirty={form.formState.isDirty}
                            cancelLink="/programs"
                            submitLabel="Save Program"
                        />
                    </form>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ProgramCreate
