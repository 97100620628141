import React from 'react'
import { Tooltip, Menu, MenuItem, Button } from '@material-ui/core';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { bindTrigger, bindMenu } from 'material-ui-popup-state/core';
import { useFetch, CachePolicies } from 'use-http';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

interface Props {
    children?: React.ReactNode;
    onTemplateSelected: (template: any) => void;
}

const TemplateSelector = (props: Props) => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'templateMenu' })

    // load data
    const { data: templates } = useFetch('/NoteTemplate?sort=name', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    const handleTemplateSelect = (template: any) =>{
        
        if (props.onTemplateSelected){
            props.onTemplateSelected(template);
        }

        popupState.close();
    }

    return (
        <>
            <Tooltip title="Use a Note Template">
                <Button
                    aria-label="more"
                    aria-controls="action-menu"
                    aria-haspopup="true"
                    startIcon={<SpeakerNotesIcon /> }
                    {...bindTrigger(popupState)}
                >
                    Templates
                </Button>
            </Tooltip>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {templates && templates.map((template: any) => (
                    <MenuItem key={template.id} onClick={() => handleTemplateSelect(template)}>{template.name}</MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default TemplateSelector
