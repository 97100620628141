import React, { useState, useEffect } from 'react'
import Bar from './Bar'
import Container from './Container'
import Spinner from './Spinner'
import './Progress.css'

interface Props {
    loading: boolean;
    animationDuration?: number;
    incrementDuration?: number;
    minimum?: number;
    color: string;
}

const Progress = ({
    loading = false,
    animationDuration = 200,
    incrementDuration = 500,
    minimum = 0.1,
    color = '#29d'
}: Props) => {

    const [progress, setProgress] = useState(0);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (loading) {
            // reset bar
            setProgress(minimum);
            setVisible(true);
            // timer to progress bar
            const interval = setInterval(() => {
                setProgress(current => current + (1 - current) * 0.2)
            }, incrementDuration);
            return () => clearInterval(interval);
        }
        else {
            // progress to 100%
            setProgress(1);
            // delay hiding
            const timer = setTimeout(() => {
                setVisible(false);
            }, animationDuration);
            return () => clearTimeout(timer);
        }
    }, [loading, animationDuration, incrementDuration, minimum])

    return (
        <Container
            visible={visible}
            animationDuration={animationDuration}>
            <Bar
                progress={progress}
                animationDuration={animationDuration}
                color={color}
            />
            <Spinner color={color} />
        </Container>
    )
}

export default Progress
