import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Container } from '@material-ui/core'

interface Props {

}

const Unauthorized = (props: Props) => {
    return (
        <Container maxWidth={false}>

            <Alert severity="error" style={{ margin: 30 }}>
                <AlertTitle>Unauthorized</AlertTitle>
                <p>You don't have access to this page.</p>
            </Alert>

        </Container>
    )
}

export default Unauthorized