import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { dataToForm, formToData } from 'services/DataTransform';
import FacilityStayForm from './FacilityStayForm';
import { FacilityStaySchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const FacilityStayEdit = ({ clientId }: Props) => {
    const { facilityStayId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "FacilityStay")

    const loadFacilityStay = useCallback(() => {
        loadAction(
            `/FacilityStay/${facilityStayId}`,
            'facility stay',
            (data) => {
                const formData = dataToForm(data, FacilityStaySchema);
                reset(formData);
            }
        );
    }, [loadAction, facilityStayId, reset]);

    const saveFacilityStay = (formData: any) => {
        if (!allowEdit) return;

        const data = formToData(formData, FacilityStaySchema);

        updateAction(
            `/FacilityStay/${facilityStayId}`, data,
            `facility stay`,
            () => {
                history.push(`/clients/${clientId}/facility`);
            }
        );
    }

    useEffect(() => {
        loadFacilityStay();
    }, [loadFacilityStay]);

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Facility Stay Information">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveFacilityStay)} noValidate autoComplete="off">
                        <FacilityStayForm 
                            clientId={clientId} 
                            disabled={!allowEdit} 
                        />
                        <FormButtons
                            dirty={formState.isDirty} 
                            disabled={!allowEdit}
                            cancelLink={`/clients/${clientId}/facility`}
                            submitLabel="Save Stay"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default FacilityStayEdit
