import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Breadcrumbs, Container, Paper, Typography, Grid } from '@material-ui/core';
import LinkRouter from 'components/Navigation/LinkRouter';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import DataGrid from 'components/DataGrid/DataGrid';
import { DateTime } from 'luxon';
import URI from 'urijs';
import ManagerPicker from 'components/Pickers/ManagerPicker';

const ClientCount = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const [caseManagers, setCaseManagers] = useState<any[]>([]);

    const firstLoad = useRef(true);

    const loadData = useCallback((caseManagerIds: number[]) => {
        const url = URI("/Report/ClientCount")
            .setSearch({
                caseManagerIds
            });

        loadAction(
            url.toString(),
            'Client Count',
            setData
        );
    }, [loadAction])

    useEffect(() => {
        // prevent double loading of list
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }

        const ids = caseManagers ? caseManagers.map(m => m.id) : [];

        loadData(ids);
    }, [loadData, caseManagers]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/reports">Reports</LinkRouter>
                <Typography color="textPrimary">Client Count</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="Client Count">
                    <p></p>
                </SectionHeader>
                <SectionTitle
                    title="Client Count Report"
                    icon={faChartBar}
                    color={theme.palette.text.primary}
                />

                <fieldset className="standard">
                    <legend>Parameters</legend>
                    <Grid
                        style={{
                            marginTop: "5px",
                            marginBottom: "5px"
                        }}
                        container
                        direction="row"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <ManagerPicker
                                selected={caseManagers}
                                setSelected={setCaseManagers}
                            />
                        </Grid>
                    </Grid>
                </fieldset>

                <DataGrid
                    exportFile={`Client Count ${DateTime.local().toISODate()}`}
                    columns={[
                        { field: 'CaseManager', title: 'Case Manager', defaultSort: 'asc' },
                        { field: 'ClientCount', title: 'Client Count', width: 250, type: 'numeric' },
                    ]}
                    data={data}
                />
            </Paper>
        </Container>
    )
}

export default ClientCount
