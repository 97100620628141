import { Paper } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useEffect, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import TimeSheetForm from './TimeSheetForm';
import { TimeEntrySchema, TimeSheetSchema } from 'models/Schemas';
import { useAuthorization } from 'components/AuthorizationProvider';
import { TimeSheetListType } from './TimeSheetList';
import { formatDate } from 'utils/dateUtils';
import { TimeEntryType } from 'models/TimeSheetModel';

export type User = {
    FirstName: string,
    LastName: string,
    FullName: string,
    CaseManagerEmailAddress: string,
    CaseManagerId: string,
    SupervisorId: string,
    SupervisorEmailAddress: string,
    IsSupervisor: boolean,
    EmployeeNumber: string
}

const TimeSheetCreate = () => {
    const form = useForm();
    const { errors } = form;
    const { loadAction, createAction, deleteAction } = useFormActions();
    const userInfo = useAuthorization();
    const [userData, setUserData] = useState<User>();
    const [delayed, setDelayed] = useState(true);
    const [timeSheetList, setTimeSheetList] = useState<TimeSheetListType[]>([]);

    // Get the manager and supervisor info
    const loadManagers = useCallback((id: string) => {
        loadAction(
            `/CaseManager/${id}`,
            'case managers',
            (managerData) => {
                if (managerData?.supervisorId) {
                    loadAction(
                        `/CaseManager/${managerData.supervisorId.toString()}`,
                        'case managers',
                        (supervisorData) => {
                            setUserData(
                                {
                                    FirstName: managerData.givenName,
                                    LastName: managerData.familyName,
                                    FullName: managerData.fullName,
                                    CaseManagerId: managerData.id,
                                    CaseManagerEmailAddress: userInfo.userName ?? userInfo.emailAddress,
                                    SupervisorId: supervisorData.id,
                                    SupervisorEmailAddress: supervisorData.emailAddress,
                                    EmployeeNumber: userInfo.employeeNumber
                                } as User
                            )
                        }
                    )
                }
            }
        );
    }, [loadAction])

    // Get previous time sheets
    const loadTimeSheet = useCallback(() => {
        const userId = userInfo.caseManagerId
        loadAction(
            `TimeSheet?q=CaseManagerId%3D${userId}`,
            '/TimeSheet',
            (data) => {
                setTimeSheetList(data)
            }
        );
    }, [loadAction])

    useEffect(() => {
        loadManagers(userInfo.caseManagerId.toString());
    }, [loadManagers])

    // Add a delay so we don't show the error message too early while the user
    // is fetching the supervisor information asynchronously
    useEffect(() => {
        const timeout = setTimeout(() => setDelayed(false), 1000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        loadTimeSheet();
    }, [loadTimeSheet]);

    const classes = useStyles();
    const history = useHistory();

    const saveTimeSheet = (formData: any) => {
        if (userData) {
            const TimeSheetData: { [field: string]: any; } = {};

            var d = new Date();
            TimeSheetData.caseManagerId = userData.CaseManagerId;
            TimeSheetData.created = TimeSheetData.updated = d.toLocaleString();
            TimeSheetData.createdBy = TimeSheetData.updatedBy = userData.FullName;
            TimeSheetData.submitted = 0;
            TimeSheetData.employeeSignature = '';
            TimeSheetData.signatureTimestamp = '';
            TimeSheetData.payPeriodTo = formData.payPeriodTo;
            TimeSheetData.payPeriodFrom = formData.payPeriodFrom;
            TimeSheetData.status = 'OPEN';
            TimeSheetData.notifyCaseManager = '';
            TimeSheetData.comments = formData.comments;

            const TimeSheetFormData = formToData(TimeSheetData, TimeEntrySchema);

            // Check if the newly saved time sheet already exists
            const prevTimeSheetMatch = timeSheetList?.find(x => x.payPeriodFrom === formatDate(TimeSheetFormData.payPeriodFrom))
            if (prevTimeSheetMatch) {
                // Delete previous time sheet
                deleteAction(
                    `/TimeSheet/${prevTimeSheetMatch.id}`,
                    `TimeSheet '${prevTimeSheetMatch.id}'`,
                    () => { console.log('TimeSheet deleted with id: ', prevTimeSheetMatch.id) },
                    true
                )
            }
            createAction(
                '/TimeSheet',
                TimeSheetFormData,
                `Time Entry '${TimeSheetData.createdBy}'`,
                (data: any) => {
                    TimeSheetFormData.id = data.id
                    const formKeys = Object.keys(formData);

                    // Get array of totalHours to find the total amount of rows
                    const entryLength = formKeys.filter(x => x.includes('totalHours')).length

                    if (TimeSheetFormData.id) {
                        let entryArray = []
                        for (var i = 0; i < entryLength; i++) {
                            entryArray.push({
                                "timeSheetId": TimeSheetFormData.id,
                                "caseManagerId": TimeSheetFormData.caseManagerId,
                                "employeeNumber": TimeSheetFormData.employeeNumber
                            } as TimeEntryType)
                        }
                        for (var i = 0; i < formKeys.length; i++) {
                            let entryKey = formKeys[i];
                            if (entryKey.includes("-")) {
                                let valueSplit = formKeys[i].split("-");
                                let tempObj = entryArray[Number(valueSplit[1])];
                                const formRow = formData[entryKey];
                                const keyName = valueSplit[0];
                                const newEntry = { [keyName]: formRow };
                                Object.assign(tempObj, newEntry);
                            }
                        }

                        let staticTwoWeek = entryArray.splice(0, 14)
                        staticTwoWeek.forEach((element, index) => {
                            createAction(
                                '/TimeEntry',
                                element,
                                '',
                                () => {
                                    if (index === 13 && entryArray?.length === 0)
                                        history.push(`/TimeSheet/edit/${TimeSheetFormData.id}`)
                                }
                            );
                        });

                        // Only add additional rows if they have hours applied
                        if (entryArray.length > 0) {
                            // Allow additional rows even if the time in or out are not added
                            // const additionalRows = entryArray.filter(x => x.totalHours !== "0")
                            const additionalRows = entryArray;
                            additionalRows.forEach((element, index) => {
                                createAction(
                                    '/TimeEntry',
                                    element,
                                    '',
                                    () => {
                                        if (index === additionalRows.length - 1)
                                            history.push(`/TimeSheet/edit/${TimeSheetFormData.id}`)
                                    }
                                );
                            })
                        }
                    }
                });
        }
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Time Sheet">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveTimeSheet)} noValidate autoComplete="off">
                    {userData ?
                        <TimeSheetForm Name={userData.FullName} isTimeSheetCreate={true} TimeSheetList={timeSheetList} /> :
                        !delayed && <div className={classes.cellError}>
                            ERROR: No supervisor assigned to user.
                        </div>
                    }
                    <FormButtons
                        disabled={Boolean(Object.keys(errors).length)}
                        dirty={form.formState.isDirty}
                        cancelLink={`/TimeSheet`}
                        submitLabel="Save TimeSheet"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default TimeSheetCreate
