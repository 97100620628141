import { faProcedures } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useState } from 'react';
import { useStyles } from 'components/Styles';
import FormButtons from 'components/Form/FormButtons';
import { useForm, FormProvider } from 'react-hook-form';
import UploadSelect from 'components/Form/UploadSelect';
import UploadMapping, { matchFields } from 'components/Form/UploadMapping';
import { FieldMap } from 'models/ImportDefinition'
import { useFormActions } from 'components/Form/FormActions';
import { useHistory } from 'react-router-dom';
import useFetch, { CachePolicies } from 'use-http';

const CodeImport = () => {
    const classes = useStyles();
    const theme = useTheme();
    const form = useForm();
    const [fileFields, setFileFields] = useState<FieldMap[] | null>(null);
    const [fileData, setFileData] = useState<any[] | null>(null);
    const [fileName, setFileName] = useState<string>('');
    const { reset, handleSubmit } = form;
    const { createAction } = useFormActions();
    const history = useHistory();

    const { data: codeImport } = useFetch('/MedicalCode/import/definition', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    const fileSelected = (data: any[], fileName?: string) => {
        setFileName(fileName ?? '');

        if (!data || data.length === 0) return;

        setFileData(data);

        const headers: FieldMap[] = data[0]
            .map((name: string, index: number) => { return { name, index } });

        setFileFields(headers);

        const formData = matchFields(codeImport, headers);

        reset(formData);
    }

    const processImport = (mappedFields: any) => {
        const mapping: FieldMap[] = [];

        Object.keys(mappedFields).forEach((key) => {
            mapping.push({
                name: key,
                index: parseFloat(mappedFields[key])
            });
        });

        const importData = {
            hasHeader: true,
            fileName: fileName,
            data: fileData,
            mappings: mapping
        };

        createAction(
            '/MedicalCode/import', importData,
            'medical codes',
            () => history.push('/administration/codes')
        );
    }

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/codes">Codes</LinkRouter>
                <Typography color="textPrimary">Import Codes</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader
                    title="Import ICD-10 Medical Code"
                    middle={<Typography style={{ fontWeight: 600 }} color="textPrimary">{fileName}</Typography>}
                >
                    <UploadSelect
                        importDefinition={codeImport}
                        fileSelected={fileSelected}
                    />
                </FormHeader>
                <SectionTitle
                    title="Map Data Fields"
                    icon={faProcedures}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <form onSubmit={handleSubmit(processImport)} noValidate>
                        <UploadMapping
                            importDefinition={codeImport}
                            fileFields={fileFields}
                        />
                        <FormButtons
                            dirty={Boolean(fileData && fileFields)}
                            cancelLink="/administration/codes"
                            submitLabel="Import"
                        />
                    </form>
                </FormProvider>
            </Paper >
        </Container >
    )
}

export default CodeImport
