import React, { createRef, useState } from 'react';
import LoadingOverlay from './LoadingOverlay';
import { DateTime } from 'luxon';
import MaterialTable, { MaterialTableProps, MTableBodyRow, Options } from 'material-table';
import { useProgress } from 'components/Progress/ProgressProvider';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { getPcClientId } from 'Helpers/ProgramCodeHelper';

interface Props<RowData extends object> extends MaterialTableProps<RowData> {
    exportFile?: string;
}

const DataGrid = ({
    options,
    actions,
    components,
    exportFile,
    ...rest
}: Props<any>) => {

    const progress = useProgress();
    const tableReference = createRef<MaterialTable<any>>();

    const [showFilter, setShowFilter] = useState<boolean>(false);

    const fileName = `Export ${DateTime.local().toISODate()}`;

    const exportData = (columns: any[], renderData: any[]) => {
        const formatted = renderData.map((row: any, index: number) => {

            let cloned: any = {};

            for (const column of columns) {
              if (column.export === false)
                continue;

              const key = column.field;
              let value = row[key];

              // Update the client id only on export
                // e.g. “00ARQQUE E” instead of "CMARQUE E"
                if (key === 'ClientID') {
                  // get program code from map and replace
                  const pcVal: string = row['ProgramCode']
                  if (pcVal) value = getPcClientId(pcVal, value)
              }

              // format date
              if (column.type === 'date') {
                  const dateValue = DateTime.fromISO(row[key])
                  value = dateValue.toLocaleString();
              }

              // format datetime
              if (column.type === 'datetime') {
                  const dateValue = DateTime.fromISO(row[key])
                  value = dateValue.toLocaleString(DateTime.DATETIME_SHORT);
              }

              // copy value
              cloned[key] = value;
            }
            return cloned;
        });

        const content = Papa.unparse(formatted);
        let downloadFile = exportFile ?? fileName;

        if (!downloadFile.endsWith('.csv')) {
            downloadFile += '.csv';
        }

        var blob = new Blob([content], {
            type: "text/csv;charset=utf-8"
        });

        saveAs(blob, downloadFile);
    }

    const tableOptions: Options<any> = {
        actionsColumnIndex: -1,
        pageSize: 10,
        filtering: showFilter,
        exportAllData: true,
        exportButton: {
            csv: true,
            pdf: false
        },
        exportFileName: exportFile ?? fileName,
        exportCsv: exportData,
        headerStyle: {
            fontWeight: 600
        },
        ...options
    }

    const tableActions = [
        {
            icon: 'filter_alt',
            tooltip: 'Show Filter Row',
            isFreeAction: true,
            onClick: () => {
                setShowFilter(v => !v);
            }
        },
        ...Array.isArray(actions) ? actions : []
    ];

    const tableComponents = {
        OverlayLoading: LoadingOverlay,
        Row: (props: any) => (
            <MTableBodyRow
                {...props}
                onDoubleClick={(event: any) => {
                    if (!props.actions) return;

                    // find edit or preview action
                    const action = props.actions
                        .find((a: any) => a.icon === 'preview' || a.icon === 'create')

                    // trigger action
                    if (action && action.onClick) {
                        action.onClick(event, props.data);
                    }
                }}
            />
        ),
        ...components,
    }

    return (
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                tableRef={tableReference}
                isLoading={progress.loading}
                style={{ boxShadow: 'none' }}
                title=''
                options={tableOptions}
                actions={tableActions}
                components={tableComponents}
                {...rest}
            />
        </div>
    )
}

export default DataGrid
