import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import Timeline from 'components/Timeline/Timeline';
import TimelineItem from 'components/Timeline/TimelineItem';
import React, { useCallback, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';

interface Props {
    programId: string
}

const ProgramHistory = ({ programId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { loadAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const progress = useProgress();

    const loadHistory = useCallback(() => {
        loadAction(
            `/program/${programId}/audit`,
            'program history',
            data => {
                setData(data);
            }
        );
    }, [loadAction, programId])

    useEffect(() => {
        loadHistory();
    }, [loadHistory]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Program History">
                <div></div>
            </SectionHeader>
            <SectionTitle
                title="Changes"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <BlockUi tag="div" blocking={progress.loading}>
                <Timeline>
                    {data && data.map((item, index) => (
                        <TimelineItem key={index} item={item} />
                    ))}
                </Timeline>
            </BlockUi>
        </Paper>
    )
}

export default ProgramHistory
