import { faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { Paper } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import AgreementForm from './AgreementForm';
import { dataToForm, formToData } from 'services/DataTransform';
import { AgreementSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';
import { DateTime } from 'luxon';

interface Props {
    agreementId: string
}

const AgreementDetail = ({ agreementId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState, setError, clearErrors } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "ServiceAgreement")

    const loadAgreement = useCallback(() => {
        loadAction(
            `/ServiceAgreement/${agreementId}`,
            'agreement',
            (data) => {
                const formData = dataToForm(data, AgreementSchema);
                reset(formData);
            }
        );
    }, [loadAction, agreementId, reset]);

    const saveAgreement = (formData: any) => {
        if (!allowEdit) return;

        let fromDate = DateTime.fromISO(formData.startDate);
        let toDate = DateTime.fromISO(formData.endDate);
        if(toDate.diff(fromDate, 'days').days < 0){
            setError("endDate", {
                message: 'End Date Cannot be less than the Start Date',
                shouldFocus: true,
            });
            return;
        }
        else{
            clearErrors("endDate");
        }        

        const data = formToData(formData, AgreementSchema);

        updateAction(
            `/ServiceAgreement/${agreementId}`, data,
            `service agreement '${data.code}'`,
            () => {
                history.push('/agreements');
            }
        );
    }

    useEffect(() => {
        loadAgreement();
    }, [loadAgreement]);

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Service Agreement Details">
                <p>* = Required Field</p>
            </FormHeader>
            <SectionTitle
                title="Details"
                icon={faNotesMedical}
                color={theme.palette.text.primary}
            />
            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveAgreement)} noValidate>
                        <AgreementForm disabled={!allowEdit} />
                        <FormButtons
                            dirty={formState.isDirty}
                            disabled={!allowEdit}
                            cancelLink="/agreements"
                            submitLabel="Save Agreement"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default AgreementDetail
