import React, { useState, useCallback, useEffect } from "react";
import { IconButton, Badge, Menu, MenuItem } from "@material-ui/core";
import NotificationsIcon from '@material-ui/icons/Notifications';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { InlineBadge } from './InlineBadge';
import { NotificationModel } from 'models/NotificationModel';
import useFetch from 'use-http'
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useAuthorization } from "components/AuthorizationProvider";

export const NotificationPopover = () => {
    const popupState = usePopupState({ variant: 'popover', popupId: 'notificationMenu' })
    const [request, response] = useFetch();
    const location = useLocation()
    const userInfo = useAuthorization();

    const [notifications, setNotifications] = useState<NotificationModel>({
        annualAssessment: 0,
        qualityVisit: 0,
        impendingDischarge: 0,
        newClient: 0,
        quarterlyAddress: 0,
        bpcAssessmentPaperwork: 0,
        totalNotifications: 0
    });

    const loadData = useCallback(async () => {
        if (!userInfo || !userInfo.caseManagerId) return;

        const data = await request.get(`/notification?caseManagerId=${userInfo.caseManagerId}`);
        if (response.ok) {
            setNotifications(data);
        }
    }, [request, response, userInfo]);

    // reload every navigation
    useEffect(() => {
        loadData()
    }, [loadData, location]);



    return (
        <React.Fragment>
            <IconButton
                color="inherit"
                {...bindTrigger(popupState)}
            >
                <Badge color="secondary" overlap="rectangular" badgeContent={notifications.totalNotifications}>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/annual-assessment"
                >
                    <InlineBadge badgeContent={notifications.annualAssessment}>
                        Annual Assessment Reminder
                    </InlineBadge>
                </MenuItem>

                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/quality-visit"
                >
                    <InlineBadge badgeContent={notifications.qualityVisit}>
                        Quality Visit Reminder
                    </InlineBadge>
                </MenuItem>

                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/impending-discharge"
                >
                    <InlineBadge badgeContent={notifications.impendingDischarge}>
                        Impending Discharge Notifications
                    </InlineBadge>
                </MenuItem>

                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/new-clients"
                >
                    <InlineBadge badgeContent={notifications.newClient}>
                        New Client Notifications
                    </InlineBadge>
                </MenuItem>

                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/quarterly-address"
                >
                    <InlineBadge badgeContent={notifications.quarterlyAddress}>
                        Quarterly Address Reminder
                    </InlineBadge>
                </MenuItem>

                <MenuItem
                    onClick={popupState.close}
                    component={Link}
                    to="/notifications/bpc-assessment-paperwork"
                >
                    <InlineBadge badgeContent={notifications.bpcAssessmentPaperwork}>
                        BPC Assessment Paperwork
                    </InlineBadge>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export default NotificationPopover

