import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useProgress } from 'components/Progress/ProgressProvider';
import { useStyles } from 'components/Styles';
import { ReallocateSchema } from 'models/Schemas';
import React, { useCallback, useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientAgreementReallocateForm from './ClientAgreementReallocateForm';
import { DateTime } from 'luxon';

interface Props {
    clientId: string
}

const ClientAgreementReallocate = ({ clientId }: Props) => {
    const { agreementId } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const form = useForm({ defaultValues: { serviceAgreementId: agreementId } });
    const { updateAction } = useFormActions();
    const { handleSubmit, formState, setValue } = form;
    const progress = useProgress();
    const { loadAction } = useFormActions();
    const [facilities, setFacilities] = useState<any[]>([]);
    const [agreement, setAgreement] = useState<any>({});
    const [showFacility, setShowFacility] = useState<boolean>(false);

    const loadData = useCallback(() => {
        loadAction(
            `/client/${clientId}/facility?sort=Admitted`,
            'facility stay',
            setFacilities
        );
    }, [loadAction, clientId])

    const loadAgreement = useCallback(() => {
        loadAction(
            `/ServiceAgreement/${agreementId}`,
            'agreement',
            (data: any) => {
                setAgreement(data);
            }
        );
    }, [loadAction, agreementId]);

    const reallocateAgreement = (formData: any) => {
        formData.serviceAgreementId = agreementId;
        const data = formToData(formData, ReallocateSchema);

        updateAction(
            `/ServiceAgreement/${agreementId}/Reallocation`, data,
            `service agreement '${data.serviceAgreementNumber}'`,
            () => {
                history.push(`/clients/${clientId}/agreements`);
            }
        );

    }

    const handleShowFacilityStays = () => {
        setShowFacility((value: boolean) => !value);
    }

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        loadAgreement();
    }, [loadAgreement]);

    useEffect(() => {
        setValue('newEndDate', agreement.endDate, {
            shouldDirty: true,
            shouldValidate: true
        })
    }, [setValue, agreement])

    return (
        <Paper className={classes.paper}>

            <FormHeader title="Service Agreement Reallocation">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(reallocateAgreement)} noValidate autoComplete="off">
                        <ClientAgreementReallocateForm agreementId={agreementId} />
                        <FormButtons
                            dirty={formState.isDirty}
                            cancelLink={`/clients/${clientId}/agreements/${agreementId}`}
                            submitLabel="Save"
                        >
                            <Button
                                type="button"

                                onClick={handleShowFacilityStays}
                            >
                                {showFacility
                                    ? <span>Hide Facility Stays</span>
                                    : <span>Show Facility Stays</span>
                                }
                            </Button>
                        </FormButtons>
                    </form>
                </BlockUi>
            </FormProvider>
            {
                showFacility &&
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Facility Admitted</TableCell>
                                <TableCell>Facility Discharged</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {facilities.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.admitted ? DateTime.fromISO(row.admitted).toLocaleString() : ''}</TableCell>
                                    <TableCell>{row.discharged ? DateTime.fromISO(row.discharged).toLocaleString() : ''}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }


        </Paper>
    )
}

export default ClientAgreementReallocate
