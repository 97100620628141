import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import { useFormActions } from 'components/Form/FormActions';
import { useStyles } from 'components/Styles';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Select,
    Typography,
} from '@material-ui/core';
import { Can, AbilityContext } from 'components/AuthorizationProvider';
import { useFetch } from 'use-http';
import { MTableToolbar } from 'material-table';

interface Props {
    agreementId: string
}

const AgreementNoteList = ({ agreementId }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const { data: managers } = useFetch('/CaseManager?sort=FamilyName&q=IsActive%3Dtrue', []);
    const [filter, setFilter] = useState<string>('');

    const ability = useContext(AbilityContext);
    const allowDelete = ability.can("delete", "ServiceNote")

    const loadCases = useCallback(() => {
        loadAction(
            `/ServiceAgreement/${agreementId}/Note`,
            'service notes',
            setData
        );
    }, [loadAction, agreementId])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/ServiceNote/${data.id}`,
            `service note '${data.id}'`,
            loadCases
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/agreements/${agreementId}/notes/${data.id}`)
    };

    const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;
        setFilter(selectedValue);
    }
    useEffect(() => {
        loadCases();
    }, [loadCases]);

    useEffect(() => {
        const filtered = data?.filter((row: any) => {
            if (!filter || filter === '' || !row.caseManagerId) {
                return true;
            }

            return row.caseManagerId === Number(filter);
        })

        setFilteredData(filtered);
    }, [data, filter]);

    return (
        <Paper className={classes.paper}>
            <SectionHeader title="Service Agreement Notes">
                <Can do="create" on="ServiceNote">
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => history.push(`/agreements/${agreementId}/notes/create`)}
                    >
                        Add Note
                    </Button>
                </Can>
            </SectionHeader>
            <SectionTitle
                title="Service Notes"
                icon={faUser}
                color={theme.palette.text.primary}
            />
            <DataGrid
                exportFile="ServiceNotes"
                columns={[
                    { field: 'id', title: 'Id', hidden: true, export: true },

                    { field: 'serviceAgreementNumber', title: 'Agreement Number', width: 210 },

                    { field: 'procedureCode', title: 'Procedure', hidden: true, export: true },
                    { field: 'serviceName', title: 'Service Name' },

                    { field: 'activityTypeName', title: 'Activity' },
                    { field: 'unitsUsed', title: 'Units', width: 180, type: 'numeric' },

                    { field: 'serviceDate', title: 'Date', width: 180, type: 'date', defaultSort: 'desc' },

                    { field: 'caseManagerId', title: 'Case Manager Id', hidden: true, export: true },
                    { field: 'caseManagerName', title: 'Case Manager' },

                    { field: 'isLocked', title: 'Locked', width: 110, type: 'boolean' },

                    { field: 'note', title: 'Note', hidden: true, export: true },

                    { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                    { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                    { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                    { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },
                ]}
                data={filteredData}
                components={{
                    Toolbar: props => (
                        <div className={classes.toolbarWrapper}>
                            <Select native
                                className={classes.toolbarSelector}
                                value={filter}
                                onChange={handleFilterChange}>
                                <option aria-label="None" value="">- All Case Managers -</option>
                                {managers && managers.map((item: any) => (
                                    <option key={item.id} value={item.id}>{item.fullName}</option>
                                ))}
                            </Select>
                            <MTableToolbar
                                classes={{
                                    spacer: classes.clearSpacer
                                }}
                                {...props}
                            />
                        </div>
                    )
                }}
                detailPanel={(rowData: any) => (
                    <Box
                        padding={1.5}
                        style={{
                            border: '1px solid #e6e6e6',
                            borderRadius: 4,
                            overflow: 'auto',
                            maxHeight: '400px'
                        }}
                    >
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                            {rowData.note}
                        </Typography>
                    </Box>
                )}
                actions={[
                    {
                        icon: 'create',
                        tooltip: 'Edit Item',
                        onClick: handleEdit
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Item',
                        onClick: handleDelete,
                        hidden: !allowDelete
                    }
                ]}
            />
        </Paper>
    )
}

export default AgreementNoteList
