import { faProcedures } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import DataGrid from 'components/DataGrid/DataGrid';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import SplitButton, { SplitItem } from 'components/Form/SplitButton';

const CodeList = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const { loadAction, deleteAction } = useFormActions();
    const [data, setData] = useState<any[]>([]);

    const splitItems: SplitItem[] = [
        {
            text: 'Import Codes',
            onClick: (e, i) => history.push('/administration/codes/import')
        }
    ];

    const loadCodes = useCallback(() => {
        loadAction(
            '/MedicalCode',
            'medical codes',
            setData
        );
    }, [loadAction])

    const handleDelete = (event: any, data: any) => {
        if (!data) {
            return;
        }

        deleteAction(
            `/MedicalCode/${data.id}`,
            `medical code '${data.code}'`,
            loadCodes
        );
    };

    const handleEdit = (event: any, data: any) => {
        history.push(`/administration/codes/${data.id}`)
    };

    useEffect(() => {
        loadCodes();
    }, [loadCodes]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <Typography color="textPrimary">Codes</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <SectionHeader title="ICD-10 Medical Codes">
                    <SplitButton
                        text="Add Code"
                        onClick={() => history.push('/administration/codes/create')}
                        items={splitItems}
                    />
                </SectionHeader>
                <SectionTitle
                    title="Medical Code List"
                    icon={faProcedures}
                    color={theme.palette.text.primary}
                />
                <DataGrid
                    exportFile='Medical Codes'
                    columns={[
                        { field: 'id', title: 'Id', hidden: true, export: true, type: 'numeric' },

                        { field: 'code', title: 'Code', width: 200, defaultSort: 'asc' },
                        { field: 'description', title: 'Description' },
                        
                        { field: 'isActive', title: 'Active', width: 120, type: 'boolean' },

                        { field: 'created', title: 'Created', hidden: true, export: true, type: 'date' },
                        { field: 'createdBy', title: 'Created By', hidden: true, export: true },
                        { field: 'updated', title: 'Updated', hidden: true, export: true, type: 'date' },
                        { field: 'updatedBy', title: 'Updated By', hidden: true, export: true },

                    ]}
                    data={data}
                    actions={[
                        {
                            icon: 'create',
                            tooltip: 'Edit Item',
                            onClick: handleEdit
                        },
                        {
                            icon: 'delete',
                            tooltip: 'Delete Item',
                            onClick: handleDelete
                        }
                    ]}
                />
            </Paper>
        </Container>
    )
}

export default CodeList
