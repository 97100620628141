import React, { useState, useCallback, useEffect } from "react";
import {
  Breadcrumbs,
  Container,
  Paper,
  Typography,
  Grid,
  TextField,
  Box,
  Button,
} from "@material-ui/core";
import LinkRouter from "components/Navigation/LinkRouter";
import SectionHeader from "components/Common/SectionHeader";
import SectionTitle from "components/Common/SectionTitle";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "components/Styles";
import { useFormActions } from "components/Form/FormActions";
import DataGrid from "components/DataGrid/DataGrid";
import URI from "urijs";
import { useDebounce } from "components/Debounce";
import { DateTime } from "luxon";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { previousPeriod } from 'services/TimePeriod';


const timePeriod = previousPeriod();

const TimeSheetRange = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { loadAction } = useFormActions();
  const [data, setData] = useState<any[]>([]);

  const [startDate, setStartDate] = useState<string>(
    timePeriod.startDate.toISODate()
  );
  const [endDate, setEndDate] = useState<string>(
    timePeriod.endDate.toISODate()
  );

  const delayedStartDate = useDebounce(startDate, 500);
  const delayedEndDate = useDebounce(endDate, 500);

  // load data
  const loadData = useCallback(
    (startDate: string, endDate: string) => {
      const url = URI("/Report/TimeSheetOutput").setSearch({
        startDate,
        endDate,
      });

      loadAction(url.toString(), "Time Sheet Output", setData);
    },
    [loadAction]
  );

  const exportData = () => {
    if (!data?.length) return;

    const formatted = data.map((row: any) => {
      return {
        "Employee/Vendor": row.Employee,
        "Recorded by ID": row.EmployeeNumber,
        // 'Ticket Number': row.TicketNumber,
        "Ticket Date": DateTime.fromISO(row.TicketDate).toLocaleString(),
        "Has been used in Sales/Invoicing": "FALSE",
        "Invoice Number Used": row.InvoiceNumberUsed,
        "Activity Item ID": row.ActivityItemId,
        "Customer/Job/Adm": row.Customer,
        "Completed for ID": row.CompletedForId,
        "Pay Level": row.PayLevel,
        "Has been used in Payroll": row.UsedInPayRoll,
        "Manual/Timed Option": row.ManualOption,
        "Start Time": row.StartTime,
        "End Time": row.EndTime,
        Break: row.Break,
        Duration: row.Duration,
        "Internal Memo": row.InternalMemo,
        "Billing Type": row.BillingType,
        "Billing Rate": row.BillingRate,
        "Billing Status": row.BillingStatus,
        "Unit Duration": row.UnitDuration,
        "Billing Amount": row.BillingAmount,
        "Ticket Description for Invoicing": row.TicketDescription,
      };
    });

    const content = Papa.unparse(formatted);
    var filename = `Time Sheet Range Output ${DateTime.local().toISODate()}.csv`;

    var blob = new Blob([content], {
      type: "text/csv;charset=utf-8",
    });

    saveAs(blob, filename);

    setTimeout(() => {
      loadData(startDate, endDate);
    }, 100);
  };

  useEffect(() => {
    if (
      !DateTime.fromISO(delayedStartDate).isValid ||
      !DateTime.fromISO(delayedEndDate).isValid
    ) {
      setTimeout(() => setData([]), 0);
      return;
    }

    loadData(delayedStartDate, delayedEndDate);
  }, [loadData, delayedStartDate, delayedEndDate]);

  return (
    <Container maxWidth={false}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ marginTop: "-10px", marginBottom: "10px" }}
      >
        <LinkRouter to="/">Home</LinkRouter>
        <LinkRouter to="/reports">Reports</LinkRouter>
        <Typography color="textPrimary">Time Sheet Output</Typography>
      </Breadcrumbs>
      <Paper className={classes.paper}>
        <SectionHeader title="Time Sheet Output - Date Range">
          <p></p>
        </SectionHeader>
        <SectionTitle
          title="Time Sheet Output Report"
          icon={faChartBar}
          color={theme.palette.text.primary}
        />

        <fieldset className="standard">
          <legend>Parameters</legend>
          <Grid
            style={{
              marginTop: "5px",
              marginBottom: "5px",
            }}
            container
            direction="row"
            spacing={3}
          >
            <Grid item xs={4}>
              <TextField
                name="startDate"
                label="Start Date"
                type="date"
                fullWidth
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                name="endDate"
                label="End Date"
                type="date"
                fullWidth
                value={endDate}
                onChange={(event) => setEndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </fieldset>

        <Box padding="5px">
          <Button
            variant="contained"
            color="primary"
            disabled={!Boolean(data?.length)}
            onClick={exportData}
          >
            Export
          </Button>
        </Box>

        <DataGrid
          exportFile={`Time Sheet Range Output ${DateTime.local().toISODate()}`}
          columns={[
            { field: "Employee", title: "Employee/Vendor", width: 190 },
            { field: "EmployeeNumber", title: "Recorded By ID", width: 190 },
            // { field: 'TicketNumber', title: 'Ticket Number', width: 190 },
            {
              field: "TicketDate",
              title: "Ticket Date",
              width: 190,
              type: "date",
            },
            {
              field: "UseInSales",
              title: "Has been used in Sales/Invoicing",
              width: 190,
            },
            {
              field: "InvoiceNumberUsed",
              title: "Invoice Number Used",
              width: 190,
            },
            { field: "ActivityItemId", title: "Activity Item ID", width: 190 },
            { field: "Customer", title: "Customer/Job/Adm", width: 190 },
            { field: "CompletedForId", title: "Completed for ID", width: 190 },
            { field: "PayLevel", title: "Pay Level", width: 190 },
            {
              field: "UsedInPayRoll",
              title: "Has been used in Payroll",
              width: 190,
            },
            { field: "ManualOption", title: "Manual/Timed Option", width: 190 },
            { field: "StartTime", title: "Start Time", width: 190 },
            { field: "EndTime", title: "End Time", width: 190 },
            { field: "Break", title: "Break", width: 190 },
            { field: "Duration", title: "Duration", width: 190 },
            { field: "InternalMemo", title: "Internal Memo", width: 190 },
            { field: "BillingType", title: "Billing Type", width: 190 },
            { field: "BillingRate", title: "Billing Rate", width: 190 },
            { field: "BillingStatus", title: "Billing Status", width: 190 },
            { field: "UnitDuration", title: "Unit Duration", width: 190 },
            { field: "BillingAmount", title: "Billing Amount", width: 190 },
            {
              field: "TicketDescription",
              title: "Ticket Description for Invoicing",
              width: 190,
            },
          ]}
          data={data}
        />
      </Paper>
    </Container>
  );
};

export default TimeSheetRange;
