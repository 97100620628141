import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import { selectStyles } from 'components/Styles';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label?: string;
    name?: string;
    required?: boolean;
    multiselect?: boolean;
    disabled?: boolean
}

const ModifierSelect = ({
    label = "Modifiers",
    name = "ModifierId",
    required = false,
    multiselect = false,
    disabled = false
}: Props) => {

    const { errors, register, setValue } = useFormContext();
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()
    const [filterOptions, setFilterOptions] = useState<any[]>([]);

    const formValue = useWatch<any>({ name: name });

    // load data
    const { data: options } = useFetch('/Modifier?sort=code', {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, []);

    // sync selected value
    const handleChange = (selectedOption: any) => {
        // set local selected value
        setSelected(selectedOption);

        let selected = null;

        // set form value
        if (selectedOption) {
            selected = multiselect
                ? selectedOption.map((o: any) => o.id)
                : selectedOption.id;
        }

        setValue(name, selected, {
            shouldValidate: true,
            shouldDirty: true
        });

    }

    // set current value when form data is loaded
    useEffect(() => {
        if (!options) {
            return;
        }

        // find the selected options
        let current: any;
        let filtered = options.filter((f: any) => f.isActive === true)

        if (!formValue) {
            setSelected(current);
            setFilterOptions(filtered);
            return;
        }

        if (multiselect) {
            current = [];

            formValue.forEach((id: string) => {
                const selected = options.find((f: any) => f.id === Number(id));
                current.push(selected);
                if (selected.isActive === false) {
                    filtered.push(selected);
                }

            });
        }
        else {
            current = options.find((f: any) => f.id === Number(formValue));
            if (current.isActive === false) {
                filtered.push(current);
            }
        }

        setSelected(current);
        setFilterOptions(filtered);

    }, [formValue, options, multiselect])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <ReactSelect
                isDisabled={disabled}
                styles={selectStyles}
                isClearable={true}
                isMulti={multiselect}
                options={filterOptions}
                getOptionLabel={(option: any) => `${option.code} - ${option.description}`}
                getOptionValue={(option: any) => option.id}
                value={selected}
                onChange={handleChange}
            />

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default ModifierSelect
