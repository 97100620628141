import {
    FormControl,
    FormHelperText,
    InputLabel,
    Select
} from '@material-ui/core';
import { AbilityContext } from 'components/AuthorizationProvider';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useFetch, CachePolicies } from 'use-http';

interface Props {
    label: string;
    name: string;
    required?: boolean;
    disabled?: boolean;
    url: string;
    optionRender: (option: any) => React.ReactNode;
    action?: string;
    subject?: string;
    field?: string;
}

const RemoteSelect = ({ 
    label, 
    name, 
    required = false, 
    disabled = false, 
    url, 
    optionRender,
    action,
    subject,
    field
}: Props) => {

    const { errors, register, setValue } = useFormContext();
    const hasError = Boolean(errors[name]);
    const [selected, setSelected] = useState<any>()
    const ability = useContext(AbilityContext);

    const formValue = useWatch<any>({ name: name });

    const denied = disabled || Boolean(action && subject && ability.cannot(action, subject, field ?? name));

    const { data: options, loading, error } = useFetch(url, {
        cacheLife: 60000,
        cachePolicy: CachePolicies.CACHE_FIRST
    }, [url]);

    // sync selected value
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const selectedValue = event.target.value as string;

        // set local selected value
        setSelected(selectedValue);

        // set form value
        setValue(name, selectedValue, {
            shouldValidate: true,
            shouldDirty: true
        });

    }

    // set current value when form data is loaded
    useEffect(() => {
        setSelected(formValue);
    }, [formValue])

    // register input in form hook
    useEffect(() => {
        register({ name: name }, { required: required });
    }, [register, name, required]);

    return (
        <FormControl
            fullWidth
            required={required}
            error={hasError}
            disabled={denied}
        >
            <InputLabel
                required={required}
                shrink
                error={hasError}
            >
                {label}
            </InputLabel>

            <Select native
                disabled={denied}
                value={selected}
                onChange={handleChange}
            >
                <option aria-label="None" value="" />
                {!error && !loading && options && options.map(optionRender)}
            </Select>

            <FormHelperText>
                {hasError && "This field is required"}
            </FormHelperText>
        </FormControl>
    )
}

export default RemoteSelect
