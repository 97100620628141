import {
    Paper,

} from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import React, { useCallback, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ClientForm from './ClientForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import { dataToForm, formToData } from 'services/DataTransform';
import { ClientSchema } from 'models/Schemas';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { AbilityContext } from 'components/AuthorizationProvider';

interface Props {
    clientId: string
}

const ClientDetail = ({ clientId }: Props) => {
    const classes = useStyles();
    const form = useForm();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const ability = useContext(AbilityContext);
    const allowEdit = ability.can("update", "Client")

    const loadClient = useCallback(() => {
        loadAction(
            `/Client/${clientId}`,
            'client',
            (data) => {
                const formData = dataToForm(data, ClientSchema);
                reset(formData);
            }
        );
    }, [loadAction, clientId, reset]);

    const saveClient = (formData: any) => {
        if (!allowEdit) return;

        const data = formToData(formData, ClientSchema);

        updateAction(
            `/Client/${clientId}`, data,
            `client '${data.familyName}, ${data.givenName}'`,
            () => { }
        );
    }

    useEffect(() => {
        loadClient();
    }, [loadClient]);


    return (
        <Paper className={classes.paper}>

            <FormHeader title="Client Details">
                <p>* = Required Field</p>
            </FormHeader>

            <FormProvider {...form}>
                <BlockUi tag="div" blocking={progress.loading}>
                    <form onSubmit={handleSubmit(saveClient)} noValidate autoComplete="off">
                        <ClientForm disabled={!allowEdit} action="update" />
                        <FormButtons
                            disabled={!allowEdit}
                            dirty={formState.isDirty}
                            cancelLink="/clients"
                            submitLabel="Save Client"
                        />
                    </form>
                </BlockUi>
            </FormProvider>
        </Paper>
    )
}

export default ClientDetail
