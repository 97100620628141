import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface Props {
    badgeContent: number;
    children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        
        inlineBadge: {
            height: 20,
            display: 'flex',
            padding: '0 6px',
            zIndex: 1,
            position: 'relative',
            flexWrap: 'wrap',
            fontSize: '0.75rem',
            minWidth: 20,
            boxSizing: 'border-box',
            transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            alignItems: 'center',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 500,
            lineHeight: 1,
            alignContent: 'center',
            borderRadius: 10,
            flexDirection: 'row',
            justifyContent: 'center',
            marginRight: 10,
            color: '#fff',
            backgroundColor: (props: Props) => {
                if (props.badgeContent === 0) {
                    return theme.palette.grey[500];
                }

                return theme.palette.secondary.main;
            }
        }
    }),
);



export const InlineBadge = (props: Props) => {
    const classes = useStyles(props);

    return (
        <React.Fragment>
            <span className={classes.inlineBadge}>{props.badgeContent}</span>
            {props.children}
        </React.Fragment>
    )
}

export default InlineBadge
