import { faFileMedical } from '@fortawesome/free-solid-svg-icons';
import {
    Breadcrumbs,
    Container,
    Paper,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import FormHeader from 'components/Common/SectionHeader';
import SectionTitle from 'components/Common/SectionTitle';
import LinkRouter from 'components/Navigation/LinkRouter';
import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import ModifierForm from './ModifierForm';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import { useFormActions } from 'components/Form/FormActions';
import BlockUi from 'react-block-ui';
import { useProgress } from 'components/Progress/ProgressProvider';
import { dataToForm, formToData } from 'services/DataTransform';
import { ModifierSchema } from 'models/Schemas';

const ModifierEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const form = useForm();
    const { modifierId } = useParams();
    const { loadAction, updateAction } = useFormActions();
    const { reset, handleSubmit, formState } = form;
    const progress = useProgress();

    const loadModifier = useCallback(() => {
        loadAction(
            `/Modifier/${modifierId}`,
            'modifier',
            data => {
                const formData = dataToForm(data, ModifierSchema);
                reset(formData);
            }
        );
    }, [loadAction, modifierId, reset]);

    const saveModifier = (formData: any) => {
        const data = formToData(formData, ModifierSchema);
        updateAction(
            `/Modifier/${modifierId}`, data,
            `modifier '${data.code}'`,
            () => {
                history.push('/administration/modifiers');
            }
        );
    }

    useEffect(() => {
        loadModifier();
    }, [loadModifier]);

    return (
        <Container maxWidth={false}>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '-10px', marginBottom: '10px' }}>
                <LinkRouter to="/">Home</LinkRouter>
                <LinkRouter to="/administration">Administration</LinkRouter>
                <LinkRouter to="/administration/modifiers">Modifiers</LinkRouter>
                <Typography color="textPrimary">Edit Modifier</Typography>
            </Breadcrumbs>
            <Paper className={classes.paper}>
                <FormHeader title="Update Service Modifier">
                    <p>* = Required Field</p>
                </FormHeader>
                <SectionTitle
                    title="Modifier Information"
                    icon={faFileMedical}
                    color={theme.palette.text.primary}
                />
                <FormProvider {...form}>
                    <BlockUi tag="div" blocking={progress.loading}>
                        <form onSubmit={handleSubmit(saveModifier)} noValidate>
                            <ModifierForm />
                            <FormButtons
                                dirty={formState.isDirty}
                                cancelLink="/administration/modifiers"
                                submitLabel="Save Modifier"
                            />
                        </form>
                    </BlockUi>
                </FormProvider>
            </Paper>
        </Container>
    )
}

export default ModifierEdit
