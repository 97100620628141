import { Paper, IconButton, MenuItem, Menu } from '@material-ui/core';
import FormHeader from 'components/Common/SectionHeader';
import { useFormActions } from 'components/Form/FormActions';
import FormButtons from 'components/Form/FormButtons';
import { useStyles } from 'components/Styles';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { formToData } from 'services/DataTransform';
import ClientContactForm from './ClientContactForm';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ContactSchema } from 'models/Schemas';

interface Props {
    client: any
}

const ClientContactCreate = ({ client }: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const form = useForm();
    const { createAction } = useFormActions();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

    const clientId = client?.id;

    const handleActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleActionClose = () => {
        setMenuAnchor(null);
    };

    const handleCopyAddress = () => {
        setMenuAnchor(null);
        const copied = {
            addressLine1: client.addressLine1,
            city: client.city,
            stateProvince: client.stateProvince,
            postalCode: client.postalCode
        }

        form.reset(copied);
    }

    const handleCopyClient = () => {
        setMenuAnchor(null);
        form.reset(client);
    }

    const saveContact = (formData: any) => {
        formData.clientId = clientId;
        const data = formToData(formData, ContactSchema);

        createAction(
            '/Contact', data,
            `contact '${data.familyName}, ${data.givenName}'`,
            () => history.push(`/clients/${clientId}/contacts`)
        );
    }

    return (
        <Paper className={classes.paper}>
            <FormHeader title="Add New Contact">
                <div>
                    <span>* = Required Field</span>
                    <IconButton
                        aria-label="more"
                        aria-controls="action-menu"
                        aria-haspopup="true"
                        onClick={handleActionClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={menuAnchor}
                        keepMounted
                        open={Boolean(menuAnchor)}
                        onClose={handleActionClose}
                    >
                        <MenuItem onClick={handleCopyClient}>Copy from Client</MenuItem>
                        <MenuItem onClick={handleCopyAddress}>Copy Address from Client</MenuItem>
                    </Menu>
                </div>
            </FormHeader>

            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(saveContact)} noValidate autoComplete="off">
                    <ClientContactForm />
                    <FormButtons
                        dirty={form.formState.isDirty}
                        cancelLink={`/clients/${clientId}/contacts`}
                        submitLabel="Save Contact"
                    />
                </form>
            </FormProvider>
        </Paper>
    )
}

export default ClientContactCreate
