import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import SectionTitle from 'components/Common/SectionTitle';
import ActivitySelect from 'components/Form/ActivitySelect';
import ServiceAgreementSelect from 'components/Form/ServiceAgreementSelect';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ManagerSelect from 'components/Form/ManagerSelect';
import { DateTime } from 'luxon';
import SecureTextField from 'components/Form/SecureTextField';
import TemplateSelector from 'components/Form/TemplateSelector';
import { useFetch } from 'use-http';
import Alert from '@material-ui/lab/Alert';

interface Props {
    clientId: string;
    disabled?: boolean;
    allowNote?: boolean;
    action?: string;
    noteText?: (note: string) => void;
    unsavedNote?: string;
}

const ClientNoteForm = ({
    clientId,
    disabled = false,
    allowNote = false,
    action = 'update',
    noteText,
    unsavedNote = ""
}: Props) => {
    const { errors, register, getValues, setValue, watch, formState, setError, clearErrors } = useFormContext();
    const theme = useTheme();
    const subject = 'ServiceNote';
    const [ serviceDate ] = useState();


    const { get } = useFetch();
    const [showWarning, setShowWarning] = useState(false);
    const watchServiceDate = watch("serviceDate");
    // const watchServiceAgreement = watch("serviceAgreementId");

    // Service Date Must fall between Service Agreement Start Date and EndDate.
    const [inputProps, setInputProps] = useState({
        max: '',
        min: ''
    })

    const inRange = (date: string, serviceAgreement: any): boolean => {
        if (!serviceAgreement || !date) return true;

        const serviceDate = DateTime.fromISO(date);
        if (!serviceDate) return true;

        const startDate = DateTime.fromISO(serviceAgreement.startDate);
        const endDate = DateTime.fromISO(serviceAgreement.endDate);

        if (!startDate || !startDate.isValid) return true;

        return endDate && endDate.isValid
            ? serviceDate >= startDate && serviceDate <= endDate
            : serviceDate >= startDate;
    }

    const onDateChange = (e : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const servDate = DateTime.fromISO(e.target.value);
        if(servDate.year.toString().length < 4) return;

        const { serviceAgreement } = getValues();
        const serviceDate = DateTime.fromISO(e.target.value);
        const startDate = DateTime.fromISO(serviceAgreement.startDate);
        const endDate = DateTime.fromISO(serviceAgreement.endDate);

        if(serviceDate < startDate || serviceDate > endDate)
        {
            setError("serviceDate", {
                message: 'Service Date must be within Service Agreement dates',
                shouldFocus: true,
            });
            return;
        }else {
            clearErrors("serviceDate");
        }
    }

    const handleCopyTemplate = (template: any) => {
        if (disabled) return;

        setValue("note", template.body, {
            shouldDirty: true,
            shouldValidate: true
        })
    }

    const onNoteChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>):any => {
        noteText?.(event.target.value);
    }

    useEffect(() => {
        setTimeout(() => {
            const { serviceAgreement } = getValues();
            if(!serviceAgreement) return;

            let maxDate = new Date(serviceAgreement.endDate) > new Date() ? new Date().toISOString().substring(0, 10) : new Date(serviceAgreement.endDate).toISOString().substring(0, 10);

            setInputProps({
                max: maxDate,
                min: new Date(serviceAgreement.startDate).toISOString().substring(0, 10)
            })
        })
    }, [getValues, formState])

    useEffect(() => {
        if (!watchServiceDate) {
            setShowWarning(false);
            return;
        }

        const serviceDate = DateTime.fromISO(watchServiceDate);
        console.log({serviceDate});
        if (!serviceDate.isValid) {
            setShowWarning(false);
            return;
        };

        const url = `/client/${clientId}/facility-check?date=${serviceDate.toISODate()}`;
        get(url).then(data => {
            setShowWarning(Boolean(data));
        });
    }, [clientId, get, watchServiceDate])

    return (
        <React.Fragment>
            <input
                type="hidden"
                name="billingDelayedReasonId"
                ref={register}
            />

            <SectionTitle
                title="Service Note"
                icon={faUser}
                color={theme.palette.text.primary}
            />

            {
                showWarning &&
                <Alert severity="warning">
                    The client was in a facility for the selected service date. Use non-billable units only.
                </Alert>
            }

            <Grid
                style={{
                    marginTop: "5px",
                    marginBottom: "5px"
                }}
                container
                direction="row"
                spacing={3}
            >
                <Grid item xs={6}>
                    <ServiceAgreementSelect
                        disabled={disabled}
                        name="serviceAgreementId"
                        label="Service Agreement"
                        required={true}
                        clientId={clientId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ManagerSelect
                        readOnly={true}
                        disabled={disabled}
                        name="caseManagerId"
                        label="Case Manager"
                        required={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="billingLockDate"
                        label="Billing Lock Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.billingLockDate)}
                        helperText={errors.billingLockDate && 'This field is required'}
                        inputRef={register()}
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="billedDate"
                        label="Billed Date"
                        type="date"
                        fullWidth
                        error={Boolean(errors.billedDate)}
                        helperText={errors.billedDate && errors.billedDate.message}
                        inputRef={register()}
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="isBillingDelayed"
                        label="Billing Delayed"
                        fullWidth
                        error={Boolean(errors.isBillingDelayed)}
                        helperText={errors.isBillingDelayed && 'This field is required'}
                        inputRef={register()}
                        readOnly={true}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="billingDelayedReason"
                        label="Delayed Reason"
                        fullWidth
                        error={Boolean(errors.billingDelayedReason)}
                        helperText={errors.billingDelayedReason && 'This field is required'}
                        inputRef={register()}
                        readOnly={true}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="serviceDate"
                        label="Service Date"
                        required
                        fullWidth
                        type="date"
                        error={Boolean(errors.serviceDate)}
                        helperText={errors.serviceDate && errors.serviceDate.message}
                        inputProps={inputProps}
                        value={serviceDate}
                        onChange={onDateChange}
                        inputRef={register({
                            required: 'This field is required',
                            // validate: {
                            //     serviceDate: value => {
                            //         const { serviceAgreement } = getValues();
                            //         return inRange(value, serviceAgreement) || 'Service Date must be within Service Agreement dates'
                            //     }
                            // }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={disabled}
                    />
                </Grid>

                <Grid item xs={6}>
                    <ActivitySelect
                        disabled={disabled}
                        name="activityTypes"
                        label="Activities"
                        required={true}
                        requiredValue={{ name: 'Facility Stay', value: 15, required: showWarning }}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="unitsUsed"
                        label="Units Used"
                        required
                        fullWidth
                        type="number"
                        error={Boolean(errors.unitsUsed)}
                        helperText={errors.unitsUsed && errors.unitsUsed.message}
                        inputRef={register({
                            required: 'This field is required',
                            min: {
                                value: 0,
                                message: 'Units must be greater than or equal to zero'
                            }
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={disabled}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div style={{ textAlign: 'end' }}>
                        <TemplateSelector onTemplateSelected={handleCopyTemplate} />
                    </div>

                    <SecureTextField
                        action={action}
                        subject={subject}
                        name="note"
                        label="Note"
                        required
                        fullWidth
                        multiline
                        minRows="15"
                        error={Boolean(errors.note)}
                        helperText={errors.note && 'This field is required'}
                        inputRef={register({ required: true })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        readOnly={!allowNote}
                        onChange={onNoteChange}
                        defaultValue={unsavedNote}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    )
}

export default ClientNoteForm
